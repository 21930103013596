import * as React from 'react';
import { SVGProps } from 'react';
const SvgRemove = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Remove_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Remove_svg__a"
			height={2}
			maskUnits="userSpaceOnUse"
			width={14}
			x={5}
			y={11}
		>
			<path d="M19 13H5v-2h14z" fill="#fff" />
		</mask>
		<g
			fill={`unset`}
			mask="url(#Remove_svg__a)"
			clipPath="url(#Remove_svg__b)"
		>
			<path d="M19 13H5v-2h14z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgRemove;
