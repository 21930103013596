import {
	Result,
	FetchResult,
	newFetchOk,
	newFetchErr,
	newLoading,
	Option,
	newSome,
	newNone,
	newOk,
	newErr,
} from "../result";
import { create } from "zustand";
import deepEqual from "deep-equal";

export interface ApiLayerEventMap<Resp, RespErr> {
	response: Result<Resp, ApiCallError<RespErr>>;
	"response-success": Resp;
	"response-error": ApiCallError<RespErr>;
}

interface ApiResponseError<RespErr> {
	kind: "api-response-error";
	message: string;
	responseCode: number;
	errorData: RespErr;
}

interface FetchPromiseFailed {
	kind: "fetch-promise-failed";
	message: string;
}

interface InvalidErrorShape {
	kind: "invalid-error-shape";
	message: string;
}

interface NoErrorJson {
	kind: "no-error-json";
	message: string;
}

interface NoSuccessJson {
	kind: "no-success-json";
	message: string;
}

interface InvalidSuccessShape {
	kind: "invalid-success-shape";
	message: string;
}

type ApiCallError<RespErr> =
	| FetchPromiseFailed
	| InvalidErrorShape
	| InvalidSuccessShape
	| NoErrorJson
	| NoSuccessJson
	| ApiResponseError<RespErr>;

function newApiResponseError<RespErr>(
	reqName: string,
	code: number,
	data: RespErr
): ApiResponseError<RespErr> {
	return {
		kind: "api-response-error",
		message: `${reqName} api request responded with error. Status code: ${code}`,
		responseCode: code,
		errorData: data,
	};
}

function newInvalidErrorShape(reqName: string): InvalidErrorShape {
	return {
		kind: "invalid-error-shape",
		message: `${reqName} request responded with an error, but the response shape was invalid.`,
	};
}

function newInvalidSuccessShape(reqName: string): InvalidSuccessShape {
	return {
		kind: "invalid-success-shape",
		message: `${reqName} request was successful, but the response shape was invalid.`,
	};
}

function newFetchPromiseFailed(
	reqName: string,
	message: string
): FetchPromiseFailed {
	return {
		kind: "fetch-promise-failed",
		message: `${reqName} fetch promise failed with error: ${message}`,
	};
}

function newNoSuccessJson(reqName: string): NoSuccessJson {
	return {
		kind: "no-success-json",
		message: `${reqName} was successful, but the response didn't contain json data, which was expected.`,
	};
}

function newNoErrorJson(reqName: string): NoErrorJson {
	return {
		kind: "no-error-json",
		message: `${reqName} request responded with error, but the response didn't contain json data, which was expected.`,
	};
}

interface IApiCallState<Req, Resp, RespErr> {
	result: FetchResult<Resp, ApiCallError<RespErr>>;
	request: Option<Req>;
	valid: boolean;
	eventTarget: EventTarget;
	dispatch: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
		name: T,
		data: ApiLayerEventMap<Resp, RespErr>[T]
	) => void;
	addEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
		name: T,
		listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
		options?: boolean | AddEventListenerOptions
	) => void;
	removeEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
		name: T,
		listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
		options?: boolean | AddEventListenerOptions
	) => void;
	setPartialRequest: (data: Partial<Req>, shouldInvalidate?: boolean) => void;
	setRequest: (data: Req, shouldInvalidate?: boolean) => void;
	fetchData: (keepCurrentResult?: boolean) => Promise<Resp>;
	invalidate: () => void;
	setLoading: () => void;
}

interface IApiCallStateWithUrlparams<Param, Req, Resp, RespErr>
	extends IApiCallState<Req, Resp, RespErr> {
	urlparams: Option<Param>;
	setUrlparams: (param: Param, shouldInvalidate?: boolean) => void;
	setPartialUrlparams: (
		param: Partial<Param>,
		shouldInvalidate?: boolean
	) => void;
}

function newApiCallState<Req, Resp, RespErr>(
	requestCall: (reqData: Req) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr,
	set: (
		callback: (
			state: IApiCallState<Req, Resp, RespErr>
		) => Partial<IApiCallState<Req, Resp, RespErr>>
	) => void,
	get: () => IApiCallState<Req, Resp, RespErr>
): IApiCallState<Req, Resp, RespErr> {
	return {
		result: newLoading(),
		request: newNone(),
		valid: false,
		eventTarget: new EventTarget(),
		dispatch: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
			name: T,
			data: ApiLayerEventMap<Resp, RespErr>[T]
		) => {
			get().eventTarget.dispatchEvent(
				new CustomEvent(name, { detail: data })
			);
		},
		addEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
			name: T,
			listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
			options: boolean | AddEventListenerOptions | undefined
		) => {
			get().eventTarget.addEventListener(
				name,
				(evt) =>
					listener(
						(evt as CustomEvent<ApiLayerEventMap<Resp, RespErr>[T]>)
							.detail
					),
				options
			);
		},
		removeEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
			name: T,
			listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
			options: boolean | AddEventListenerOptions | undefined
		) => {
			get().eventTarget.removeEventListener(
				name,
				(evt) =>
					listener(
						(evt as CustomEvent<ApiLayerEventMap<Resp, RespErr>[T]>)
							.detail
					),
				options
			);
		},
		setPartialRequest: (request: Partial<Req>, shouldInvalidate = true) =>
			set((state) => {
				if (state.request.kind === "some") {
					if (shouldInvalidate) {
						state.invalidate();
					}
					return {
						request: newSome({ ...state.request.data, ...request }),
					};
				} else {
					throw new Error(
						"setPartialRequest was used before using setRequest"
					);
				}
			}),
		setRequest: (request: Req, shouldInvalidate = true) =>
			set((state) => {
				if (state.request.kind === "some") {
					if (!deepEqual(state.request.data, request)) {
						if (shouldInvalidate) {
							state.invalidate();
						}
						return { request: newSome(request) };
					} else {
						return {};
					}
				} else {
					if (shouldInvalidate) {
						state.invalidate();
					}
					return { request: newSome(request) };
				}
			}),
		invalidate: () => set(() => ({ valid: false })),
		setLoading: () => set(() => ({ result: newLoading() })),
		fetchData: (keepCurrentResult = false) =>
			fetchData(
				get(),
				set,
				requestCall,
				requestName,
				isValidResponse,
				isValidResponseError,
				keepCurrentResult
			),
	};
}

function newApiCallStateWithUrlparams<Param, Req, Resp, RespErr>(
	requestCall: (reqData: Req, urlparams: Param) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr,
	set: (
		callback: (
			state: IApiCallStateWithUrlparams<Param, Req, Resp, RespErr>
		) => Partial<IApiCallStateWithUrlparams<Param, Req, Resp, RespErr>>
	) => void,
	get: () => IApiCallStateWithUrlparams<Param, Req, Resp, RespErr>
): IApiCallStateWithUrlparams<Param, Req, Resp, RespErr> {
	const requestCallWithParams = (req: Req) => {
		const urlparams = get().urlparams;
		if (urlparams.kind === "some") {
			return requestCall(req, urlparams.data);
		} else {
			throw new Error("called fetchData without setting urlparams");
		}
	};
	return {
		...newApiCallState(
			requestCallWithParams,
			requestName,
			isValidResponse,
			isValidResponseError,
			set,
			() => get() as IApiCallState<Req, Resp, RespErr>
		),
		urlparams: newNone(),
		setUrlparams: (param: Param, shouldInvalidate = true) =>
			set((state) => {
				if (state.urlparams.kind === "some") {
					if (!deepEqual(state.urlparams.data, param)) {
						if (shouldInvalidate) {
							state.invalidate();
						}
						return { urlParams: newSome(param) };
					}
					return {};
				} else {
					if (shouldInvalidate) {
						state.invalidate();
					}
					return { urlparams: newSome(param) };
				}
			}),
		setPartialUrlparams: (param: Partial<Param>, shouldInvalidate = true) =>
			set((state) => {
				if (state.urlparams.kind === "some") {
					if (shouldInvalidate) {
						state.invalidate();
					}
					return {
						urlparams: newSome({
							...state.urlparams.data,
							...param,
						}),
					};
				} else {
					throw new Error(
						"setPartialUrlparams was used before using setUrlparams"
					);
				}
			}),
	};
}

const generateUseApiCallStore = <Req, Resp, RespErr>(
	requestCall: (reqData: Req) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr
) =>
	create<IApiCallState<Req, Resp, RespErr>>((set, get) =>
		newApiCallState(
			requestCall,
			requestName,
			isValidResponse,
			isValidResponseError,
			set,
			get
		)
	);

const generateUseApiCallStoreWithUrlParams = <Param, Req, Resp, RespErr>(
	requestCall: (reqData: Req, param: Param) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr
) =>
	create<IApiCallStateWithUrlparams<Param, Req, Resp, RespErr>>((set, get) =>
		newApiCallStateWithUrlparams(
			requestCall,
			requestName,
			isValidResponse,
			isValidResponseError,
			set,
			get
		)
	);

function fetchData<Req, Resp, RespErr>(
	state: IApiCallState<Req, Resp, RespErr>,
	set: (
		callback: (
			state: IApiCallState<Req, Resp, RespErr>
		) => Partial<IApiCallState<Req, Resp, RespErr>>
	) => void,
	requestCall: (reqData: Req) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr,
	keepCurrentResult = false
): Promise<Resp> {
	if (!state.valid) {
		if (keepCurrentResult && state.result.kind === "ok") {
			set(() => ({ valid: true }));
		} else {
			set(() => ({ valid: true, result: newLoading() }));
		}
		const request = state.request.expect(
			"Please use setRequest before using fetchData"
		);
		requestCall(request)
			.then((resp) => {
				if (resp.ok) {
					resp.json()
						.then((json) => {
							if (isValidResponse(json)) {
								set(() => ({ result: newFetchOk(json) }));
								state.dispatch("response", newOk(json));
								state.dispatch("response-success", json);
							} else {
								const err = newInvalidSuccessShape(requestName);
								set(() => ({
									result: newFetchErr(err),
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							}
						})
						.catch(() => {
							// Check wether json is expected.
							// eslint-disable-next-line
							const nil: unknown = null; // Typescript is not very smart
							if (isValidResponse(nil)) {
								set(() => ({ result: newFetchOk(nil) }));
								state.dispatch("response", newOk(nil));
								state.dispatch("response-success", nil);
							} else {
								const err = newNoSuccessJson(requestName);
								set(() => ({
									result: newFetchErr(err),
									// If this is expected behavior, please set IResponseData to null
									// in the request definition.
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							}
						});
				} else {
					resp.json()
						.then((json) => {
							if (isValidResponseError(json)) {
								const err = newApiResponseError(
									requestName,
									resp.status,
									json
								);
								set(() => ({
									result: newFetchErr(err),
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							} else {
								const err = newInvalidErrorShape(requestName);
								set(() => ({
									result: newFetchErr(err),
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							}
						})
						.catch(() => {
							// Check wether json is expected.
							// eslint-disable-next-line
							const nil: unknown = null; // Typescript is not very smart
							if (isValidResponseError(nil)) {
								const err = newApiResponseError(
									requestName,
									resp.status,
									nil
								);
								set(() => ({
									result: newFetchErr(err),
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							} else {
								const err = newNoErrorJson(requestName);
								set(() => ({
									result: newFetchErr(err),
									// If this is expected behavior, please set IResponseData to null in the request definition.
								}));
								state.dispatch("response", newErr(err));
								state.dispatch("response-error", err);
							}
						});
				}
			})
			.catch((error) => {
				set(() => ({
					result: newFetchErr(
						newFetchPromiseFailed(requestName, error.message)
					),
				}));
			});
		return new Promise((resolve, reject) => {
			state.addEventListener(
				"response-success",
				(data) => resolve(data),
				{ once: true }
			);
			state.addEventListener("response-error", (error) => reject(error), {
				once: true,
			});
		});
	} else if (state.result.kind === "loading") {
		return new Promise((resolve, reject) => {
			state.addEventListener(
				"response-success",
				(data) => resolve(data),
				{ once: true }
			);
			state.addEventListener("response-error", (error) => reject(error), {
				once: true,
			});
		});
	} else if (state.result.kind === "ok") {
		return Promise.resolve(state.result.data);
	} else {
		return Promise.reject(state.result.error);
	}
}

const generatePublicApi = <Req, Resp, RespErr>(
	requestCall: (req: Req) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr
) => {
	const useGeneratedApiCallStore = generateUseApiCallStore(
		requestCall,
		requestName,
		isValidResponse,
		isValidResponseError
	);
	return {
		// should be called in useEffect
		setPartialRequest: (req: Partial<Req>, shouldInvalidate = true) =>
			useGeneratedApiCallStore
				.getState()
				.setPartialRequest(req, shouldInvalidate),
		setRequest: (req: Req, shouldInvalidate = true) =>
			useGeneratedApiCallStore
				.getState()
				.setRequest(req, shouldInvalidate),
		fetchData: (keepCurrentResult = false) =>
			useGeneratedApiCallStore.getState().fetchData(keepCurrentResult),
		addEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
			name: T,
			listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
			options?: boolean | AddEventListenerOptions
		) =>
			useGeneratedApiCallStore
				.getState()
				.addEventListener(name, listener, options),
		removeEventListener: <T extends keyof ApiLayerEventMap<Resp, RespErr>>(
			name: T,
			listener: (data: ApiLayerEventMap<Resp, RespErr>[T]) => void,
			options?: boolean | AddEventListenerOptions
		) =>
			useGeneratedApiCallStore
				.getState()
				.removeEventListener(name, listener, options),
		invalidate: () => useGeneratedApiCallStore.getState().invalidate(),

		// custom hook
		useGetResult: () => useGeneratedApiCallStore((state) => state.result),
		useIsValid: () => useGeneratedApiCallStore((state) => state.valid),

		// can be called anywhere
		getResult: () => useGeneratedApiCallStore.getState().result,
		isValid: () => useGeneratedApiCallStore.getState().valid,
		setLoading: () => useGeneratedApiCallStore.getState().setLoading(),
	};
};

const generatePublicApiWithUrlparams = <Param, Req, Resp, RespErr>(
	requestCall: (req: Req, param: Param) => Promise<Response>,
	requestName: string,
	// eslint-disable-next-line
	isValidResponse: (resp: unknown) => resp is Resp,
	// eslint-disable-next-line
	isValidResponseError: (respError: unknown) => respError is RespErr
) => {
	const useGeneratedApiCallStoreWithUrlparams =
		generateUseApiCallStoreWithUrlParams(
			requestCall,
			requestName,
			isValidResponse,
			isValidResponseError
		);
	const requestCallWithParams = (req: Req) => {
		const urlparams =
			useGeneratedApiCallStoreWithUrlparams.getState().urlparams;
		if (urlparams.kind === "some") {
			return requestCall(req, urlparams.data);
		} else {
			throw new Error("called fetchData without setting urlparams");
		}
	};

	return {
		...generatePublicApi(
			requestCallWithParams,
			requestName,
			isValidResponse,
			isValidResponseError
		),

		// setters
		setUrlparams: (param: Param, shouldInvalidate = true) =>
			useGeneratedApiCallStoreWithUrlparams
				.getState()
				.setUrlparams(param, shouldInvalidate),
		setPartialUrlparams: (param: Partial<Param>, shouldInvalidate = true) =>
			useGeneratedApiCallStoreWithUrlparams
				.getState()
				.setPartialUrlparams(param, shouldInvalidate),
	};
};

// public api

import * as GetSettings from "../api/request-definitions/get-settings";

export const getSettings = generatePublicApi(
	GetSettings.request,
	"GetSettings",
	GetSettings.isValidResponse,
	GetSettings.isValidResponseError
);

import * as SetSettings from "../api/request-definitions/set-settings";

export const setSettings = generatePublicApi(
	SetSettings.request,
	"SetSettings",
	SetSettings.isValidResponse,
	SetSettings.isValidResponseError
);

import * as Shutdown from "../api/request-definitions/shutdown";

export const shutdown = generatePublicApi(
	Shutdown.request,
	"Shutdown",
	Shutdown.isValidResponse,
	Shutdown.isValidResponseError
);

import * as About from "../api/request-definitions/about";

export const about = generatePublicApi(
	About.request,
	"About",
	About.isValidResponse,
	About.isValidResponseError
);

import * as GetInternetConnectionState from "../api/request-definitions/get-internet-connection-state";

export const getInternetConnectionState = generatePublicApi(
	GetInternetConnectionState.request,
	"GetInternetConnectionState",
	GetInternetConnectionState.isValidResponse,
	GetInternetConnectionState.isValidResponseError
);

import * as GetServerStatus from "../api/request-definitions/get-server-status";

export const getServerStatus = generatePublicApi(
	GetServerStatus.request,
	"GetServerStatus",
	GetServerStatus.isValidResponse,
	GetServerStatus.isValidResponseError
);

import * as GetMeasurementCount from "../api/request-definitions/get-measurement-count";

export const getMeasurementCount = generatePublicApi(
	GetMeasurementCount.request,
	"GetMeasurementCount",
	GetMeasurementCount.isValidResponse,
	GetMeasurementCount.isValidResponseError
);

import * as Home from "../api/request-definitions/home";

export const home = generatePublicApi(
	Home.request,
	"Home",
	Home.isValidResponse,
	Home.isValidResponseError
);

import * as Restart from "../api/request-definitions/restart";

export const restart = generatePublicApi(
	Restart.request,
	"Restart",
	Restart.isValidResponse,
	Restart.isValidResponseError
);

import * as TestServerPresence from "../api/request-definitions/test-server-presence";

export const testServerPresence = generatePublicApi(
	TestServerPresence.request,
	"TestServerPresence",
	TestServerPresence.isValidResponse,
	TestServerPresence.isValidResponseError
);

import * as GetHoyasettings from "../api/request-definitions/get-hoyasettings";

export const getHoyasettings = generatePublicApi(
	GetHoyasettings.request,
	"GetHoyasettings",
	GetHoyasettings.isValidResponse,
	GetHoyasettings.isValidResponseError
);

import * as SetHoyasettings from "../api/request-definitions/set-hoyasettings";

export const setHoyasettings = generatePublicApi(
	SetHoyasettings.request,
	"SetHoyasettings",
	SetHoyasettings.isValidResponse,
	SetHoyasettings.isValidResponseError
);

import * as LoadGDPR from "../api/request-definitions/load-gdpr";

export const loadGDPR = generatePublicApiWithUrlparams(
	LoadGDPR.requestWithParams,
	"LoadGDPR",
	LoadGDPR.isValidResponse,
	LoadGDPR.isValidResponseError
);

import * as SetGDPR from "../api/request-definitions/set-gdpr";

export const setGDPR = generatePublicApiWithUrlparams(
	SetGDPR.requestWithParams,
	"SetGDPR",
	SetGDPR.isValidResponse,
	SetGDPR.isValidResponseError
);

import * as SetRetentionPeriod from "../api/request-definitions/set-retention-period";

export const setRetentionPeriod = generatePublicApi(
	SetRetentionPeriod.request,
	"SetRetentionPeriod",
	SetRetentionPeriod.isValidResponse,
	SetRetentionPeriod.isValidResponseError
);

import * as GetCommSettings from "../api/request-definitions/get-comm-settings";

export const getCommSettings = generatePublicApi(
	GetCommSettings.request,
	"GetCommSettings",
	GetCommSettings.isValidResponse,
	GetCommSettings.isValidResponseError
);

import * as SetVPN from "../api/request-definitions/set-vpn";

export const setVPN = generatePublicApi(
	SetVPN.request,
	"SetVPN",
	SetVPN.isValidResponse,
	SetVPN.isValidResponseError
);

import * as SetUpdateEnabled from "../api/request-definitions/set-update-enabled";

export const setUpdateEnabled = generatePublicApi(
	SetUpdateEnabled.request,
	"SetUpdateEnabled",
	SetUpdateEnabled.isValidResponse,
	SetUpdateEnabled.isValidResponseError
);

import * as GetSSIDs from "../api/request-definitions/get-ssids";

export const getSSIDs = generatePublicApi(
	GetSSIDs.request,
	"GetSSIDs",
	GetSSIDs.isValidResponse,
	GetSSIDs.isValidResponseError
);

import * as SetAutoconfigApChannel from "../api/request-definitions/set-autoconfig-ap-channel";

export const setAutoconfigApChannel = generatePublicApi(
	SetAutoconfigApChannel.request,
	"SetAutoconfigApChannel",
	SetAutoconfigApChannel.isValidResponse,
	SetAutoconfigApChannel.isValidResponseError
);

import * as ApChannelAutoconfig from "../api/request-definitions/ap-channel-autoconfig";

export const apChannelAutoconfig = generatePublicApi(
	ApChannelAutoconfig.request,
	"ApChannelAutoconfig",
	ApChannelAutoconfig.isValidResponse,
	ApChannelAutoconfig.isValidResponseError
);

import * as SetApPassword from "../api/request-definitions/set-ap-password";

export const setApPassword = generatePublicApi(
	SetApPassword.request,
	"APPassword",
	SetApPassword.isValidResponse,
	SetApPassword.isValidResponseError
);

import * as WifiConnect from "../api/request-definitions/wifi-connect";

export const wifiConnect = generatePublicApi(
	WifiConnect.request,
	"WifiConnect",
	WifiConnect.isValidResponse,
	WifiConnect.isValidResponseError
);

import * as WifiDisconnect from "../api/request-definitions/wifi-disconnect";

export const wifiDisconnect = generatePublicApi(
	WifiDisconnect.request,
	"WifiDisconnect",
	WifiDisconnect.isValidResponse,
	WifiDisconnect.isValidResponseError
);

import * as ListMeasurements from "../api/request-definitions/list-measurements";

export const listMeasurements = generatePublicApi(
	ListMeasurements.request,
	"ListMeasurements",
	ListMeasurements.isValidResponse,
	ListMeasurements.isValidResponseError
);

import * as EditMeasurement from "../api/request-definitions/edit-measurement";

export const editMeasurement = generatePublicApi(
	EditMeasurement.request,
	"EditMeasurement",
	EditMeasurement.isValidResponse,
	EditMeasurement.isValidResponseError
);

import * as DeleteMeasurement from "../api/request-definitions/delete-measurement";

export const deleteMeasurement = generatePublicApi(
	DeleteMeasurement.request,
	"DeleteMeasurement",
	DeleteMeasurement.isValidResponse,
	DeleteMeasurement.isValidResponseError
);

import * as Start from "../api/request-definitions/start";

export const start = generatePublicApi(
	Start.request,
	"Start",
	Start.isValidResponse,
	Start.isValidResponseError
);

import * as Shot from "../api/request-definitions/shot";

export const shot = generatePublicApi(
	Shot.request,
	"Shot",
	Shot.isValidResponse,
	Shot.isValidResponseError
);

import * as EditorChange from "../api/request-definitions/editor-change";

export const editorChange = generatePublicApi(
	EditorChange.request,
	"EditorChange",
	EditorChange.isValidResponse,
	EditorChange.isValidResponseError
);

import * as EditorPage1 from "../api/request-definitions/editor-page-1";

export const editorPage1 = generatePublicApi(
	EditorPage1.request,
	"EditorPage1",
	EditorPage1.isValidResponse,
	EditorPage1.isValidResponseError
);

import * as EditorPage2 from "../api/request-definitions/editor-page-2";

export const editorPage2 = generatePublicApi(
	EditorPage2.request,
	"EditorPage2",
	EditorPage2.isValidResponse,
	EditorPage2.isValidResponseError
);

import * as ProcessMeasurement from "../api/request-definitions/process-measurement";

export const processMeasurement = generatePublicApi(
	ProcessMeasurement.request,
	"ProcessMeasurement",
	ProcessMeasurement.isValidResponse,
	ProcessMeasurement.isValidResponseError
);

// import * as ClientAlive from "../api/request-definitions/client-alive";
//
// export const clientAlive = generatePublicApi(
// 	ClientAlive.request,
// 	"ClientAlive",
// 	ClientAlive.isValidResponse,
// 	ClientAlive.isValidResponseError
// );

import * as Calculate from "../api/request-definitions/calculate";

export const calculate = generatePublicApi(
	Calculate.request,
	"Calculate",
	Calculate.isValidResponse,
	Calculate.isValidResponseError
);

import * as SetFrameType from "../api/request-definitions/set-frame-type";

export const setFrameType = generatePublicApi(
	SetFrameType.request,
	"SetFrameType",
	SetFrameType.isValidResponse,
	SetFrameType.isValidResponseError
);

import * as SetLensType from "../api/request-definitions/set-lens-type";

export const setLensType = generatePublicApi(
	SetLensType.request,
	"SetLensType",
	SetLensType.isValidResponse,
	SetLensType.isValidResponseError
);

import * as SetHeadRotation from "../api/request-definitions/set-head-rotation";

export const setHeadRotation = generatePublicApi(
	SetHeadRotation.request,
	"SetHeadRotation",
	SetHeadRotation.isValidResponse,
	SetHeadRotation.isValidResponseError
);

import * as SetOrderId from "../api/request-definitions/set-order-id";

export const setOrderId = generatePublicApi(
	SetOrderId.request,
	"SetOrderId",
	SetOrderId.isValidResponse,
	SetOrderId.isValidResponseError
);

import * as SetShopName from "../api/request-definitions/set-shop-name";

export const setShopName = generatePublicApi(
	SetShopName.request,
	"SetShopName",
	SetShopName.isValidResponse,
	SetShopName.isValidResponseError
);

import * as SetFinished from "../api/request-definitions/set-finished";

export const setFinished = generatePublicApi(
	SetFinished.request,
	"SetFinished",
	SetFinished.isValidResponse,
	SetFinished.isValidResponseError
);

import * as SendEmail from "../api/request-definitions/send-email";

export const sendEmail = generatePublicApi(
	SendEmail.request,
	"SendEmail",
	SendEmail.isValidResponse,
	SendEmail.isValidResponseError
);

import * as GetSessions from "../api/request-definitions/get-sessions";

export const getSessions = generatePublicApi(
	GetSessions.request,
	"GetSessions",
	GetSessions.isValidResponse,
	GetSessions.isValidResponseError
);

import * as SetSession from "../api/request-definitions/set-session";

export const setSession = generatePublicApi(
	SetSession.request,
	"SetSession",
	SetSession.isValidResponse,
	SetSession.isValidResponseError
);

import * as SubmitOrder from "../api/request-definitions/submit-order";

export const submitOrder = generatePublicApi(
	SubmitOrder.request,
	"SubmitOrder",
	SubmitOrder.isValidResponse,
	SubmitOrder.isValidResponseError
);

import * as AdminLogin from "../api/request-definitions/admin-login";

export const adminLogin = generatePublicApi(
	AdminLogin.request,
	"AdminLogin",
	AdminLogin.isValidResponse,
	AdminLogin.isValidResponseError
);

import * as ChangeAdminPassword from "../api/request-definitions/change-admin-password";

export const changeAdminPassword = generatePublicApi(
	ChangeAdminPassword.request,
	"ChangeAdminPassword",
	ChangeAdminPassword.isValidResponse,
	ChangeAdminPassword.isValidResponseError
);

import * as FactoryReset from "../api/request-definitions/factory-reset";

export const fatoryReset = generatePublicApi(
	FactoryReset.request,
	"FactoryReset",
	FactoryReset.isValidResponse,
	FactoryReset.isValidResponseError
);

import * as CreateAccessId from "../api/request-definitions/create-access-id";

export const createAccessId = generatePublicApi(
	CreateAccessId.request,
	"CreateAccessId",
	CreateAccessId.isValidResponse,
	CreateAccessId.isValidResponseError
);

import * as OnboardingDone from "../api/request-definitions/onboarding-done";

export const onboardingDone = generatePublicApi(
	OnboardingDone.request,
	"OnboardingDone",
	OnboardingDone.isValidResponse,
	OnboardingDone.isValidResponseError
);

import * as EditorChangeNotification from "../api/request-definitions/editor-change-notification";

export const editorChangeNotification = generatePublicApi(
	EditorChangeNotification.request,
	"EditorChangeNotification",
	EditorChangeNotification.isValidResponse,
	EditorChangeNotification.isValidResponseError
);

import * as AmIMeasurementOwner from "../api/request-definitions/am-i-measurement-owner";

export const amIMeasurementOwner = generatePublicApi(
	AmIMeasurementOwner.request,
	"AmIMeasurementOwner",
	AmIMeasurementOwner.isValidResponse,
	AmIMeasurementOwner.isValidResponseError
);

import * as AmICameraOwner from "../api/request-definitions/am-i-camera-owner";

export const amICameraOwner = generatePublicApi(
	AmICameraOwner.request,
	"AmICameraOwner",
	AmICameraOwner.isValidResponse,
	AmICameraOwner.isValidResponseError
);

import * as GetStereoTweakingOptions from "../api/request-definitions/get-stereo-tweaking-options";

export const getStereoTweakingOptions = generatePublicApi(
	GetStereoTweakingOptions.request,
	"GetStereoTweakingOptions",
	GetStereoTweakingOptions.isValidResponse,
	GetStereoTweakingOptions.isValidResponseError
);

import * as SetStereoTweakingOptions from "../api/request-definitions/set-stereo-tweaking-options";

export const setStereoTweakingOptions = generatePublicApi(
	SetStereoTweakingOptions.request,
	"SetStereoTweakingOptions",
	SetStereoTweakingOptions.isValidResponse,
	SetStereoTweakingOptions.isValidResponseError
);
