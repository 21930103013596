import clsx from "clsx";
import AsyncSelect, {
	components,
	StylesConfig,
	DropdownIndicatorProps,
} from "react-select";
import { capitalize, cn } from "../../util/helpers";
import { BodyText } from "../typography";
import { useBrandingStore } from "../../store";
import { cva } from "class-variance-authority";

export type SelectOption = {
	value: string;
	label: string;
};

type SelectProps = {
	defaultLabel?: string;
	value: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setValue: (value: any) => void;
	options: SelectOption[];
	label: string;
	alert?: string;
	info?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	className?: string;
	menuOpened?: () => void;
};

const DropdownIndicator = (props: DropdownIndicatorProps<SelectOption>) => {
	const { menuIsOpen } = props.selectProps;
	const { branding } = useBrandingStore();

	return (
		<components.DropdownIndicator {...props}>
			<img
				src={
					branding === "optiswiss"
						? "/icons/optiswiss/arrowdown_icon.svg"
						: "/icons/arrowdown_icon.svg"
				}
				width={24}
				height={24}
				className={menuIsOpen ? "rotate-180" : ""}
			/>
		</components.DropdownIndicator>
	);
};

const CustomSelect = ({
	value,
	setValue,
	options,
	label,
	alert,
	disabled,
	// fullWidth,
	info,
	defaultLabel = "",
	className,
	menuOpened,
}: SelectProps) => {
	const { branding } = useBrandingStore();

	const getType = () => {
		if (alert) return "alert";
		if (disabled) return "disabled";
		return "default";
	};

	const type = getType();

	const customSelectStyles: StylesConfig<SelectOption> = {
		control: (styles) => {
			return {
				...styles,
				boxShadow: "none",
				minHeight: branding === "optiswiss" ? 40 : 48,
				borderRadius: branding === "optiswiss" ? 20 : 0,
				borderColor:
					type === "alert"
						? "var(--system-danger--100)"
						: "var(--secondary--100)",
			};
		},
		option: (styles, state) => {
			return {
				...styles,
				display: "flex",
				gap: 12,
				alignItems: "center",
				padding: "12px 24px",
				color: "var(--primary-dark--100)",
				fontWeight: state.isSelected ? "700" : undefined,
				backgroundColor:
					state.isSelected && branding === "optiswiss"
						? "var(--quaternary--20)"
						: "#fff",
				":hover": {
					cursor: "pointer",
				},
				":active, :hover": {
					backgroundColor: "var(--quaternary--20)",
				},
				":before": {
					content: "''",
					display: "block",
					width: 24,
					height: 24,
					opacity: state.isSelected ? "1" : "0",
					backgroundImage:
						branding === "optiswiss"
							? "url(/icons/optiswiss/done_icon.svg)"
							: "url(/icons/done_icon.svg)",
				},
			};
		},
		valueContainer: (styles) => ({
			...styles,
			padding: "7px 12px",
		}),
		menu: (styles) => ({
			...styles,
			borderRadius: branding === "optiswiss" ? 10 : 0,
			padding: branding === "optiswiss" ? "20px 12px" : styles.padding,
			border:
				branding === "optiswiss" ? "1px solid #BBBEC3" : styles.border,
			boxShadow:
				branding === "optiswiss"
					? "0px 4px 4px rgba(0, 0, 0, 0.25)"
					: "0px 12px 48px rgba(45, 41, 38, 0.24)",
			zIndex: "20",
		}),
		menuList: (styles) => ({
			...styles,
			paddingTop: 0,
			paddingBottom: 0,
		}),
	};

	const wrapperVariants = cva("gap-2", {
		variants: {
			brand: {
				hoya: "flex flex-col",
				optiswiss: "grid items-start md:grid-cols-[1fr_250px]",
			},
		},
		defaultVariants: {
			brand: "hoya",
		},
	});

	return (
		<div
			className={cn(
				wrapperVariants({
					brand: branding !== "optiswiss" ? "hoya" : "optiswiss",
					className,
				})
			)}
		>
			{label && (
				<BodyText
					type={branding === "optiswiss" ? "normal" : "bold14"}
					className={cn(
						branding === "optiswiss" && "self-center",
						branding === "optiswiss" &&
							alert &&
							"text-system-danger-100"
					)}
				>
					{label}
				</BodyText>
			)}
			<AsyncSelect<SelectOption, false>
				options={options}
				placeholder={defaultLabel ? defaultLabel : undefined}
				styles={customSelectStyles}
				components={{
					DropdownIndicator,
					IndicatorSeparator: () => null,
				}}
				value={options.find((opt) => opt.value === value) || null}
				onChange={(option) => {
					setValue(option?.value || "");
				}}
				theme={(theme) => ({
					...theme,
					controlHeight: branding === "optiswiss" ? 40 : 48,
					borderRadius: branding === "optiswiss" ? 20 : 0,
					spacing: {
						...theme.spacing,
						menuGutter: 1,
					},
					colors: {
						...theme.colors,
						primary: "var(--primary--100)",
						primary75: "var(--primary--80)",
						primary50: "var(--primary--60)",
						primary25: "var(--primary--20)",
						neutral5: "var(--secondary--20)",
						neutral10: "var(--secondary--40)",
						neutral20: "var(--secondary--100)",
						neutral30: "var(--primary--100)",
						neutral80: "var(--primary-dark--100)",
						danger: "var(--system-danger--100)",
					},
				})}
				onMenuOpen={() => {
					menuOpened && menuOpened();
				}}
				isDisabled={disabled}
				isSearchable={false}
				menuPlacement="auto"
				// isSearchable={false}
				// menuIsOpen={true}
			/>
			{(info || alert) && (
				<div className="flex flex-col gap-1 col-start-2 ml-3">
					{alert && (
						<BodyText
							type="mini"
							className="text-system-danger-100 select-none"
						>
							{capitalize(alert)}
						</BodyText>
					)}
					{info && (
						<BodyText
							type="mini"
							className="text-primary-dark-60 select-none"
						>
							{capitalize(info)}
						</BodyText>
					)}
				</div>
			)}
		</div>
	);
};

export default CustomSelect;
