import clsx from "clsx";
import { Link } from "react-router-dom";
import { ButtonText } from "../typography";
import BaseButtonProps from "./baseProps";
import createRipple from "./rippleEffect";

const TextButton = ({
	children,
	onClick,
	color,
	className,
	disabled,
	icon,
	iconClasses,
	iconPosition,
	fullWidth,
	ignorePadding,
	disableRipple,
	to,
	state,
	forwardRef,
}: BaseButtonProps) => {
	return (
		<>
			{to ? (
				<Link
					to={to}
					state={state}
					className={clsx(
						!ignorePadding && "h-12 p-3",
						fullWidth ? "w-full" : "w-fit",
						color === "primary" &&
							!disabled &&
							"text-primary-100 active:bg-primary-20",
						color === "primary" && disabled && "text-secondary-20",
						"inline-flex items-center justify-center gap-x-3",
						!children && "rounded-full",
						"relative select-none overflow-hidden transition duration-200 ease-in-out",
						className
					)}
					onClick={(e) => {
						!disableRipple && createRipple(e, "gray");
						onClick && onClick();
					}}
				>
					{!iconPosition &&
						icon?.call(null, { className: iconClasses })}
					{children && (
						<ButtonText type="bold">{children}</ButtonText>
					)}
					{iconPosition === "suffix" &&
						icon?.call(null, { className: iconClasses })}
				</Link>
			) : (
				<button
					className={clsx(
						!ignorePadding && "h-12 p-3",
						fullWidth ? "w-full" : "w-fit",
						color === "primary" &&
							!disabled &&
							"text-primary-100 active:bg-primary-20",
						color === "primary" && disabled && "text-secondary-20",
						"inline-flex items-center justify-center gap-x-3",
						!children && "rounded-full",
						"relative select-none overflow-hidden transition duration-200 ease-in-out",
						className
					)}
					disabled={disabled}
					onClick={(e) => {
						!disableRipple && createRipple(e, "gray");
						onClick && onClick();
					}}
					type="button"
					ref={forwardRef}
				>
					{!iconPosition &&
						icon?.call(null, { className: iconClasses })}
					{children && (
						<ButtonText type="bold">{children}</ButtonText>
					)}
					{iconPosition === "suffix" &&
						icon?.call(null, { className: iconClasses })}
				</button>
			)}
		</>
	);
};

export default TextButton;
