import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Back, OSArrowLeft } from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import Footer from "../../components/panels/Footer";
import { HeadingText, BodyText } from "../../components/typography";
import languageIcons, { languageIcon } from "../../util/LanguageIcons";
import {
	getInternetConnectionState,
	getSettings,
} from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import Modal from "react-modal";
import BrandLogo from "../../components/logo/BrandLogo";
import { application, cn } from "../../util/helpers";

const Language = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [selectedLanguageCode, setSelectedLanguageCode] =
		useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setSelectedLanguageCode(lng);
	};

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
		});

		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
			})
			.finally(() => {
				setIsLoading(false);
			});

		const lang = window.localStorage.getItem("i18nextLng");

		if (lang) {
			if (lang === "en-US") {
				changeLanguage("en-GB");
			} else {
				changeLanguage(lang);
			}
		}
	}, []);

	const generateRow = (language: languageIcon) => {
		const isSelected = language.code === selectedLanguageCode;
		return (
			<div
				key={language.code}
				className={`flex h-12 ${
					isSelected ? "bg-quaternary-100" : ""
				} cursor-pointer`}
				onClick={() => changeLanguage(language.code)}
			>
				<img
					src={language.iconSrc}
					className={cn("m-[14px] h-5 w-5")}
				/>
				<BodyText
					type={
						isSelected
							? branding === "optiswiss"
								? "normal"
								: "bold16"
							: "normal"
					}
					className="w-[100%] py-3"
				>
					{language.name}
				</BodyText>
				{isSelected && (
					<img
						src={
							branding === "optiswiss"
								? "/icons/optiswiss/done_icon.svg"
								: "/icons/done_icon.svg"
						}
						className={cn("m-3 h-6 w-6 justify-self-end")}
					/>
				)}
			</div>
		);
	};

	if (branding === "optiswiss") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className="fixed inset-0 overflow-y-auto focus-visible:outline-0"
					overlayClassName="fixed inset-0 z-30 bg-transparent"
					bodyOpenClassName="overflow-hidden"
					style={{
						content: {
							transition: "none",
						},
						overlay: {
							transition: "none",
						},
					}}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo override={branding} size={"37px"} />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										{application[branding].title}
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-6 pb-12 sm:px-14",
								)}
							>
								<div
									className={cn(
										"w-full overflow-hidden pb-8",
									)}
								>
									<TextButton
										to="/onboarding/welcome"
										color="primary"
										icon={
											branding === "optiswiss"
												? OSArrowLeft
												: Back
										}
										iconClasses={
											branding === "optiswiss"
												? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
												: undefined
										}
										className={cn(
											"-m-3 mb-6",
											branding === "optiswiss"
												? "mt-6 h-fit"
												: "mt-3",
										)}
									>
										{t("language.previous")}
									</TextButton>
									<HeadingText
										h={4}
										className={cn(
											"mb-3 text-primary-dark-100",
										)}
									>{`1/${hasInternet ? "3" : "4"}. ${t(
										"language.pleaseSelectALanguage",
									)}`}</HeadingText>
									<BodyText
										type={"normal"}
										className={cn(
											"mb-5 text-primary-dark-80",
										)}
									>
										{t(
											"language.itWillBeTheLanguagOfTheDevice",
										)}
									</BodyText>
									<div className="grid max-h-[338px] overflow-y-auto">
										<div className="divide-y">
											{languageIcons.map((language) =>
												generateRow(language),
											)}
										</div>
									</div>
								</div>
								<div
									className={cn(
										"flex w-full flex-col gap-5 bg-white",
									)}
								>
									<SolidButton
										color="primary"
										loading={isLoading}
										onClick={() => {
											if (isLoading) {
												return;
											}
											navigate("/onboarding/shop-name");
										}}
										fullWidth
										className="capitalize"
									>
										{t("application.btnNextCaption")}
									</SolidButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div className="container pb-12">
					<TextButton
						to="/onboarding/welcome"
						color="primary"
						icon={Back}
						className="-m-3 mb-6 mt-3"
					>
						{t("language.previous")}
					</TextButton>
					<HeadingText
						h={4}
						className={cn("mb-3 text-primary-dark-100")}
					>{`1/${hasInternet ? "3" : "4"}. ${t(
						"language.pleaseSelectALanguage",
					)}`}</HeadingText>
					<BodyText
						type={"normal"}
						className={cn("mb-12 text-primary-dark-80")}
					>
						{t("language.itWillBeTheLanguagOfTheDevice")}
					</BodyText>
					<div className="divide-y">
						{languageIcons.map((language) => generateRow(language))}
					</div>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={1}
						label={t("application.btnNextCaption")}
						loading={isLoading}
						onClick={() => {
							if (isLoading) {
								return;
							}
							navigate("/onboarding/shop-name");
						}}
					/>
				</div>
			</Footer>
		</>
	);
};

export default Language;
