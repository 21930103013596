import J from "joi";

export type IRequestData = null;

export interface IUrlparams {
	branding: string;
	language: string;
	code: string;
}

export interface IResponseData {
	templateContent: string;
}

export type IResponseError = null;

const responseDataShape = J.object({
	templateContent: J.string(),
})
	.required()
	.unknown();

const uri = ({ branding, language, code }: IUrlparams) =>
	`https://vrauto-orderservice-dev.azurewebsites.net/api/gdpr/${branding}/${language}?code=${code}`;

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function requestWithParams(
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_: IRequestData,
	params: IUrlparams
): Promise<Response> {
	return fetch(uri(params), {
		method: "GET",
	});
}
