import { SVGProps } from "react";

const GreenTick = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_1295)">
				<path
					d="M23.9989 38.9395C13.1828 38.9395 4.41113 30.3149 4.41113 19.6709C4.41113 9.02687 13.1828 0.40625 23.9989 0.40625C34.8151 0.40625 43.5867 9.03093 43.5867 19.6749"
					fill="#8DB41F"
				/>
				<path
					d="M43.5893 19.6738C43.5893 30.3138 34.8217 38.9425 24.0015 38.9425L43.5893 19.6738Z"
					fill="#8DB41F"
				/>
				<path
					d="M23.9989 38.9395C13.1828 38.9395 4.41113 30.3149 4.41113 19.6709C4.41113 9.02687 13.1828 0.40625 23.9989 0.40625C34.8151 0.40625 43.5867 9.03093 43.5867 19.6749"
					fill="#8DB41F"
				/>
				<path
					d="M43.5893 19.6738C43.5893 30.3138 34.8217 38.9425 24.0015 38.9425L43.5893 19.6738Z"
					fill="#8DB41F"
				/>
				<path
					d="M44.0015 19.6738C44.0015 30.5206 35.032 39.348 24.0015 39.348L44.0015 19.6738Z"
					fill="#8DB41F"
				/>
				<path
					d="M24 39.3443C12.9736 39.3443 4 30.5209 4 19.6701C4 8.81931 12.9736 0 24 0C35.0264 0 44 8.82337 44 19.6742"
					fill="#8DB41F"
				/>
				<path
					d="M43.1764 19.6737C43.1764 9.27303 34.5737 0.810547 24.0008 0.810547C13.4278 0.810547 4.8252 9.27303 4.8252 19.6737C4.8252 30.0744 13.4278 38.5369 24.0008 38.5369"
					fill="#8DB41F"
				/>
				<path
					d="M24.0015 38.5341C34.5744 38.5341 43.1771 30.0716 43.1771 19.6709L24.0015 38.5341Z"
					fill="#8DB41F"
				/>
				<path
					d="M19.7297 28.5131L13.9345 22.7774C13.2254 22.0756 13.2256 20.9299 13.935 20.2284C14.6338 19.5374 15.7587 19.5378 16.457 20.2293L19.7297 23.47L29.9212 13.3783C30.6194 12.6868 31.7443 12.6868 32.4426 13.3783C33.1507 14.0794 33.1507 15.2235 32.4426 15.9246L19.7297 28.5131Z"
					fill="white"
				/>
			</g>
			<defs>
				<filter
					id="filter0_d_1_1295"
					x="0"
					y="0"
					width="48.0015"
					height="47.3477"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_1295"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_1295"
						result="shape"
					/>
				</filter>
			</defs>
		</svg>
	);
};

export default GreenTick;
