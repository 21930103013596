import * as React from 'react';
import { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Edit_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Edit_svg__a"
			height={19}
			maskUnits="userSpaceOnUse"
			width={19}
			x={3}
			y={2}
		>
			<path
				d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM21.41 6.34l-3.75-3.75-2.53 2.54 3.75 3.75z"
				fill="#fff"
			/>
		</mask>
		<g fill={`unset`} mask="url(#Edit_svg__a)" clipPath="url(#Edit_svg__b)">
			<path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM21.41 6.34l-3.75-3.75-2.53 2.54 3.75 3.75z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgEdit;
