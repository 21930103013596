import * as React from 'react';
import { SVGProps } from 'react';
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="M12 1.9c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zM9.5 16.4v-9l7 4.5z"
			fill={`unset`}
		/>
	</svg>
);
export default SvgVideo;
