import * as React from 'react';
import { SVGProps } from 'react';
const SvgHistory = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 48 48"
		{...props}
	>
		<path
			clipRule="evenodd"
			d="M41.6 20.2H27.8l6-6c-2.4-2.6-6-4.2-9.8-4.2-7.8 0-14 6.2-14 14s6.2 14 14 14c7 0 12.8-5.2 13.8-12h4c-1 9-8.6 16-17.8 16-10 0-18-8-18-18S14 6 24 6c5 0 9.4 2 12.8 5.2L42 6v14.2zM16 22h6v-8h4v12H16z"
			fill={`unset`}
			fillRule="evenodd"
		/>
	</svg>
);
export default SvgHistory;
