import * as React from 'react';
import { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Warning_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Warning_svg__a"
			height={19}
			maskUnits="userSpaceOnUse"
			width={22}
			x={1}
			y={2}
		>
			<path d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z" fill="#fff" />
		</mask>
		<g
			fill={`unset`}
			mask="url(#Warning_svg__a)"
			clipPath="url(#Warning_svg__b)"
		>
			<path d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgWarning;
