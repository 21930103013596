import { SVGProps } from "react";
const Measurement = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="86"
		height="86"
		viewBox="0 0 86 86"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<circle cx="43" cy="43" r="43" fill="#DD052B" />
		<g clipPath="url(#clip0_42_159)">
			<path
				d="M42.9998 46.0664C44.6934 46.0664 46.0664 44.6934 46.0664 42.9998C46.0664 41.3061 44.6934 39.9331 42.9998 39.9331C41.3061 39.9331 39.9331 41.3061 39.9331 42.9998C39.9331 44.6934 41.3061 46.0664 42.9998 46.0664Z"
				fill="white"
			/>
			<path
				d="M42.9999 33.0332C37.4954 33.0332 33.0332 37.4954 33.0332 42.9999C33.0332 48.5044 37.4955 52.9665 42.9999 52.9665C48.5043 52.9665 52.9666 48.5043 52.9666 42.9998C52.9603 37.4981 48.5017 33.0395 42.9999 33.0332ZM42.9999 47.5999C40.4594 47.5999 38.3999 45.5404 38.3999 42.9998C38.3999 40.4592 40.4594 38.3999 42.9999 38.3999C45.5404 38.3999 47.5999 40.4593 47.5999 42.9998C47.5974 45.5393 45.5394 47.5973 42.9999 47.5999Z"
				fill="white"
			/>
			<path
				d="M64.8868 42.0411C62.3154 38.9115 53.4911 29.2002 43 29.2002C32.509 29.2002 23.6847 38.9115 21.1133 42.0411C20.6517 42.5966 20.6517 43.4022 21.1133 43.9577C23.6847 47.0888 32.509 56.8002 43.0001 56.8002C53.4912 56.8002 62.3155 47.0888 64.8869 43.9593C65.3492 43.4035 65.3492 42.5969 64.8868 42.0411ZM43 54.5002C36.6487 54.5002 31.5 49.3515 31.5 43.0002C31.5 36.649 36.6488 31.5003 43 31.5003C49.3512 31.5003 54.5 36.649 54.5 43.0002C54.4928 49.3485 49.3484 54.493 43 54.5002Z"
				fill="white"
			/>
			<path
				d="M33.8 23.5197C33.4393 23.5195 33.1276 23.2679 33.0512 22.9154C32.9748 22.563 33.1544 22.2049 33.4826 22.0554C34.5101 21.5882 35.5708 21.1973 36.6558 20.8862C37.0629 20.7697 37.4874 21.0054 37.6038 21.4125C37.7202 21.8197 37.4846 22.2441 37.0774 22.3605C36.0653 22.6506 35.076 23.0147 34.1173 23.4499C34.0178 23.4957 33.9096 23.5194 33.8 23.5197Z"
				fill="white"
			/>
			<path
				d="M27.6668 27.6669C27.3532 27.6669 27.0714 27.4761 26.9548 27.1851C26.8383 26.894 26.9108 26.5614 27.1377 26.3451C28.1189 25.4106 29.1803 24.5641 30.3094 23.8152C30.5377 23.6623 30.8304 23.6433 31.0766 23.7653C31.3227 23.8873 31.4848 24.1318 31.5013 24.4061C31.5179 24.6804 31.3864 24.9425 31.1567 25.0932C30.1025 25.7924 29.1118 26.5829 28.1958 27.4553C28.0532 27.5912 27.8638 27.6669 27.6668 27.6669Z"
				fill="white"
			/>
			<path
				d="M23.5266 33.0335C23.2557 33.033 23.005 32.8896 22.8674 32.656C22.7298 32.4226 22.7257 32.1338 22.8566 31.8965C23.4176 30.8806 24.0547 29.9088 24.7625 28.9893C24.9255 28.7612 25.2001 28.6403 25.4784 28.674C25.7567 28.7077 25.9944 28.8907 26.0983 29.1511C26.2021 29.4115 26.1556 29.7079 25.9769 29.9239C25.317 30.7824 24.7227 31.6894 24.199 32.6371C24.0639 32.882 23.8063 33.0338 23.5266 33.0335Z"
				fill="white"
			/>
			<path
				d="M21.2444 39.1668C21.1911 39.1668 21.1379 39.1614 21.0857 39.1507C20.8866 39.1089 20.7122 38.9897 20.6011 38.8193C20.49 38.6489 20.4511 38.4414 20.493 38.2423C20.7197 37.1638 21.0242 36.1033 21.4046 35.069C21.5547 34.6775 21.9915 34.4793 22.385 34.6239C22.7785 34.7686 22.9828 35.2025 22.8436 35.5981C22.4894 36.563 22.2053 37.5523 21.9934 38.5582C21.9188 38.9124 21.6065 39.1662 21.2444 39.1668Z"
				fill="white"
			/>
			<path
				d="M46.0668 21.7428C46.0314 21.7431 45.996 21.7408 45.961 21.7359C43.9957 21.4675 42.0031 21.4675 40.0378 21.7359C39.7641 21.7783 39.4888 21.6697 39.3177 21.452C39.1466 21.2342 39.1062 20.9411 39.212 20.6852C39.3179 20.4294 39.5535 20.2503 39.8285 20.2171C41.9332 19.9296 44.0672 19.9296 46.1719 20.2171C46.5697 20.2738 46.8563 20.6279 46.8287 21.0287C46.801 21.4296 46.4686 21.7412 46.0668 21.7428Z"
				fill="white"
			/>
			<path
				d="M52.1998 23.5197C52.0902 23.5196 51.982 23.4961 51.8824 23.4507C50.9238 23.0152 49.9344 22.6508 48.9223 22.3605C48.5151 22.244 48.2795 21.8196 48.396 21.4125C48.5124 21.0055 48.9369 20.7697 49.3439 20.8862C50.429 21.1973 51.4896 21.5882 52.5171 22.0554C52.8453 22.2049 53.025 22.563 52.9486 22.9154C52.8722 23.2679 52.5605 23.5195 52.1998 23.5197Z"
				fill="white"
			/>
			<path
				d="M58.3335 27.6667C58.1365 27.6667 57.9471 27.5909 57.8044 27.4551C56.8884 26.5827 55.8977 25.7922 54.8436 25.093C54.599 24.9495 54.4536 24.6828 54.4655 24.3995C54.4775 24.1162 54.6448 23.8625 54.9006 23.7401C55.1563 23.6177 55.4588 23.6465 55.6869 23.815C56.816 24.5639 57.8774 25.4105 58.8586 26.3449C59.085 26.5607 59.1577 26.8924 59.0423 27.1831C58.9268 27.4738 58.6463 27.6652 58.3335 27.6667Z"
				fill="white"
			/>
			<path
				d="M62.4734 33.0335C62.1938 33.0338 61.9361 32.882 61.801 32.6371C61.2773 31.6893 60.6829 30.7824 60.0231 29.9239C59.8444 29.7079 59.7979 29.4115 59.9017 29.1511C60.0055 28.8907 60.2433 28.7077 60.5216 28.674C60.8 28.6403 61.0745 28.7612 61.2375 28.9893C61.9453 29.9088 62.5824 30.8807 63.1434 31.8965C63.2743 32.1338 63.2702 32.4226 63.1326 32.656C62.9951 32.8896 62.7444 33.033 62.4734 33.0335Z"
				fill="white"
			/>
			<path
				d="M64.7557 39.1669C64.3936 39.1664 64.0813 38.9126 64.0067 38.5582C63.7948 37.5523 63.5107 36.5631 63.1565 35.5981C63.0173 35.2026 63.2216 34.7686 63.6151 34.6239C64.0086 34.4793 64.4454 34.6775 64.5955 35.069C64.9758 36.1033 65.2804 37.1638 65.507 38.2423C65.549 38.4414 65.5101 38.6489 65.399 38.8193C65.2879 38.9897 65.1135 39.1089 64.9144 39.1507C64.8622 39.1614 64.809 39.1668 64.7557 39.1669Z"
				fill="white"
			/>
			<path
				d="M36.8667 65.1438C36.7953 65.1436 36.7244 65.1336 36.6558 65.1139C35.5708 64.8028 34.5101 64.4119 33.4826 63.9447C33.1073 63.7637 32.9453 63.316 33.1178 62.9367C33.2904 62.5574 33.7343 62.3853 34.1174 62.5494C35.076 62.9849 36.0654 63.3493 37.0775 63.6396C37.4458 63.7441 37.6806 64.1044 37.6274 64.4836C37.5743 64.8627 37.2496 65.1446 36.8667 65.1438Z"
				fill="white"
			/>
			<path
				d="M30.7331 62.3126C30.5826 62.313 30.4353 62.2687 30.3099 62.1853C29.1807 61.4363 28.1193 60.5897 27.1381 59.6553C26.8316 59.3631 26.82 58.8778 27.1121 58.5713C27.4042 58.2647 27.8896 58.2531 28.1961 58.5452C29.1121 59.4176 30.1029 60.208 31.157 60.9072C31.4386 61.0943 31.5644 61.4437 31.4668 61.7673C31.3692 62.0909 31.0712 62.3124 30.7331 62.3126Z"
				fill="white"
			/>
			<path
				d="M25.3665 57.3104C25.1286 57.3101 24.9043 57.1993 24.7593 57.0106C24.0515 56.0911 23.4144 55.1192 22.8534 54.1034C22.6563 53.7336 22.7927 53.274 23.1596 53.0716C23.5265 52.8692 23.988 52.9989 24.1958 53.3628C24.7195 54.3106 25.3139 55.2175 25.9737 56.0761C26.1519 56.3075 26.183 56.62 26.0541 56.8821C25.9252 57.1442 25.6586 57.3103 25.3665 57.3104Z"
				fill="white"
			/>
			<path
				d="M22.1242 51.4333C21.8027 51.4334 21.5153 51.2329 21.4043 50.9311C21.024 49.8968 20.7193 48.8363 20.4927 47.7579C20.4332 47.4886 20.5229 47.2082 20.7275 47.0234C20.9322 46.8386 21.2203 46.7779 21.4821 46.8645C21.7439 46.9511 21.939 47.1716 21.9931 47.442C22.205 48.4479 22.4891 49.4371 22.8433 50.4021C22.9297 50.6372 22.8958 50.8997 22.7525 51.1052C22.6093 51.3105 22.3747 51.4331 22.1242 51.4333Z"
				fill="white"
			/>
			<path
				d="M43.0001 66.0002C41.9391 66.0002 40.8794 65.9277 39.8284 65.7832C39.5545 65.7486 39.3203 65.5695 39.2153 65.3141C39.1103 65.0588 39.1507 64.7668 39.3211 64.5495C39.4915 64.3322 39.7655 64.2233 40.0385 64.2644C42.0038 64.5328 43.9964 64.5328 45.9617 64.2644C46.2347 64.2234 46.5087 64.3322 46.6791 64.5495C46.8495 64.7667 46.8898 65.0588 46.7849 65.3141C46.6799 65.5695 46.4457 65.7486 46.1718 65.7832C45.1208 65.9277 44.0611 66.0002 43.0001 66.0002Z"
				fill="white"
			/>
			<path
				d="M49.1335 65.1434C48.7506 65.1442 48.4259 64.8624 48.3727 64.4832C48.3196 64.104 48.5543 63.7438 48.9226 63.6392C49.9347 63.3491 50.9241 62.985 51.8828 62.5498C52.1331 62.429 52.4293 62.4532 52.6567 62.6131C52.8842 62.7731 53.0072 63.0435 52.9783 63.32C52.9495 63.5964 52.7731 63.8357 52.5176 63.9451C51.4901 64.4123 50.4294 64.8031 49.3444 65.1142C49.2757 65.1336 49.2048 65.1435 49.1335 65.1434Z"
				fill="white"
			/>
			<path
				d="M55.2665 62.3121C54.9285 62.312 54.6303 62.0905 54.5327 61.7669C54.4351 61.4433 54.5609 61.0939 54.8426 60.9068C55.8967 60.2075 56.8875 59.4171 57.8035 58.5447C58.11 58.2525 58.5954 58.2643 58.8875 58.5708C59.1797 58.8773 59.168 59.3627 58.8614 59.6548C57.8802 60.5893 56.8189 61.4359 55.6897 62.1848C55.5644 62.2681 55.4171 62.3124 55.2665 62.3121Z"
				fill="white"
			/>
			<path
				d="M60.6331 57.3104C60.341 57.3103 60.0744 57.1442 59.9454 56.8821C59.8165 56.62 59.8477 56.3075 60.0259 56.0761C60.6857 55.2175 61.28 54.3105 61.8038 53.3628C62.0115 52.9989 62.473 52.8692 62.8399 53.0716C63.2068 53.274 63.3433 53.7336 63.1462 54.1034C62.5852 55.1192 61.9481 56.0911 61.2402 57.0106C61.0954 57.1993 60.871 57.3101 60.6331 57.3104Z"
				fill="white"
			/>
			<path
				d="M63.8756 51.4333C63.6252 51.4332 63.3905 51.3107 63.2472 51.1052C63.1039 50.8997 63.0701 50.6372 63.1564 50.4021C63.5107 49.4372 63.7948 48.4479 64.0067 47.442C64.0607 47.1716 64.2558 46.9511 64.5176 46.8645C64.7794 46.7779 65.0676 46.8386 65.2722 47.0234C65.4769 47.2082 65.5665 47.4886 65.507 47.7579C65.2805 48.8363 64.9758 49.8969 64.5955 50.9311C64.4846 51.233 64.1971 51.4334 63.8756 51.4333Z"
				fill="white"
			/>
			<path
				d="M20.7667 26.1333C20.3432 26.1333 20 25.7901 20 25.3666V21.5333C20 20.6865 20.6865 20 21.5333 20H25.3666C25.7901 20 26.1333 20.3432 26.1333 20.7667C26.1333 21.1901 25.7901 21.5334 25.3666 21.5334H21.5333V25.3667C21.5333 25.7901 21.1901 26.1333 20.7667 26.1333Z"
				fill="white"
			/>
			<path
				d="M65.2334 26.1333C64.81 26.1333 64.4667 25.7901 64.4667 25.3666V21.5333H60.6334C60.21 21.5333 59.8667 21.1901 59.8667 20.7666C59.8667 20.3432 60.21 20 60.6335 20H64.4668C65.3136 20 66.0001 20.6865 66.0001 21.5333V25.3666C66.0001 25.7901 65.6569 26.1333 65.2334 26.1333Z"
				fill="white"
			/>
			<path
				d="M25.3666 66.0001H21.5333C20.6865 66.0001 20 65.3136 20 64.4667V60.6334C20 60.21 20.3432 59.8667 20.7667 59.8667C21.1901 59.8667 21.5334 60.2099 21.5334 60.6334V64.4667H25.3667C25.7902 64.4667 26.1334 64.81 26.1334 65.2334C26.1333 65.6569 25.7901 66.0001 25.3666 66.0001Z"
				fill="white"
			/>
			<path
				d="M64.4667 66.0001H60.6334C60.21 66.0001 59.8667 65.6569 59.8667 65.2334C59.8667 64.81 60.2099 64.4667 60.6334 64.4667H64.4667V60.6334C64.4667 60.21 64.81 59.8667 65.2334 59.8667C65.6568 59.8667 66.0001 60.2099 66.0001 60.6334V64.4667C66.0001 65.3136 65.3136 66.0001 64.4667 66.0001Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_42_159">
				<rect
					width="46"
					height="46"
					fill="white"
					transform="translate(20 20)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default Measurement;
