import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoreVertical = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="MoreVertical_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="MoreVertical_svg__a"
			height={16}
			maskUnits="userSpaceOnUse"
			width={4}
			x={10}
			y={4}
		>
			<path
				d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
				fill="#fff"
			/>
		</mask>
		<g
			fill={`unset`}
			mask="url(#MoreVertical_svg__a)"
			clipPath="url(#MoreVertical_svg__b)"
		>
			<path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgMoreVertical;
