import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Info } from "../icons";
import { ModalBase, ModalSystemInfo } from "../modals";
import { BodyText, ButtonText } from "../typography";
import { getSettings } from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import { application, cn } from "../../util/helpers";

type FooterProps = {
	children?: React.ReactNode;
	stickyFooter?: boolean;
};

const Footer = ({ children, stickyFooter }: FooterProps) => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalOpen, setmodalOpen] = useState<boolean>(false);

	const { aiModule } = getSettings
		.useGetResult()
		.map((resp) => ({
			aiModule: resp.modules.ai,
		}))
		.unwrapOrDefault({
			aiModule: false,
		});

	const closeModal = () => {
		setmodalOpen(false);
	};

	return (
		<footer
			className={clsx(
				stickyFooter ? "sticky bottom-0 z-10" : "relative",
				branding !== "optiswiss" && "before:opacity-[0.88]",
				"w-full self-end backdrop-blur before:absolute before:inset-0 before:bg-pure-white",
			)}
		>
			{children ? (
				<div className="relative grid h-[72px] items-center shadow-[0px_-1px_0px_#e6e8e9]">
					{children}
				</div>
			) : (
				<>
					<div
						className={cn(
							"relative h-[72px] shadow-[0px_-1px_0px_#e6e8e9]",
							branding === "optiswiss"
								? "gird-cols-1 grid grid-rows-1 place-items-center"
								: "grid grid-cols-[1fr_auto_1fr] items-center px-6",
						)}
					>
						{branding !== "optiswiss" ? (
							<ButtonText type="bold" className="col-start-2">
								{t("application.title_visureal")}{" "}
								{t("application.title")}
								{aiModule && (
									<sup className="ml-1 text-primary-100">
										AI
									</sup>
								)}
							</ButtonText>
						) : (
							<div
								className={cn(
									"flex flex-col items-center [grid-area:1/1/1/1]",
								)}
							>
								<BodyText
									type={"normal"}
									className="!leading-[1em] text-pure-black"
								>
									{application[branding].title}
								</BodyText>
								<p
									className="text-[8px] text-pure-black"
									dangerouslySetInnerHTML={{
										__html: application[branding]
											.title_visureal,
									}}
								></p>
							</div>
						)}
						<Info
							onClick={() => setmodalOpen(true)}
							className={cn(
								"cursor-pointer",
								branding === "optiswiss"
									? "translate-x-20 text-primary-100 [grid-area:1/1/1/1]"
									: "justify-self-end",
							)}
						/>
					</div>
					<ModalBase
						title={t("footer.systemInfo") || undefined}
						open={modalOpen}
						close={closeModal}
					>
						<ModalSystemInfo />
					</ModalBase>
				</>
			)}
		</footer>
	);
};

export default Footer;
