import * as React from 'react';
import { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="m20.03 18.56-6.59-6.59L20 5.41 18.59 4l-6.56 6.56L5.48 4 4.07 5.41l6.55 6.56L4 18.59 5.41 20l6.62-6.62 6.59 6.59z"
			fill={`unset`}
		/>
	</svg>
);
export default SvgClose;
