import * as React from 'react';
import { SVGProps } from 'react';
const SvgNormalScreen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="NormalScreen_svg__a">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<g clipPath="url(#NormalScreen_svg__a)">
			<path
				clipRule="evenodd"
				d="M19.6 3 21 4.4l-4.3 4.3 2.8 2.8h-7v-7l2.8 2.8zM3 19.6l4.3-4.3-2.8-2.8h7v7l-2.8-2.8L4.4 21z"
				fill={`unset`}
				fillRule="evenodd"
			/>
		</g>
	</svg>
);
export default SvgNormalScreen;
