import J from "joi";

export interface IRequestData {
	access_id: string;
	order_id: string;
	eye_catcher: boolean;
	client_time_zone: number;
	timestamp: string;
	client_time: number;
	//frame_type: string;
}

export interface IResponseData {
	camera_pair: number;
	measurement?: string;
}

export type IResponseError = null;

const responseDataShape = J.object({
	camera_pair: J.number(),
	measurement: J.string(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	error: J.string(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "start";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
