import Modal from "react-modal";
import { Close } from "../icons";
import { HeadingText } from "../typography";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";
import TextButton from "../buttons/TextButton";

type ModalBaseProps = {
	title?: string;
	disableHeader?: boolean;
	disableClose?: boolean;
	children: React.ReactNode;
	open: boolean;
	small?: boolean;
	close: () => void;
};

Modal.setAppElement("#root");

const ModalBase = ({
	title,
	disableHeader,
	disableClose,
	children,
	open,
	small,
	close,
}: ModalBaseProps) => {
	const { branding } = useBrandingStore();
	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			onRequestClose={close}
			className="fixed inset-0 overflow-y-auto focus-visible:outline-0"
			overlayClassName="fixed inset-0 z-30 bg-black/50 transition-opacity"
			bodyOpenClassName="overflow-hidden"
		>
			<div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
				<div
					className={cn(
						"bg-pure-white relative w-full overflow-hidden transition-all sm:my-8 ",
						branding === "optiswiss"
							? "rounded-[50px]"
							: "sm:max-w-[498px]",
						branding === "optiswiss" &&
							(small ? "sm:max-w-[490px]" : "sm:max-w-[620px]")
					)}
				>
					<div
						className={cn(
							branding === "optiswiss"
								? "px-0"
								: "sm:px-6 sm:pb-6"
						)}
					>
						{!disableHeader && (
							<div
								className={cn(
									"border-secondary-40 border-b-[1px] px-6",
									branding === "optiswiss"
										? "py-8 grid place-items-center px-12"
										: "flex flex-row items-center justify-between py-5"
								)}
							>
								{title && (
									<HeadingText
										h={4}
										className={cn(
											branding === "optiswiss" &&
												"[grid-area:1/1] pr-8"
										)}
									>
										{title}
									</HeadingText>
								)}
								{!disableClose && (
									<TextButton
										color="secondary"
										disabled={false}
										fullWidth={false}
										icon={Close}
										iconClasses={
											branding === "optiswiss"
												? "size-[17px]"
												: undefined
										}
										onClick={() => {
											close();
										}}
										className={cn(
											"justify-self-end",
											branding === "optiswiss" &&
												"!rounded-[10px] border border-quaternary-80 [grid-area:1/1] p-[5px] h-fit"
										)}
									/>
								)}
							</div>
						)}
						{children}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ModalBase;
