import * as React from 'react';
import { SVGProps } from 'react';
const SvgTurnOff = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width={props.width || 24}
		height={props.height || 24}
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<g clipPath="url(#TurnOff_svg__a)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 2h2v10h-2V2ZM4 12c0-2.219.884-4.18 2.328-5.715l-1.456-1.37C3.116 6.78 2 9.219 2 12c0 2.781 1.116 5.22 2.872 7.085l.02.022.023.021C6.78 20.884 9.219 22 12 22c2.781 0 5.22-1.116 7.085-2.872l.022-.02.021-.023C20.884 17.22 22 14.781 22 12c0-2.781-1.116-5.22-2.872-7.085l-1.456 1.37C19.116 7.82 20 9.781 20 12c0 2.208-.875 4.161-2.308 5.692C16.162 19.125 14.208 20 12 20s-4.161-.875-5.693-2.308C4.875 16.162 4 14.208 4 12Z"
				fill={`unset`}
			/>
		</g>
		<defs>
			<clipPath id="TurnOff_svg__a">
				<path fill="#fff" d="M0 0h24v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgTurnOff;
