import J from "joi";

export interface IRequestData {
	access_id: string;
	retention_period_72h: boolean;
	admin_settings: boolean;
	export_settings: boolean;
	other_settings: boolean;
	comm_settings: boolean;
	ap_password: boolean;
	language_en_GB: boolean;
	delete_measurements: boolean;
	module_b2b_optic_off: boolean;
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "factory_reset";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
