import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Logout_svg__a">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<g clipPath="url(#Logout_svg__a)">
			<path
				clipRule="evenodd"
				d="M17 11V7l5 5-5 5v-4H8v-2zM4 20h9v2H2V2h11v2H4z"
				fill={`unset`}
				fillRule="evenodd"
			/>
		</g>
	</svg>
);
export default SvgLogout;
