import * as React from 'react';
import { SVGProps } from 'react';
const SvgEyes = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			clipRule="evenodd"
			d="M12 5c7.5 0 10.8 6.3 10.9 6.6l.2.4-.2.4c-.1.3-3.4 6.6-10.9 6.6S1.2 12.7 1.1 12.4L.9 12l.2-.4C1.2 11.3 4.5 5 12 5zm-8.8 7c.8 1.3 3.5 5 8.8 5 5.2 0 8-3.7 8.8-5-.8-1.3-3.5-5-8.8-5-5.3 0-8 3.7-8.8 5zM8 12c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4zm2 0c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2z"
			fill={`unset`}
			fillRule="evenodd"
		/>
	</svg>
);
export default SvgEyes;
