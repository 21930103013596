import * as React from 'react';
import { SVGProps } from 'react';
const SvgEyesOff = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			clipRule="evenodd"
			d="M18.5 6.9c3 1.9 4.3 4.5 4.4 4.7l.2.4-.2.4c-.1.3-3.4 6.6-10.9 6.6-1.8 0-3.3-.4-4.7-.9L3.4 22 2 20.6 20.6 2 22 3.4zM12 14c1.1 0 2-.9 2-2 0-.2 0-.3-.1-.5l-2.4 2.4c.2.1.3.1.5.1zm-3.1 2.5c.9.3 1.9.5 3.1.5 5.2 0 8-3.7 8.8-4.9-.5-.8-1.7-2.5-3.8-3.7L15.4 10c.4.6.6 1.3.6 2 0 2.2-1.8 4-4 4-.7 0-1.4-.2-2-.6zm-3.6-2-1.4 1.4c-1.836-1.546-2.645-3.186-2.788-3.475A3.193 3.193 0 0 0 1.1 12.4L.9 12l.2-.4C1.2 11.3 4.5 5 12 5c.9 0 1.7.1 2.5.2L12.7 7H12c-5.3 0-8 3.7-8.8 5 .3.6 1 1.6 2.1 2.5z"
			fill={`unset`}
			fillRule="evenodd"
		/>
	</svg>
);
export default SvgEyesOff;
