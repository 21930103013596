import J from "joi";

export interface Option {
	active: boolean;
	auto: boolean;
	reset?: boolean;
}

export type IRequestData = {
	access_id: string;
};

export type IResponseError = null;

export interface IResponseData extends Array<Option>{}

const responseDataShape = J.array().items(
	J.object({
		active: J.boolean(),
		auto: J.boolean(),
	})
);

const uri =
	import.meta.env.VITE_MASTER_SERVER_HOST + "get_stereo_tweaking_options";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
