import { SVGProps } from "react";

const HelpVideo = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
	return (
		<svg
			width="94"
			height="94"
			viewBox="0 0 94 94"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
		>
			<g filter="url(#filter0_d_1_6541)">
				<circle cx="47" cy="43" r="43" fill="#DD052B" />
				<g clipPath="url(#clip0_1_6541)">
					<path
						d="M54.89 23H39.11C32.4234 23 27 28.4234 27 35.11V50.89C27 57.5766 32.4234 63 39.11 63H54.89C61.5766 63 67 57.5766 67 50.89V35.11C67 28.4234 61.5766 23 54.89 23ZM64.2938 34.3912H56.5508L52.8916 25.679H54.8955C59.856 25.679 63.9289 29.5287 64.2992 34.3912H64.2938ZM44.7566 34.3912L41.0975 25.679H50.0384L53.6975 34.3912H44.7566ZM38.2551 25.7171L41.8979 34.3912H29.7062C30.0547 29.8064 33.6921 26.131 38.2551 25.7171ZM64.321 50.89C64.321 56.0901 60.0901 60.321 54.89 60.321H39.11C33.9099 60.321 29.679 56.0901 29.679 50.89V37.0648H64.3264V50.89H64.321ZM42.8998 54.5709V42.8312C42.8998 42.1832 43.6621 41.8075 44.2066 42.1887L52.1348 47.6992C52.8372 48.1892 52.8372 49.202 52.1348 49.6866L44.1794 55.1971C43.6458 55.5674 42.8998 55.1971 42.8998 54.5655V54.5709Z"
						fill="white"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_6541"
					x="0"
					y="0"
					width="94"
					height="94"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_6541"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_6541"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_6541">
					<rect
						width="40"
						height="40"
						fill="white"
						transform="translate(27 23)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default HelpVideo;
