import clsx from "clsx";
import { ButtonText } from "../typography";
import BaseButtonProps from "./baseProps";
import createRipple from "./rippleEffect";
import { Loading } from "../icons";
import { cva } from "class-variance-authority";
import { useBrandingStore } from "../../store";

const SolidButton = ({
	children,
	color,
	className,
	disabled,
	fullWidth,
	icon,
	onClick,
	loading,
	submit,
	form,
}: BaseButtonProps) => {
	const { branding } = useBrandingStore();

	const buttonVariants = cva(
		"inline-flex items-center justify-center gap-x-3 px-6 relative overflow-hidden outline-none transition duration-200 ease-in-out text-pure-white bg-primary-100 disabled:bg-secondary-20 disabled:text-pure-white",
		{
			variants: {
				brand: {
					hoya: "h-12 active:bg-primary-80",
					optiswiss: "h-10 rounded-[20px] active:bg-[#c40427]",
				},
				intent: {
					primary: "",
					secondary: "",
				},
				width: {
					default: "min-w-[136px] md:min-w-[225px]",
					full: "w-full",
				},
			},
			compoundVariants: [
				{
					brand: "hoya",
					width: "default",
				},
			],
			defaultVariants: {
				brand: "hoya",
				width: "default",
			},
		}
	);

	return (
		<button
			className={clsx(
				buttonVariants({
					brand: branding !== "optiswiss" ? "hoya" : "optiswiss",
					width: fullWidth ? "full" : "default",
					intent: color,
					className,
				})
			)}
			disabled={disabled}
			onClick={(e) => {
				if (loading || submit) {
					return;
				}
				createRipple(e);
				onClick && onClick();
			}}
			type={submit ? "submit" : "button"}
			form={form}
		>
			{icon?.call(null, {})}
			{loading && <Loading className="animate-spin" />}
			<ButtonText type="bold">{children}</ButtonText>
		</button>
	);
};

export default SolidButton;
