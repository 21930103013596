import clsx from "clsx";
import { InputHTMLAttributes, useState } from "react";
import { Close, Eyes, EyesOff } from "../icons";
import { BodyText } from "../typography";
import { DeepMap, FieldError, FieldValues, get } from "react-hook-form";
import { cva } from "class-variance-authority";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type TextFieldType = "default" | "alert" | "disabled";

type TextFieldProps = {
	type: TextFieldType;
	label: string;
	info?: string;
	clearable?: boolean;
	search?: boolean;
	showPasswordButton?: boolean;
	leftIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
	htmlFor: string;
	fieldOptions?: InputHTMLAttributes<HTMLInputElement>;
	value?: string;
	setValue?: (e: string) => void;
	fullWidth?: boolean;
	className?: string;
	errors?: Partial<DeepMap<FieldValues, FieldError>>;
};

const TextField = ({
	label,
	type,
	clearable,
	search,
	showPasswordButton,
	info,
	leftIcon,
	fieldOptions,
	htmlFor,
	value,
	setValue,
	// fullWidth,
	className,
	errors,
}: TextFieldProps) => {
	const [showPassword, setShowPassword] = useState(false);
	const errorMessages = get(errors, htmlFor);
	const hasError = !!(errors && errorMessages);

	const { branding } = useBrandingStore();

	const wrapperVariants = cva("gap-2", {
		variants: {
			brand: {
				hoya: "flex flex-col",
				optiswiss: "grid items-start md:grid-cols-[1fr_250px]",
			},
			search: {
				default: "",
				true: "flex flex-col items-start",
			},
		},
		defaultVariants: {
			brand: "hoya",
		},
	});

	const labelVariants = cva("select-none", {
		variants: {
			brand: {
				hoya: "",
				optiswiss: "self-center",
			},
			search: {
				default: "",
				true: "self-start font-bold text-base",
			},
		},
		defaultVariants: {
			brand: "hoya",
		},
	});

	const inputVariants = cva("text-base w-full border leading-6", {
		variants: {
			brand: {
				hoya: "p-3",
				optiswiss: "px-3 py-2 rounded-[20px]",
			},
			type: {
				default:
					"text-primary-dark-100 border-secondary-100 focus:border-primary-100 focus:outline-none",
				alert: "text-primary-dark-100 border-system-danger-100",
				disabled:
					"bg-secondary-20 border-secondary-40 text-secondary-100",
			},
			clearable: {
				default: "",
				true: "pr-10",
			},
			search: {
				default: "",
				true: "rounded-[10px] p-3 mt-1 w-full",
			},
			leftIcon: {
				default: "",
				true: "pl-10",
			},
		},
		defaultVariants: {
			brand: "hoya",
			type: "default",
			clearable: "default",
			leftIcon: "default",
		},
	});

	return (
		<div
			className={cn(
				wrapperVariants({
					brand: branding !== "optiswiss" ? "hoya" : "optiswiss",
					className,
					search: search,
				}),
			)}
		>
			<label
				htmlFor={htmlFor}
				className={cn(
					labelVariants({
						brand: branding !== "optiswiss" ? "hoya" : "optiswiss",
						search: search,
					}),
				)}
			>
				<BodyText
					type={
						branding === "optiswiss"
							? search
								? "bold16"
								: "normal"
							: "bold14"
					}
					className={cn(
						branding === "optiswiss" &&
							hasError &&
							"text-system-danger-100",
					)}
				>
					{label}
				</BodyText>
			</label>
			<div
				className={cn(
					"relative inline-flex items-center",
					branding === "optiswiss" && "w-full",
				)}
			>
				{leftIcon && (
					<span className="relative left-0 -mr-8 block select-none">
						{leftIcon.call(null, {
							className:
								branding !== "optiswiss"
									? "fill-secondary-100"
									: "",
						})}
					</span>
				)}
				<input
					type={"text"}
					className={cn(
						inputVariants({
							brand:
								branding !== "optiswiss" ? "hoya" : "optiswiss",
							type: type || (hasError && "alert"),
							clearable,
							leftIcon: leftIcon ? true : false,
							search: search,
						}),
					)}
					id={htmlFor}
					autoComplete="off"
					{...fieldOptions}
					disabled={type === "disabled"}
					value={value}
					onChange={(e) => {
						setValue && setValue(e.target.value);
					}}
				/>
				{clearable && value && type !== "disabled" && (
					<span
						onClick={() => {
							setValue && setValue("");
						}}
						className={clsx(
							"relative right-0 -ml-10 block cursor-pointer select-none rounded-full p-1 transition duration-200 ease-in-out active:bg-secondary-20",
						)}
					>
						<Close className="fill-secondary-100" />
					</span>
				)}
				{fieldOptions && showPasswordButton && (
					<span
						onClick={() => {
							if (type === "disabled") {
								return;
							}
							setShowPassword(!showPassword);
							fieldOptions.type = showPassword
								? "password"
								: "text";
						}}
						className={clsx(
							type !== "disabled"
								? "cursor-pointer"
								: "cursor-default",
							"relative right-0 -ml-10 block select-none rounded-full p-1 transition duration-200 ease-in-out active:bg-secondary-20",
						)}
					>
						{showPassword ? (
							<EyesOff className="fill-secondary-100" />
						) : (
							<Eyes className="fill-secondary-100" />
						)}
					</span>
				)}
			</div>
			{(hasError || info) && (
				<div
					className={clsx(
						branding === "optiswiss" && "px-3 md:col-start-2",
					)}
				>
					{hasError && (
						<BodyText
							className={clsx("text-system-danger-100")}
							type="mini"
						>
							{errorMessages.message}
						</BodyText>
					)}
					{info && (
						<BodyText
							className={clsx(
								type === "default" && "text-primary-dark-60",
								type === "disabled" && "text-primary-dark-60",
								type === "alert" && "text-system-danger-100",
								"",
							)}
							type="mini"
						>
							{info}
						</BodyText>
					)}
				</div>
			)}
		</div>
	);
};

export default TextField;
