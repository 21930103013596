import { SVGProps } from "react";
const Notification = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={props.height || 24}
		width={props.width || 24}
		viewBox="0 0 16 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.9999 11.0086C11.3834 11.0086 12.9487 10.5745 13.0999 8.8323C13.0999 7.09128 12.0086 7.20323 12.0086 5.06707C12.0086 3.39848 10.427 1.5 7.9999 1.5C5.57276 1.5 3.99121 3.39848 3.99121 5.06707C3.99121 7.20323 2.8999 7.09128 2.8999 8.8323C3.05167 10.5811 4.61697 11.0086 7.9999 11.0086Z"
			stroke="#1D1D1D"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.43332 12.8145C8.61484 13.7233 7.33803 13.7341 6.51172 12.8145"
			stroke="#1D1D1D"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="11.8997"
			cy="2.9998"
			r="2.2"
			fill="#DD052B"
			stroke="white"
		/>
	</svg>
);
export default Notification;
