export type IRequestData = null;
export type IResponseData = null;
export type IResponseError = null;

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "shutdown";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	return resp === null;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
	});
}
