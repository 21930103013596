type labelInput = {
	frame_height_l: number;
	frame_height_r: number;
	frame_width_l: number;
	frame_width_r: number;
	pupill_to_nose_l: number;
	pupill_to_nose_r: number;
	pupill_to_frame_l: number;
	pupill_to_frame_r: number;
	lens_distance: number;
};

type FrameProps = {
	className?: string;
	data: labelInput;
};

const Frame1 = ({ className, data }: FrameProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="305"
		height="130"
		fill="none"
		className={className}
	>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="82.293" y="122.54">
				{data.frame_width_l.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="205.293" y="122.54">
				{data.frame_width_r.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="0" y="68.54">
				{data.frame_height_l.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="116.352" y="51.54">
				{data.pupill_to_nose_l.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="167.246" y="51.54">
				{data.pupill_to_nose_r.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="75.652" y="83.54">
				{data.pupill_to_frame_l.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="207" y="83.54">
				{data.pupill_to_frame_r.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="280.777" y="68.54">
				{data.frame_height_r.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="currentColor"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="142" y="122.54">
				{data.lens_distance.toFixed(1)}
			</tspan>
		</text>
	</svg>
);
export default Frame1;
