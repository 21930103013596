import { SVGProps } from "react";

const OSNormalScreen = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
				x="1.37832"
				y="1.1"
				width="11.2444"
				height="14.8"
				rx="2.4"
				stroke="#DD052B"
				strokeWidth="1.2"
			/>
			<path
				d="M10.6504 8.46289H14.5948"
				stroke="white"
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M10.6689 5.5301C10.6689 5.20635 10.2969 5.0431 10.0821 5.27276C9.48207 5.90641 8.26638 7.19031 7.56159 7.93463C7.55897 7.9374 7.55635 7.94017 7.55373 7.94293C7.26291 8.25007 7.26291 8.74537 7.55373 9.05251L7.56159 9.06081C8.27162 9.81067 9.49255 11.1029 10.0821 11.7254C10.1816 11.8306 10.3283 11.861 10.4567 11.8029C10.5772 11.7503 10.6689 11.6286 10.6689 11.4681L10.6689 5.5301Z"
				fill="#DD052B"
			/>
			<path
				d="M9.33594 8.49878H14.5952"
				stroke="#DD052B"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default OSNormalScreen;
