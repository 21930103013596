import { SVGProps } from "react";

const HelpManual = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
	return (
		<svg
			width="94"
			height="94"
			viewBox="0 0 94 94"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_6530)">
				<circle cx="47" cy="43" r="43" fill="#DD052B" />
				<g clipPath="url(#clip0_1_6530)">
					<path
						d="M60.9997 55.8306H60.1248V26.4427C60.1248 25.032 58.9874 23.8835 57.5905 23.8835H40.0283V23H57.5876C59.4686 23 60.9967 24.5461 60.9967 26.4427V55.8306H60.9997Z"
						fill="white"
					/>
					<path
						d="M59.279 57.5091H58.8708V28.1211C58.8708 26.5809 57.6284 25.3264 56.1032 25.3264H38.541V24.9141H56.1002C57.853 24.9141 59.2761 26.3542 59.2761 28.1211V57.5091H59.279Z"
						fill="white"
					/>
					<mask id="path-4-inside-1_1_6530" fill="white">
						<path d="M58.113 58.8607H57.7048V29.4727C57.7048 27.9325 56.4624 26.6779 54.9372 26.6779H37.3779V26.2656H54.9372C56.6899 26.2656 58.113 27.7057 58.113 29.4727V58.8607Z" />
					</mask>
					<path
						d="M58.113 58.8607H57.7048V29.4727C57.7048 27.9325 56.4624 26.6779 54.9372 26.6779H37.3779V26.2656H54.9372C56.6899 26.2656 58.113 27.7057 58.113 29.4727V58.8607Z"
						fill="white"
					/>
					<path
						d="M58.113 58.8607V63.8607H63.113V58.8607H58.113ZM57.7048 58.8607H52.7048V63.8607H57.7048V58.8607ZM37.3779 26.6779H32.3779V31.6779H37.3779V26.6779ZM37.3779 26.2656V21.2656H32.3779V26.2656H37.3779ZM58.113 53.8607H57.7048V63.8607H58.113V53.8607ZM62.7048 58.8607V29.4727H52.7048V58.8607H62.7048ZM62.7048 29.4727C62.7048 25.2173 59.2698 21.6779 54.9372 21.6779V31.6779C53.655 31.6779 52.7048 30.6477 52.7048 29.4727H62.7048ZM54.9372 21.6779H37.3779V31.6779H54.9372V21.6779ZM42.3779 26.6779V26.2656H32.3779V26.6779H42.3779ZM37.3779 31.2656H54.9372V21.2656H37.3779V31.2656ZM54.9372 31.2656C53.8795 31.2656 53.113 30.4179 53.113 29.4727H63.113C63.113 24.9935 59.5002 21.2656 54.9372 21.2656V31.2656ZM53.113 29.4727V58.8607H63.113V29.4727H53.113Z"
						fill="white"
						mask="url(#path-4-inside-1_1_6530)"
					/>
					<path
						d="M35.3214 29.2715H53.006C54.081 29.2715 54.969 30.1541 54.969 31.2636V60.2716C54.969 61.3811 54.081 62.2637 53.006 62.2637H35.3214C34.2465 62.2637 33.3584 61.3811 33.3584 60.2716V31.2636C33.3584 30.1541 34.2465 29.2715 35.3214 29.2715Z"
						stroke="white"
						strokeWidth="2"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_6530"
					x="0"
					y="0"
					width="94"
					height="94"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_6530"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_6530"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_6530">
					<rect
						width="29"
						height="41"
						fill="white"
						transform="translate(32 23)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default HelpManual;
