import J from "joi";

interface MeasurementPosition {
	changed: boolean;
	position: number[];
}

interface MeasurementSide {
	bottom?: MeasurementPosition;
	bottom_nasal?: MeasurementPosition;
	bottom_temporal?: MeasurementPosition;
	iris: MeasurementPosition;
	iris_radius_pixels: number;
	nasal?: MeasurementPosition;
	temporal?: MeasurementPosition;
	top?: MeasurementPosition;
	top_nasal?: MeasurementPosition;
	top_temporal?: MeasurementPosition;
}

export interface IRequestData {
	access_id: string;
	editable: {
		_L: MeasurementSide;
		_R: MeasurementSide;
	};
	images: {
		_CO: string;
		_CU: string;
	};
	measurement: string;
	order_id: string;
	page: number;
	time_stamp: string;
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = {
	error: string;
	successs: boolean;
};

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	error: J.string(),
	success: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "editor_change";

export function isValidResponse(resp: unknown): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
