import { SVGProps } from "react";

const OSLoading = (props: SVGProps<SVGSVGElement> & { color?: string }) => {
	return (
		<svg
			width="78"
			height="83"
			viewBox="0 0 78 83"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_6115)">
				<path
					d="M40.826 14.7297C44.6908 14.7297 47.8238 11.5443 47.8238 7.61486C47.8238 3.68543 44.6908 0.5 40.826 0.5C36.9612 0.5 33.8281 3.68543 33.8281 7.61486C33.8281 11.5443 36.9612 14.7297 40.826 14.7297Z"
					fill="#DD052B"
				/>
				<path
					d="M23.2495 18.8986C26.7783 18.8986 29.6391 15.9901 29.6391 12.4022C29.6391 8.81432 26.7783 5.90576 23.2495 5.90576C19.7206 5.90576 16.8599 8.81432 16.8599 12.4022C16.8599 15.9901 19.7206 18.8986 23.2495 18.8986Z"
					fill="#DD052B"
					fill-opacity="0.9"
				/>
				<path
					d="M10.3814 31.363C13.5744 31.363 16.1628 28.7313 16.1628 25.4849C16.1628 22.2386 13.5744 19.6069 10.3814 19.6069C7.1885 19.6069 4.6001 22.2386 4.6001 25.4849C4.6001 28.7313 7.1885 31.363 10.3814 31.363Z"
					fill="#DD052B"
					fill-opacity="0.8"
				/>
				<path
					d="M5.6731 48.6149C8.53012 48.6149 10.8462 46.2601 10.8462 43.3553C10.8462 40.4505 8.53012 38.0957 5.6731 38.0957C2.81607 38.0957 0.5 40.4505 0.5 43.3553C0.5 46.2601 2.81607 48.6149 5.6731 48.6149Z"
					fill="#DD052B"
					fill-opacity="0.7"
				/>
				<path
					d="M10.3817 65.8667C12.9017 65.8667 14.9446 63.7897 14.9446 61.2275C14.9446 58.6654 12.9017 56.5884 10.3817 56.5884C7.86172 56.5884 5.81885 58.6654 5.81885 61.2275C5.81885 63.7897 7.86172 65.8667 10.3817 65.8667Z"
					fill="#DD052B"
					fill-opacity="0.6"
				/>
				<path
					d="M23.2495 78.3291C25.4336 78.3291 27.2042 76.5289 27.2042 74.3083C27.2042 72.0877 25.4336 70.2876 23.2495 70.2876C21.0655 70.2876 19.2949 72.0877 19.2949 74.3083C19.2949 76.5289 21.0655 78.3291 23.2495 78.3291Z"
					fill="#DD052B"
					fill-opacity="0.4"
				/>
				<path
					d="M40.8259 82.5C42.674 82.5 44.1722 80.9767 44.1722 79.0976C44.1722 77.2186 42.674 75.6953 40.8259 75.6953C38.9777 75.6953 37.4795 77.2186 37.4795 79.0976C37.4795 80.9767 38.9777 82.5 40.8259 82.5Z"
					fill="#DD052B"
					fill-opacity="0.3"
				/>
				<path
					d="M58.4022 77.0922C59.9144 77.0922 61.1403 75.8458 61.1403 74.3083C61.1403 72.7708 59.9144 71.5244 58.4022 71.5244C56.89 71.5244 55.6641 72.7708 55.6641 74.3083C55.6641 75.8458 56.89 77.0922 58.4022 77.0922Z"
					fill="#DD052B"
					fill-opacity="0.3"
				/>
				<path
					d="M71.27 63.393C72.4463 63.393 73.3999 62.4235 73.3999 61.2275C73.3999 60.0315 72.4463 59.062 71.27 59.062C70.0937 59.062 69.1401 60.0315 69.1401 61.2275C69.1401 62.4235 70.0937 63.393 71.27 63.393Z"
					fill="#DD052B"
					fill-opacity="0.2"
				/>
				<path
					d="M75.9806 44.9022C76.821 44.9022 77.5022 44.2096 77.5022 43.3552C77.5022 42.5007 76.821 41.8081 75.9806 41.8081C75.1402 41.8081 74.459 42.5007 74.459 43.3552C74.459 44.2096 75.1402 44.9022 75.9806 44.9022Z"
					fill="#DD052B"
					fill-opacity="0.2"
				/>
				<path
					d="M71.2698 26.4139C71.7742 26.4139 72.1832 25.9981 72.1832 25.4853C72.1832 24.9724 71.7742 24.5566 71.2698 24.5566C70.7654 24.5566 70.3564 24.9724 70.3564 25.4853C70.3564 25.9981 70.7654 26.4139 71.2698 26.4139Z"
					fill="#DD052B"
					fill-opacity="0.2"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_6115">
					<rect
						width="77"
						height="82"
						fill="white"
						transform="translate(0.5 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSLoading;
