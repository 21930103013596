import J from "joi";

export interface IRequestData {
	// @TODO nem kellenek ezek?
	// inet_connected: boolean;
	// update_enabled: boolean;
	// unattended_update: boolean;//@todo ????
	// autoconfig_ap_channel: boolean;
	// autoconfig_ap_channel_at_start: boolean;
	// ap_channel: number;
	inet_config: {
		SSID: string;
		key: string;
		encryption: string;
	};
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "set_comm_settings";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
