export interface IRequestData {
	companyName: string;
	name: string;
	customerNumber: string;
	serialNumber: number;
	version: string;
	branding: string;
	html: string;
}

export interface IUrlparams {
	code: string;
}

export type IResponseData = null;

export type IResponseError = null;

const uri = ({ code }: IUrlparams) =>
	`https://vrauto-orderservice-dev.azurewebsites.net/api/gdpr?code=${code}`;
// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	return resp === null;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function requestWithParams(
	req: IRequestData,
	params: IUrlparams
): Promise<Response> {
	return fetch(uri(params), {
		method: "POST",
		body: JSON.stringify(req),
	});
}
