import * as React from 'react';
import { SVGProps } from 'react';
const SvgPrint = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Print_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Print_svg__a"
			height={18}
			maskUnits="userSpaceOnUse"
			width={20}
			x={2}
			y={3}
		>
			<path
				d="M22 8H2v9h4v4h12v-4h4zm-6 11H8v-5h8zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12z"
				fill="#fff"
			/>
		</mask>
		<g
			fill={`unset`}
			mask="url(#Print_svg__a)"
			clipPath="url(#Print_svg__b)"
		>
			<path d="M22 8H2v9h4v4h12v-4h4zm-6 11H8v-5h8zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgPrint;
