import { Navigate, NavigateProps, useSearchParams } from "react-router-dom";

const NavigateWithParams = ({ to, replace, state, relative, } :NavigateProps) => {
	const [searchParams, _] = useSearchParams();
	if (typeof(to) === "string") {
		to = { pathname: to }
	}
	return Navigate({ to: { ...to, search: searchParams.toString()}, replace, state, relative});
}

export default NavigateWithParams;
