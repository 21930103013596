import { SVGProps } from 'react';
const EyesWithCheck = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height={props.height || 80}
		width={props.width || 128}
		viewBox="0 0 128 80"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M126.85 37.71C126.27 36 107.25 0 64 0C20.75 0 1.73 36 1.15 37.71L0 40L1.15 42.29C1.73 44 20.75 80 64 80C107.25 80 126.27 44 126.85 42.29L128 40L126.85 37.71Z" fill="#D1CCBD"/>
		<path d="M64 64C77.2548 64 88 53.2548 88 40C88 26.7452 77.2548 16 64 16C50.7452 16 40 26.7452 40 40C40 53.2548 50.7452 64 64 64Z" fill="white"/>
		<g clipPath="url(#clip0_1518_13591)">
		<mask id="mask0_1518_13591" maskUnits="userSpaceOnUse" x="55" y="33" width="18" height="14">
			<path d="M61 44.2L56.8 40L55.4 41.4L61 47L73 35L71.6 33.6L61 44.2Z" fill="white"/>
		</mask>
			<g mask="url(#mask0_1518_13591)">
				<path d="M61 44.2L56.8 40L55.4 41.4L61 47L73 35L71.6 33.6L61 44.2Z" fill="#958B87"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M52 28H76V52H52V28Z" fill="#958B87"/>
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1518_13591">
				<rect width="24" height="24" fill="white" transform="translate(52 28)"/>
			</clipPath>
		</defs>
	</svg>
);
export default EyesWithCheck;
