import React from "react";
import { ScrollRestoration } from "react-router-dom";
import Footer from "../panels/Footer";
import Header from "../panels/Header";
import SidebarMenu from "../panels/SidebarMenu";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type FrameLayoutProps = {
	children: React.ReactNode;
	headerType?: string;
	hiddenFooter?: boolean;
	stickyFooter?: boolean;
	mainClassName?: string;
};

const FrameLayout = ({
	children,
	headerType,
	hiddenFooter,
	stickyFooter,
	mainClassName,
}: FrameLayoutProps) => {
	const { branding } = useBrandingStore();

	return (
		<>
			<header
				className={cn(
					branding !== "optiswiss" && "before:opacity-[0.88]",
					"before:bg-pure-white fixed top-0 z-30 w-full backdrop-blur before:absolute before:inset-0"
				)}
			>
				<Header type={headerType ? headerType : "default"} />
			</header>
			<main
				className={cn(
					branding === "optiswiss" ? "pt-[85px]" : "pt-[72px]",
					mainClassName
				)}
			>
				{children}
			</main>
			{!hiddenFooter && <Footer stickyFooter={stickyFooter} />}
			<SidebarMenu />
			<ScrollRestoration />
		</>
	);
};

export default FrameLayout;
