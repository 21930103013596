import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	SSIDs: string[];
	admin_mode: boolean;
	ap_channel: number;
	autoconfig_ap_channel: boolean;
	autoconfig_ap_channel_at_start: boolean;
	inet_config: {
		SSID: string;
		address: string;
		encryption: string;
	};
	inet_connected: boolean;
	update_enabled: boolean;
	vpn_on: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	SSIDs: J.array().allow(null),
	admin_mode: J.boolean(),
	ap_channel: J.number(),
	autoconfig_ap_channel: J.boolean(),
	autoconfig_ap_channel_at_start: J.boolean(),
	inet_config: J.object({
		SSID: J.string().allow(""),
		address: J.string(),
		encryption: J.string(),
	}).unknown(),
	inet_connected: J.boolean(),
	update_enabled: J.boolean(),
	vpn_on: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_comm_settings";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
