import { useBrandingStore } from "../../store";
import { capitalize, cn } from "../../util/helpers";
import { BodyText } from "../typography";
import Slider, { SliderProps } from "./Slider";

type SliderComponentProps = {
	label: string;
	info?: string;
} & SliderProps;

const SliderComponent = (props: SliderComponentProps) => {
	const { branding } = useBrandingStore();

	return (
		<div
			className={cn(
				"flex flex-col gap-3",
				branding === "optiswiss" && "flex-row gap-12"
			)}
		>
			<div className="flex flex-col gap-1">
				<BodyText
					className={cn(
						"text-primary-dark-100 select-none",
						branding === "optiswiss" && "whitespace-nowrap"
					)}
					type="normal"
				>
					{branding === "optiswiss"
						? `${props.label}`
						: `${props.label}: ${props.value}`}
				</BodyText>
				{props.info && (
					<BodyText
						type="mini"
						className="text-primary-dark-60 select-none"
					>
						{capitalize(props.info)}
					</BodyText>
				)}
			</div>
			<Slider {...props} />
		</div>
	);
};

export default SliderComponent;
