import { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SolidButton, TextButton } from "../../components/buttons";
import {
	Back,
	OSArrowLeft,
	OSSearch,
	OSWarning,
	Warning,
} from "../../components/icons";
import { CustomSelect, TextField, Checkbox } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import Info from "../../components/panels/Info";
import { TableHeader, TableRow } from "../../components/table";
import { BodyText, HeadingText } from "../../components/typography";
import Datepicker from "../../components/input/Datepicker";
import Loading from "../../components/icons/Loading";

import { Option, newNone, newSome } from "../../util/result";

import {
	listMeasurements,
	getSettings,
	deleteMeasurement,
	home,
} from "../../util/api/api-store";
import {
	Measurement,
	IResponseData as Measurements,
} from "../../util/api/request-definitions/list-measurements";
import { ModalBase } from "../../components/modals";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import { cn } from "../../util/helpers";

const History = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const locationData = useLocation();
	const [memorySpanToDelete, setMemorySpanToDelete] = useState<string>("");
	const [selection, setSelection] = useState<boolean[]>([]);
	const [dates, setDates] = useState<{
		startDate: Option<Date>;
		endDate: Option<Date>;
	}>({ startDate: newNone(), endDate: newNone() });
	const [searchString, setSearchString] = useState<Option<string>>(newNone());
	const [showDraftsOnly, setShowDraftsOnly] = useState<boolean>(false);
	const [reevaluate, setReevaluate] = useState<boolean>(false);
	const [tempSearchString, setTempSearchString] = useState<string>("");
	const openSnack = useSnackStore((state) => state.open);

	const { isPolicyAccepted, showReevaluate, b2bOpticDownload, showPreview } =
		getSettings
			.useGetResult()
			.map((resp) => ({
				isPolicyAccepted: resp.measurement_retention_days > 3,
				showReevaluate: resp.show_reevaluate_button,
				b2bOpticDownload: resp.modules.b2b_optic_download,
				showPreview: resp.history_show_preview,
			}))
			.unwrapOrDefault({
				isPolicyAccepted: true,
				showReevaluate: false,
				b2bOpticDownload: false,
				showPreview: true,
			});
	const measurementsResponse = listMeasurements.useGetResult();
	const {
		measurementCount,
		measurementCountMax,
		measurements,
		draftCount,
		earliestMeasurement,
	} = measurementsResponse
		.map(({ measurements, measurement_count, measurement_count_max }) => {
			let draftCount = 0;
			let earliestMeasurement: Option<Measurement> = newNone();
			measurements.forEach((m) => {
				if (
					earliestMeasurement.kind === "none" ||
					new Date(earliestMeasurement.data.time_stamp) >
						new Date(m.time_stamp)
				) {
					earliestMeasurement = newSome(m);
				}

				if (!m.finished) {
					draftCount += 1;
				}
			});
			return {
				measurements,
				measurementCount: measurement_count,
				draftCount: draftCount,
				earliestMeasurement: earliestMeasurement,
				measurementCountMax: measurement_count_max,
			};
		})
		.unwrapOrDefault({
			measurements: [],
			measurementCount: 0,
			draftCount: 0,
			measurementCountMax: 110,
			earliestMeasurement: newNone<Measurement>(),
		});

	const isMeasurementShown = (measurement: Measurement) => {
		const timeStamp = new Date(measurement.time_stamp);
		return (
			(!showDraftsOnly || !measurement.finished) &&
			dates.startDate
				.map((startDate) => startDate <= timeStamp)
				.unwrapOrDefault(true) &&
			dates.endDate
				.map((endDate) => endDate >= timeStamp)
				.unwrapOrDefault(true) &&
			searchString
				.map(
					(searchString) =>
						measurement.order_id
							.toLowerCase()
							.search(searchString.toLowerCase()) !== -1
				)
				.unwrapOrDefault(true)
		);
	};

	const shownMeasurements = measurements
		.map((e, i) => ({ e, i }))
		.filter(({ e }) => isMeasurementShown(e))
		.map(({ i }) => i);

	const isAllEqual =
		shownMeasurements.length === 0
			? true
			: shownMeasurements.reduce(
					(a, i) =>
						a && selection[i] === selection[shownMeasurements[0]],
					true
			  );
	const isAllSelected =
		isAllEqual &&
		shownMeasurements.length > 0 &&
		selection[shownMeasurements[0]];

	const isNoneSelected =
		isAllEqual &&
		(shownMeasurements.length === 0 || !selection[shownMeasurements[0]]);

	const isBetweenDates = (l: Date, m: Date, g: Date): boolean => {
		return l <= m && m <= g;
	};

	const isMeasurementBetweenDates = (
		l: Date,
		m: Measurement,
		g: Date
	): boolean => {
		const md = new Date(m.time_stamp);
		return isBetweenDates(l, md, g);
	};

	const setIsSelected = (index: number, value: boolean) => {
		const copy = [...selection];
		copy[index] = value;
		setSelection(copy);
		setMemorySpanToDelete("");
	};

	const uri = import.meta.env.VITE_MASTER_SERVER_HOST;

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData();

		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData().then(() => {
				listMeasurements.setRequest(
					{
						client_time: Math.floor(Date.now() / 1000),
						version: 2,
					},
					false
				);
				const fillSelection = ({ measurement_count }: Measurements) => {
					setSelection(Array(measurement_count).fill(false));
				};
				listMeasurements.addEventListener("response-error", (error) => {
					if (error.kind === "no-success-json") {
						listMeasurements.setRequest({
							client_time: Date.now(),
							version: 2,
						});
						listMeasurements.fetchData().then(fillSelection);
					}
				});
				listMeasurements.fetchData().then(fillSelection);
			});
		});
	}, [listMeasurements.useIsValid()]);

	useEffect(() => {
		if (locationData.state?.showDrafts) {
			setShowDraftsOnly(true);
			// @todo state kiuritese, mert reloadnal megmarad. elvileg igy oke
			// navigate(location.pathname, { replace: true });
		}
	}, [navigate]);

	const delMeasurement = (measurement: string) => {
		getAccessIdAsPromise().then((access_id) => {
			deleteMeasurement.setRequest({
				measurement: measurement,
				access_id,
			});
			deleteMeasurement.invalidate();
			deleteMeasurement.fetchData();
			listMeasurements.invalidate();
		});
	};

	const countHelper = (value: string) => {
		const monthCount = parseInt(value);
		let filterFn: (m: Measurement) => boolean;
		if (
			!isNaN(monthCount) &&
			0 < monthCount &&
			monthCount < 24 &&
			earliestMeasurement.kind === "some"
		) {
			const today = new Date();
			const startDate = new Date(earliestMeasurement.data.time_stamp);
			let endDate = new Date(
				today.getFullYear(),
				today.getMonth() - 1 - monthCount,
				today.getDate()
			);
			if (startDate >= endDate) {
				startDate.setDate(startDate.getDate() - 1);
				endDate = new Date(startDate);
			}
			filterFn = (m: Measurement) =>
				isMeasurementBetweenDates(startDate, m, endDate);
		} else if (monthCount === -1) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			filterFn = (_: Measurement) => true;
		} else if (monthCount === -2) {
			filterFn = (m: Measurement) => m.finished;
		} else {
			filterFn = () => true;
		}
		return measurements.filter(filterFn).length;
	};

	const selectHelper = (value: string) => {
		const monthCount = parseInt(value);
		if (
			!isNaN(monthCount) &&
			0 < monthCount &&
			monthCount < 24 &&
			earliestMeasurement.kind === "some"
		) {
			const today = new Date();
			const startDate = new Date(earliestMeasurement.data.time_stamp);
			let endDate = new Date(
				today.getFullYear(),
				today.getMonth() - 1 - monthCount,
				today.getDate()
			);
			if (startDate >= endDate) {
				startDate.setDate(startDate.getDate() - 1);
				endDate = new Date(startDate);
			}
			setDates({
				startDate: newSome(startDate),
				endDate: newSome(endDate),
			});
			setSelection(
				Array(selection.length)
					.fill(false)
					.map((_, i) =>
						isMeasurementBetweenDates(
							startDate,
							measurements[i],
							endDate
						)
					)
			);
		} else if (monthCount === -1) {
			resetSelection(true);
			setDates({
				startDate: newNone(),
				endDate: newNone(),
			});
		} else if (monthCount === -2) {
			setSelection(
				Array(selection.length)
					.fill(false)
					.map((_, i) => measurements[i].finished)
			);
			setDates({
				startDate: newNone(),
				endDate: newNone(),
			});
		}
		setMemorySpanToDelete(value);
	};

	const selectionCount = selection.filter((s) => s).length;

	const resetSelection = (val: boolean) => {
		setSelection(Array(measurementCount).fill(val));
	};

	const [modalConfirmDelete, setModalConfirmDelete] =
		useState<boolean>(false);
	const [modalConfirmDeleteType, setModalConfirmDeleteType] =
		useState<string>("");
	const [modalConfirmDeleteMeasurement, setModalConfirmDeleteMeasurement] =
		useState<Measurement | null>(null);

	const openModalConfirmDelete = (
		type: string,
		measurement?: Measurement | null
	) => {
		console.log(type, measurement);
		setModalConfirmDelete(true);
		setModalConfirmDeleteType(type);
		if (measurement?.id) {
			setModalConfirmDeleteMeasurement(measurement);
		}
	};

	const closeModalConfirmDelete = () => {
		setModalConfirmDelete(false);
		setModalConfirmDeleteType("");
		setModalConfirmDeleteMeasurement(null);
	};

	const onModalConfirmDelete = () => {
		console.log(modalConfirmDeleteType);
		openSnack(
			modalConfirmDeleteMeasurement
				? t("history.deleteSingle", {
						id: modalConfirmDeleteMeasurement.order_id,
						date: modalConfirmDeleteMeasurement.last_modified,
				  })
				: t("history.deleteMultiple", { count: selectionCount }),
			"ok"
		);
		if (modalConfirmDeleteMeasurement) {
			delMeasurement(modalConfirmDeleteMeasurement.id);
		} else {
			selection.forEach((bool, index) => {
				if (bool) {
					delMeasurement(measurements[index].id);
				}
			});
		}
		closeModalConfirmDelete();
	};

	const getImagePathFromPreview = (preview: string) => {
		return preview.replace("preview", "cam").replace("jpg", "png");
	};

	return (
		<FrameLayout stickyFooter mainClassName="flex flex-col">
			<div className="container flex grow flex-col pb-12">
				<div className="flex items-center justify-between gap-4">
					<TextButton
						to="/"
						color="primary"
						icon={branding === "optiswiss" ? OSArrowLeft : Back}
						iconClasses={
							branding === "optiswiss"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
								: undefined
						}
						className={cn(
							"-m-3 mb-6",
							branding === "optiswiss" ? "mt-6 h-fit" : "mt-3"
						)}
					>
						{t("history.mainPage")}
					</TextButton>
					{branding !== "optiswiss" && showReevaluate && (
						<Checkbox
							label={t("history.reevaluate")}
							className="mb-6 mt-3"
							checked={reevaluate ? "checked" : "unchecked"}
							setChecked={() => setReevaluate((c) => !c)}
						/>
					)}
				</div>
				<div
					className={cn(
						"flex w-full flex-col md:flex-row md:justify-between",
						branding === "optiswiss"
							? "mb-6 md:mb-8"
							: "mb-8 md:mb-12"
					)}
				>
					<HeadingText
						h={1}
						className={
							branding === "optiswiss"
								? "!text-[26px] align-middle"
								: ""
						}
					>
						{t("history.titleWithCount", {
							count: measurementCount,
						})}
					</HeadingText>
					{showDraftsOnly ? (
						<TextButton
							color={"primary"}
							className={cn(
								branding === "optiswiss"
									? "text-primary-80"
									: "-ml-3 md:ml-0"
							)}
							onClick={() => {
								resetSelection(false);
								setShowDraftsOnly(false);
							}}
						>
							{t("history.showAllMeasurements", {
								count: measurementCount,
							})}
						</TextButton>
					) : (
						<TextButton
							color={"primary"}
							className={cn(
								branding === "optiswiss"
									? "text-primary-80 underline underline-offset-4 [&>span]:font-normal"
									: "-ml-3 md:ml-0"
							)}
							onClick={() => {
								resetSelection(false);
								setShowDraftsOnly(true);
							}}
						>
							{t("history.showDrafts", { count: draftCount })}
						</TextButton>
					)}
				</div>
				{isPolicyAccepted ? (
					<div className="mb-8">
						<Info
							type={"done"}
							title={t("history.acceptedPolicyText")}
							className={cn(branding === "optiswiss" && "py-7")}
						/>
					</div>
				) : (
					<div className="mb-8">
						<Info
							type={"info"}
							title={t("history.notAcceptedPolicyText")}
							message={
								t("history.pleaseAcceptPolicy") || undefined
							}
							isCloseable={true}
							button={{
								onClick: () =>
									navigate("/settings/privacy-policy"),
								text: t("history.goToSettings"),
							}}
							className={cn(
								branding === "optiswiss" &&
									"[&>div>div>svg]:size-[112px] [&>div>div>svg]:self-center px-10 py-9"
							)}
						/>
					</div>
				)}
				{25 >= measurementCountMax - measurementCount && (
					<div className="mb-8">
						<Info
							type={"info"}
							title={t("history.memoryFullSoon")}
							message={
								t("home.pleaseDeleteCount", {
									count:
										measurementCountMax - measurementCount,
								}) || undefined
							}
							isCloseable={false}
							className={cn(
								branding === "optiswiss" &&
									"[&>div>div>svg]:size-[112px] px-10 py-9 [&>div>div>svg]:self-center"
							)}
							customControl={
								<>
									<div className="grid grid-cols-1 gap-6 md:grid-cols-2">
										<CustomSelect
											value={memorySpanToDelete}
											setValue={selectHelper}
											className={cn(
												branding === "optiswiss" &&
													"flex flex-col [&>div]:w-full"
											)}
											defaultLabel={
												isNoneSelected
													? t(
															"history.selectionDefault"
													  ) || undefined
													: t(
															"history.customSelection"
													  ) || undefined
											}
											options={[
												...[1, 2, 4, 6, 12].map(
													(n) => ({
														label: t(
															"history.olderThanCount",
															{
																n,
																count: countHelper(
																	`${n}`
																),
															}
														),
														value: `${n}`,
													})
												),
												{
													label: t(
														"history.exceptDraftCount",
														{
															count: countHelper(
																"-2"
															),
														}
													),
													value: "-2",
												},
												{
													label: t(
														"history.allCount",
														{
															count: measurements.length,
														}
													),
													value: "-1",
												},
											]}
											label={""}
										/>
										{selectionCount > 0 && (
											<SolidButton
												color={"primary"}
												onClick={() => {
													openModalConfirmDelete(
														"selection",
														null
													);
												}}
											>
												{t("history.deleteSelected")}
											</SolidButton>
										)}
									</div>
								</>
							}
						/>
					</div>
				)}
				<div
					className={cn(
						"mb-6 grid grid-cols-1 gap-6 md:grid-cols-2",
						branding === "optiswiss" && "gap-12"
					)}
				>
					<Datepicker
						type={"default"}
						label={t("history.searchDate")}
						htmlFor={"date"}
						startDate={dates.startDate.unwrapOrDefaultGeneric(null)}
						endDate={dates.endDate.unwrapOrDefaultGeneric(null)}
						setValue={([startDate, endDate]) => {
							let optionStartDate;
							if (startDate === null) {
								optionStartDate = newNone<Date>();
							} else {
								optionStartDate = newSome(startDate);
							}
							let optionEndDate;
							if (endDate === null) {
								optionEndDate = newNone<Date>();
							} else {
								optionEndDate = newSome(endDate);
							}
							setDates({
								startDate: optionStartDate,
								endDate: optionEndDate,
							});
							resetSelection(false);
						}}
						placeholderText={
							t("history.searchDatePlaceholder") || undefined
						}
					/>
					<TextField
						type={"default"}
						search
						leftIcon={OSSearch}
						fullWidth={true}
						label={t("history.searchOrderId")}
						htmlFor={"orederId"}
						value={tempSearchString}
						fieldOptions={{
							placeholder:
								t("history.searchOrderIdPlaceholder") ||
								undefined,
						}}
						clearable
						setValue={(search) => {
							setTempSearchString(search);
							if (search.length < 2) {
								if (searchString.kind === "some") {
									resetSelection(false);
								}
								setSearchString(newNone());
							} else {
								resetSelection(false);
								setSearchString(newSome(search));
							}
						}}
						className={cn("flex flex-col")}
					></TextField>
				</div>
				{isNoneSelected ? (
					<TableHeader
						variant="lastModified"
						checkbox="unchecked"
						setCheckboxChecked={() => {
							setSelection(
								Array(measurementCount)
									.fill(false)
									.map((_, i) =>
										isMeasurementShown(measurements[i])
									)
							);
							setMemorySpanToDelete("");
						}}
						options={[
							...[1, 2, 4, 6].map((n, i) => {
								const count = countHelper(`${n}`);
								return {
									id: i,
									label: t("history.olderThan", { n }),
									count,
									onSet: () => selectHelper(`${n}`),
								};
							}),
							{
								id: 5,
								label: t("history.exceptDraft"),
								count: measurements.filter((m) => m.finished)
									.length,
								onSet: () => selectHelper("-2"),
							},
							{
								id: 6,
								label: t("history.all"),
								count: measurementCount,
								onSet: () => selectHelper("-1"),
							},
						]}
					/>
				) : (
					<TableHeader
						variant="delete"
						checkbox={isAllSelected ? "checked" : "indeterminate"}
						itemCountToDelete={selectionCount}
						setCheckboxChecked={() => {
							resetSelection(false);
							setMemorySpanToDelete("");
						}}
						// eslint-disable-next-line
						onDelete={() => {
							openModalConfirmDelete("selection", null);
						}}
					/>
				)}
				{measurementsResponse.kind === "ok" ? (
					<div
						className={cn(
							branding === "optiswiss"
								? "grid grid-cols-2 md:grid-cols-3 gap-x-[52px] gap-y-9 mt-9"
								: "grid divide-y"
						)}
					>
						{measurements.map(
							(measurement, index) =>
								isMeasurementShown(measurement) && (
									<div
										key={index}
										className={cn(
											branding === "optiswiss"
												? ""
												: "py-4"
										)}
									>
										<TableRow
											reevaluate={reevaluate}
											showPreview={showPreview}
											isSelected={
												selection[index] || false
											}
											content={{
												id: measurement.id,
												preview:
													uri + measurement.preview_2,
												image1:
													uri +
													(measurement.image_1 ||
														getImagePathFromPreview(
															measurement.preview_1
														)),
												image2:
													uri +
													(measurement.image_2 ||
														getImagePathFromPreview(
															measurement.preview_2
														)),
												name: measurement.order_id,
												date: new Date(
													measurement.time_stamp
												),
												lastModified: new Date(
													measurement.last_modified
												),
												cameraPair:
													measurement.camera_pair,
												xml: b2bOpticDownload
													? uri +
													  measurement.measurement_data
													: "",
												isDraft: !measurement.finished,
											}}
											actions={{
												toggleSelection: (v) =>
													setIsSelected(index, v),
												deleteMeasurement: () => {
													openModalConfirmDelete(
														"dropdown",
														measurement
													);
												},
											}}
										/>
									</div>
								)
						)}
					</div>
				) : (
					<div className="flex h-full w-full grow items-center justify-center">
						<Loading
							width={48}
							height={48}
							className="animate-spin"
						/>
					</div>
				)}
			</div>
			<ModalBase
				title={t("history.deleteMeasurement") || undefined}
				open={modalConfirmDelete}
				small
				close={closeModalConfirmDelete}
			>
				<div
					className={cn(
						branding === "optiswiss"
							? "px-12 py-11 text-center"
							: "p-6"
					)}
				>
					<p
						className={cn(
							branding === "optiswiss" ? "mb-8" : "mb-6"
						)}
					>
						{modalConfirmDeleteMeasurement
							? t("history.deleteSingleConfirm", {
									id: modalConfirmDeleteMeasurement.order_id,
									date: modalConfirmDeleteMeasurement.last_modified,
							  })
							: t("history.deleteMultipleConfirm", {
									count: selectionCount,
							  })}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							onClick={onModalConfirmDelete}
							fullWidth={branding === "optiswiss"}
						>
							{t("history.delete")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</FrameLayout>
	);
};

export default History;
