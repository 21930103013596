import { SVGProps } from "react";

const SnackWarning = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="42"
			height="42"
			viewBox="0 0 42 42"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.1841 36.3384C12.6184 36.3384 5.67188 29.463 5.67188 20.9779C5.67188 12.4928 12.6184 5.62061 21.1841 5.62061C29.7498 5.62061 36.6964 12.496 36.6964 20.9811"
				fill="white"
			/>
			<path
				d="M36.6963 20.981C36.6963 29.4629 29.753 36.3415 21.1841 36.3415L36.6963 20.981Z"
				fill="white"
			/>
			<path
				d="M21.1841 36.3384C12.6184 36.3384 5.67188 29.463 5.67188 20.9779C5.67188 12.4928 12.6184 5.62061 21.1841 5.62061C29.7498 5.62061 36.6964 12.496 36.6964 20.9811"
				fill="white"
			/>
			<path
				d="M36.6963 20.981C36.6963 29.4629 29.753 36.3415 21.1841 36.3415L36.6963 20.981Z"
				fill="white"
			/>
			<path
				d="M37.0228 20.981C37.0228 29.6277 29.9195 36.6647 21.1841 36.6647L37.0228 20.981Z"
				fill="white"
			/>
			<path
				d="M21.1839 36.6617C12.4517 36.6617 5.34521 29.6279 5.34521 20.9779C5.34521 12.3279 12.4517 5.29736 21.1839 5.29736C29.9161 5.29736 37.0226 12.3311 37.0226 20.9811"
				fill="white"
			/>
			<path
				d="M36.3697 20.9811C36.3697 12.6899 29.5569 5.94385 21.1839 5.94385C12.8108 5.94385 5.99805 12.6899 5.99805 20.9811C5.99805 29.2723 12.8108 36.0184 21.1839 36.0184"
				fill="white"
			/>
			<path
				d="M21.1841 36.0153C29.5572 36.0153 36.3699 29.2692 36.3699 20.978L21.1841 36.0153Z"
				fill="white"
			/>
			<g clipPath="url(#clip0_1_3444)">
				<path
					d="M22.897 14.0327C22.897 12.9104 22.0638 12.0005 21.0361 12.0005C20.0084 12.0005 19.1752 12.9104 19.1752 14.0327V14.175C19.1752 14.2179 19.1774 14.2605 19.182 14.303L20.0296 22.2466C20.0891 22.8045 20.5218 23.2251 21.0361 23.2251C21.5504 23.2251 21.9831 22.8045 22.0426 22.2466L22.8902 14.303C22.8948 14.2605 22.897 14.2176 22.897 14.175V14.0327Z"
					fill="#DD052B"
				/>
				<path
					d="M19.5876 28.4096C19.9793 28.8035 20.4571 29.0006 21.0205 29.0006C21.584 29.0006 22.0377 28.8055 22.4227 28.4152C22.8076 28.0251 23 27.5369 23 26.9515C23 26.3661 22.8076 25.882 22.4227 25.4766C22.0379 25.0714 21.5705 24.8687 21.0205 24.8687C20.4706 24.8687 19.9793 25.0714 19.5876 25.4766C19.1959 25.8818 19 26.3737 19 26.9515C19 27.5294 19.1959 28.0154 19.5876 28.4096Z"
					fill="#DD052B"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_3444">
					<rect
						width="4"
						height="17"
						fill="white"
						transform="matrix(-1 0 0 1 23 12.0005)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default SnackWarning;
