import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getSettings, loadGDPR } from "../../util/api/api-store";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const ModalPrivacyPolicy = () => {
	const { i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const loadGDPRResponse = loadGDPR.useGetResult();

	const { templateContent } = loadGDPRResponse.unwrapOrDefault({
		templateContent: "",
	});

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			loadGDPR.setRequest(null);
			loadGDPR.setUrlparams({
				branding: resp.branding,
				language: getCurrentLng().split("-")[0],
				code: "aMHEfEz2MzChHOcahdv8LhbY1XGRwTWNM4gw5LYkQ6ivtXPDN3FFOQ==",
			});
			loadGDPR.fetchData();
		});
	}, []);

	return (
		<div
			className={cn(
				"container",
				branding === "optiswiss" ? "py-4 px-12" : "py-[72px]"
			)}
		>
			<div
				dangerouslySetInnerHTML={{ __html: templateContent }}
				className="prose my-6"
			></div>
		</div>
	);
};

export default ModalPrivacyPolicy;
