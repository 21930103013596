import * as React from 'react';
import { SVGProps } from 'react';
const SvgPhoto = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 48 48"
		{...props}
	>
		<path
			d="m33.2 12-4-4H18.8l-4 4H6v28h36V12zM24 34c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
			fill={`unset`}
		/>
	</svg>
);
export default SvgPhoto;
