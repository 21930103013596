import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SolidButton } from "../../components/buttons";
import { TextField } from "../../components/input";
import { HeadingText } from "../../components/typography";
import { adminLogin, getSettings, home } from "../../util/api/api-store";
import { unwrapFetchResult } from "../../util/result";
import { getAccessIdAsPromise, useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const Login = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		access_id: "",
		password: "",
	});
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isInputError, setInputError] = useState<boolean>(false);

	const onSubmit = () => {
		setIsSubmited(true);
		setInputError(false);

		const getSettingsResp = unwrapFetchResult(getSettings.getResult());

		adminLogin.setRequest(formData, true);
		adminLogin.invalidate();
		adminLogin
			.fetchData()
			.then((resp) => {
				console.log(resp);
				if (resp.action === "admin") {
					if (getSettingsResp.admin_mode) {
						navigate("/admin/settings", {
							state: { loggedIn: true },
						});
					} else {
						navigate("/admin/setup", {
							state: { setupRequired: true },
						});
					}
				}
				if (resp.action === "factory_reset") {
					navigate("/admin/reset", { state: { loggedIn: true } });
				}
			})
			.catch((err) => {
				console.error(err);
				if (
					err.kind === "api-response-error" &&
					err.responseCode === 400
				) {
					setInputError(true);
				}
			})
			.finally(() => {
				setIsSubmited(false);
			});
	};

	useEffect(() => {
		document.title = t("login.title");

		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
			setFormData({
				access_id: access_id,
				password: "",
			});
		});

		getSettings.setRequest(null);
		getSettings.fetchData();
	}, []);

	return (
		<div
			// eslint-disable-next-line tailwindcss/no-contradicting-classname
			className={cn(
				"h-dvh flex w-screen flex-row items-center justify-center bg-cover bg-center",
				branding === "optiswiss"
					? "bg-[url('/images/os_admin_login.jpeg')]"
					: "bg-[url('/images/admin_bg_min.jpg')]"
			)}
		>
			<div
				className={cn(
					"bg-pure-white mx-4 w-full max-w-lg px-6 pb-6 md:mx-0",
					branding === "optiswiss" && "rounded-[50px]"
				)}
			>
				<div
					className={cn(
						"flex items-center justify-between gap-4 px-6 py-5",
						branding === "optiswiss"
							? "py-11 justify-center"
							: "py-5"
					)}
				>
					<HeadingText h={4}>{t("login.pageTitle")}</HeadingText>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
					className={cn(
						"flex flex-col",
						branding === "optiswiss"
							? " px-12 py-4 gap-16"
							: "border-t border-secondary-40 p-6 gap-6"
					)}
				>
					<TextField
						label={t("admin.password")}
						htmlFor="admin-password"
						type={isInputError ? "alert" : "default"}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						value={formData.password}
						setValue={(v) =>
							setFormData({
								...formData,
								password: v,
							})
						}
						fullWidth
					/>
					<div className="flex flex-row justify-end">
						<SolidButton
							color="primary"
							submit={true}
							disabled={isSubmited}
							fullWidth={branding === "optiswiss"}
						>
							{t("admin.login")}
						</SolidButton>
					</div>
				</form>
			</div>
		</div>
	);
};
export default Login;
