import clsx from "clsx";
import Modal from "react-modal";
import { Loading } from "../icons";

type ModalFullScreenLoadingProps = {
	children: React.ReactNode;
	open: boolean;
	className?: string;
	loadingClass?: string;
};

Modal.setAppElement("#root");

const ModalFullScreenLoading = ({ children, open, className, loadingClass }: ModalFullScreenLoadingProps) => {
	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			className={clsx(
				className,
				"fixed inset-0 overflow-y-auto focus-visible:outline-0"
			)}
			overlayClassName="fixed inset-0 z-30 bg-black/50 transition-opacity"
			bodyOpenClassName="overflow-hidden"
		>
			<div className="flex flex-col min-h-full items-center justify-center">
				<Loading
					className={clsx(
						loadingClass,
						"animate-spin"
					)}
				/>
				{children}
			</div>
		</Modal>
	);
};

export default ModalFullScreenLoading;
