import clsx from "clsx";
import Modal from "react-modal";

type ModalFullScreenProps = {
	children: React.ReactNode;
	open: boolean;
	close: () => void;
	className?: string;
};

Modal.setAppElement("#root");

const ModalFullScreen = ({ children, open, close, className }: ModalFullScreenProps) => {
	return (
		<Modal
			closeTimeoutMS={300}
			isOpen={open}
			onRequestClose={close}
			className={clsx(
				className,
				"bg-pure-white fixed inset-0 overflow-y-auto focus-visible:outline-0"
			)}
			overlayClassName="fixed inset-0 z-30 bg-black/50 transition-opacity"
			bodyOpenClassName="overflow-hidden"
		>
			{children}
		</Modal>
	);
};

export default ModalFullScreen;
