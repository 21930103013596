import { SVGProps } from "react";
const RouteHome = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.53644 16.8185V14.2628C6.53643 13.6152 7.06306 13.089 7.71536 13.0849H10.1105C10.7657 13.0849 11.2969 13.6123 11.2969 14.2628V14.2628V16.8111C11.2969 17.3728 11.7534 17.8293 12.3191 17.8334H13.9532C14.7164 17.8354 15.449 17.5357 15.9893 17.0007C16.5297 16.4656 16.8333 15.739 16.8333 14.9814V7.72162C16.8333 7.10957 16.5601 6.52901 16.0872 6.13633L10.5358 1.72864C9.56543 0.957683 8.17948 0.982588 7.23783 1.7879L1.80584 6.13633C1.31062 6.51743 1.01463 7.09972 1 7.72162V14.9739C1 16.5532 2.28948 17.8334 3.88014 17.8334H5.47691C5.74931 17.8354 6.01124 17.7293 6.20456 17.5388C6.39788 17.3482 6.5066 17.089 6.5066 16.8185H6.53644Z"
				stroke="#DD052B"
			/>
		</svg>
	);
};

export default RouteHome;
