import { SVGProps } from "react";

const OSArrowLeft = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M8 0.930061C8 0.104523 7.1071 -0.311775 6.59147 0.273863C5.1515 1.88966 2.23384 5.1636 0.542346 7.06163C0.536058 7.06869 0.529769 7.07574 0.523481 7.0828C-0.174495 7.866 -0.174495 9.12901 0.523481 9.91222L0.542346 9.93338C2.24641 11.8455 5.17665 15.1406 6.59147 16.7282C6.83042 16.9963 7.18255 17.0739 7.49067 16.9258C7.77992 16.7917 8 16.4813 8 16.072L8 0.930061Z"
				fill="#DD052B"
			/>
			<path
				d="M4 8.5H16"
				stroke="#DD052B"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default OSArrowLeft;
