interface Props {
	xLeft: number;
	xRight: number;
	yLeft: number;
	yRight: number;
	lensWidth: number;
	frameHeight: number;
	distance: number;
}

const OSSizing = ({
	xLeft,
	xRight,
	yLeft,
	yRight,
	lensWidth,
	frameHeight,
	distance,
}: Props) => {
	const paddingX = 20;
	const paddingY = 30;
	const middleX = paddingX + lensWidth + distance / 2;
	const width = lensWidth * 2 + distance + paddingX * 2;
	const height = frameHeight + paddingY * 2 + 8;

	return (
		<svg
			width={`${width}mm`}
			height={`${height}mm`}
			xmlns="http://www.w3.org/2000/svg"
		>
			{/* left L */}
			<path
				d={`M${paddingX * 3.78},${(frameHeight + paddingY) * 3.78} L${
					(paddingX + lensWidth - 10) * 3.78
				},${(frameHeight + paddingY) * 3.78} Q${
					(paddingX + lensWidth) * 3.78
				},${(frameHeight + paddingY) * 3.78} ${
					(paddingX + lensWidth) * 3.78
				},${(frameHeight + paddingY - 10) * 3.78} L${
					(paddingX + lensWidth) * 3.78
				},${paddingY * 3.78}
				}`}
				stroke="var(--primary--100)"
				strokeWidth="1.5"
				fill="none"
			/>

			{/* middle line */}
			<line
				x1={`${middleX}mm`}
				y1={`${paddingY / 1.4}mm`}
				x2={`${middleX}mm`}
				y2={`${frameHeight + paddingY}mm`}
				strokeDasharray="8"
				stroke="var(--primary--100)"
			/>

			{/* right L */}
			<path
				d={`M${(paddingX + lensWidth * 2 + distance) * 3.78},${
					(frameHeight + paddingY) * 3.78
				} L${(paddingX + lensWidth + distance + 10) * 3.78},${
					(frameHeight + paddingY) * 3.78
				} Q${(paddingX + lensWidth + distance) * 3.78},${
					(frameHeight + paddingY) * 3.78
				} ${(paddingX + lensWidth + distance) * 3.78},${
					(frameHeight + paddingY - 10) * 3.78
				} L${(paddingX + lensWidth + distance) * 3.78},${
					paddingY * 3.78
				}
				}`}
				stroke="var(--primary--100)"
				strokeWidth="1.5"
				fill="none"
			/>

			{/* pluses */}
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${paddingY + frameHeight - yLeft - 3}mm`}
				width="1px"
				height="6mm"
				fill="var(--primary--100)"
			/>
			<rect
				x={`${middleX - xLeft - 3}mm`}
				y={`${paddingY + frameHeight - yLeft}mm`}
				width="6mm"
				height="1px"
				fill="var(--primary--100)"
			/>
			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="3mm"
				stroke="var(--primary--100)"
				fill="#DD052B40"
			/>
			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r={`${
					middleX -
					xLeft -
					(paddingX + 10 + (middleX - xLeft - paddingX - 10 - 5) / 2)
				}mm`}
				stroke="var(--primary--100)"
				fill="#DD052B40"
			/>

			<rect
				x={`${middleX + xRight}mm`}
				y={`${paddingY + frameHeight - yRight - 3}mm`}
				width="1px"
				height="6mm"
				fill="var(--primary--100)"
			/>
			<rect
				x={`${middleX + xRight - 3}mm`}
				y={`${paddingY + frameHeight - yRight}mm`}
				width="6mm"
				height="1px"
				fill="var(--primary--100)"
			/>
			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="3mm"
				stroke="var(--primary--100)"
				fill="#DD052B40"
			/>
			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r={`${
					middleX +
					xRight +
					5 +
					(middleX - xRight - paddingX - 10 - 5) / 2 -
					(middleX + xRight)
				}mm`}
				stroke="var(--primary--100)"
				fill="#DD052B40"
			/>

			{/* left circles */}
			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="27.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${paddingY + frameHeight - yLeft - 28.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${paddingY + frameHeight - yLeft - 26.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			>
				55
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="30mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${paddingY + frameHeight - yLeft - 31}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${paddingY + frameHeight - yLeft - 29}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			>
				60
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="32.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${paddingY + frameHeight - yLeft - 33.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${paddingY + frameHeight - yLeft - 31.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			>
				65
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="35mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${paddingY + frameHeight - yLeft - 36}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${paddingY + frameHeight - yLeft - 34}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			>
				70
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${paddingY + frameHeight - yLeft}mm`}
				r="37.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${paddingY + frameHeight - yLeft - 38.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${paddingY + frameHeight - yLeft - 36.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(paddingY + frameHeight - yLeft) * 3.78
				})`}
			>
				75
			</text>

			{/* right circles */}
			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="27.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${paddingY + frameHeight - yRight - 28.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${paddingY + frameHeight - yRight - 26.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			>
				55
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="30mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${paddingY + frameHeight - yRight - 31}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${paddingY + frameHeight - yRight - 29}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			>
				60
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="32.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${paddingY + frameHeight - yRight - 33.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${paddingY + frameHeight - yRight - 31.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			>
				65
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="35mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${paddingY + frameHeight - yRight - 36}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${paddingY + frameHeight - yRight - 34}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			>
				70
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${paddingY + frameHeight - yRight}mm`}
				r="37.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${paddingY + frameHeight - yRight - 38.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${paddingY + frameHeight - yRight - 36.5}mm`}
				fill="var(--pure-black--100)"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(paddingY + frameHeight - yRight) * 3.78
				})`}
			>
				75
			</text>

			{/* lines above pluses */}
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${paddingY / 1.4}mm`}
				width="1px"
				height={`${
					paddingY + frameHeight - yLeft - 3 - paddingY / 1.4
				}mm`}
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${paddingY / 1.15}mm`}
				height="1px"
				width={`${xLeft}mm`}
			/>
			<text
				x={`${middleX - xLeft / 2 - 1}mm`}
				y={`${paddingY / 1.15 - 1}mm`}
				fill="var(--pure-black--100)"
			>
				x
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${middleX - xLeft},${paddingY / 1.15 + 0.15} ${
						middleX - xLeft + 2
					},${paddingY / 1.15 - 1.125 + 0.15} ${
						middleX - xLeft + 2
					},${paddingY / 1.15 + 1.125 + 0.15}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${middleX},${paddingY / 1.15 + 0.15} ${
						middleX - 2
					},${paddingY / 1.15 - 1.125 + 0.15} ${middleX - 2},${
						paddingY / 1.15 + 1.125 + 0.15
					}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${middleX},${paddingY / 1.15 + 0.15} ${
						middleX + 2
					},${paddingY / 1.15 - 1.125 + 0.15} ${middleX + 2},${
						paddingY / 1.15 + 1.125 + 0.15
					}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${middleX + xRight},${paddingY / 1.15 + 0.15} ${
						middleX + xRight - 2
					},${paddingY / 1.15 - 1.125 + 0.15} ${
						middleX + xRight - 2
					},${paddingY / 1.15 + 1.125 + 0.15}`}
					fill="var(--pure-black--100)"
				/>
			</g>

			<rect
				x={`${middleX + xRight}mm`}
				y={`${paddingY / 1.4}mm`}
				width="1px"
				height={`${
					paddingY + frameHeight - yRight - 3 - paddingY / 1.4
				}mm`}
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${middleX}mm`}
				y={`${paddingY / 1.15}mm`}
				height="1px"
				width={`${xRight}mm`}
				fill="var(--pure-black--100)"
			/>
			<text
				x={`${middleX + xRight / 2 - 1}mm`}
				y={`${paddingY / 1.15 - 1}mm`}
				fill="var(--pure-black--100)"
			>
				x
			</text>

			{/* lines besides pluses */}
			<rect
				x={`${
					paddingX + 10 + (middleX - xLeft - paddingX - 10 - 5) / 2
				}mm`}
				y={`${paddingY + frameHeight - yLeft}mm`}
				height="1px"
				width={`${
					middleX -
					xLeft -
					3 -
					(paddingX + 10 + (middleX - xLeft - paddingX - 10 - 5) / 2)
				}mm`}
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${
					paddingX + 10 + (middleX - xLeft - paddingX - 10 - 5) / 2
				}mm`}
				y={`${paddingY + frameHeight - yLeft}mm`}
				width="1px"
				height={`${yLeft}mm`}
				fill="var(--pure-black--100)"
			/>
			<text
				x={`${
					paddingX + 7 + (middleX - xLeft - paddingX - 10 - 5) / 2
				}mm`}
				y={`${paddingY + frameHeight - yLeft / 2 + 1}mm`}
			>
				y
			</text>
			<rect
				x={`${middleX + xRight + 3}mm`}
				y={`${paddingY + frameHeight - yRight}mm`}
				height="1px"
				width={`${
					middleX +
					xRight +
					5 +
					(middleX - xRight - paddingX - 10 - 5) / 2 -
					(middleX + xRight + 3)
				}mm`}
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${
					middleX +
					xRight +
					5 +
					(middleX - xRight - paddingX - 10 - 5) / 2
				}mm`}
				y={`${paddingY + frameHeight - yRight}mm`}
				width="1px"
				height={`${yRight}mm`}
				fill="var(--pure-black--100)"
			/>
			<text
				x={`${
					middleX +
					xRight +
					6 +
					(middleX - xRight - paddingX - 10 - 5) / 2
				}mm`}
				y={`${paddingY + frameHeight - yRight / 2 + 1}mm`}
			>
				y
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 +
						0.15
					},${paddingY + frameHeight - yRight} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 -
						1.125 +
						0.15
					},${paddingY + frameHeight - yRight + 2} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 +
						1.125 +
						0.15
					},${paddingY + frameHeight - yRight + 2}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 +
						0.15
					},${paddingY + frameHeight} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 -
						1.125 +
						0.15
					},${paddingY + frameHeight - 2} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - paddingX - 10 - 5) / 2 +
						1.125 +
						0.15
					},${paddingY + frameHeight - 2}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 +
						0.15
					},${paddingY + frameHeight - yLeft} ${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 -
						1.125 +
						0.15
					},${paddingY + frameHeight - yLeft + 2} ${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 +
						1.125 +
						0.15
					},${paddingY + frameHeight - yLeft + 2}`}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 +
						0.15
					},${paddingY + frameHeight} ${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 -
						1.125 +
						0.15
					},${paddingY + frameHeight - 2} ${
						paddingX +
						10 +
						(middleX - xLeft - paddingX - 10 - 5) / 2 +
						1.125 +
						0.15
					},${paddingY + frameHeight - 2}`}
					fill="var(--pure-black--100)"
				/>
			</g>

			<text
				x="5mm"
				y="15mm"
				fill="var(--pure-black--100)"
				style={{
					fontSize: "3rem",
					color: "black",
					fontWeight: "bold",
					fontFamily: "sans-serif",
				}}
			>
				L
			</text>
			<text
				x={`${width - 15}mm`}
				y="15mm"
				fill="var(--pure-black--100)"
				style={{
					fontSize: "3rem",
					color: "black",
					fontWeight: "bold",
					fontFamily: "sans-serif",
				}}
			>
				R
			</text>

			<rect
				x={`${paddingX}mm`}
				y={`${height - 15}mm`}
				height="1px"
				width="10cm"
				fill="var(--pure-black--100)"
			/>
			<rect
				x={`${paddingX}mm`}
				y={`${height - 18}mm`}
				height="6mm"
				width="1px"
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${paddingX + 100}mm`}
				y={`${height - 18}mm`}
				height="6mm"
				width="1px"
				fill="var(--quaternary--80)"
			/>
			<text
				x={`${paddingX + 46}mm`}
				y={`${height - 18}mm`}
				fill="var(--pure-black--100)"
			>
				10cm
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${paddingX},${height - 15 + 0.15} ${
						paddingX + 2
					},${height - 15 - 1.125 + 0.15} ${paddingX + 2},${
						height - 15 + 1.125 + 0.15
					} `}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${paddingX + 100},${height - 15 + 0.15} ${
						paddingX + 100 - 2
					},${height - 15 - 1.125 + 0.15} ${paddingX + 100 - 2},${
						height - 15 + 1.125 + 0.15
					} `}
					fill="var(--pure-black--100)"
				/>
			</g>

			<rect
				x={`${paddingX}mm`}
				y={`${height - 5}mm`}
				height="1px"
				width="5in"
			/>
			<rect
				x={`${paddingX}mm`}
				y={`${height - 8}mm`}
				height="6mm"
				width="1px"
				fill="var(--quaternary--80)"
			/>
			<rect
				x={`${paddingX + 127}mm`}
				y={`${height - 8}mm`}
				height="6mm"
				width="1px"
				fill="var(--quaternary--80)"
			/>
			<text
				x={`${paddingX + 57}mm`}
				y={`${height - 8}mm`}
				fill="var(--pure-black--100)"
			>
				5inch
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${paddingX},${height - 5 + 0.15} ${paddingX + 2},${
						height - 5 - 1.125 + 0.15
					} ${paddingX + 2},${height - 5 + 1.125 + 0.15} `}
					fill="var(--pure-black--100)"
				/>
				<polygon
					points={`${paddingX + 127},${height - 5 + 0.15} ${
						paddingX + 127 - 2
					},${height - 5 - 1.125 + 0.15} ${paddingX + 127 - 2},${
						height - 5 + 1.125 + 0.15
					} `}
					fill="var(--pure-black--100)"
				/>
			</g>
		</svg>
	);
};

export default OSSizing;
