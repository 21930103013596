import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	master_system: string;
	mainboard_serial_nr: string;
	public_version: string;
	space_info: {
		available: number;
		capacity: number;
		free: number;
	};
	system_nr: number;
	user_instructions_url: string; // Could be a problem
	versions: {
		firm_ware: string;
		frame_tracer: string;
		hard_ware: string;
		inet_tools_master: string;
		inet_tools_tegra: string;
		master: string;
		nxweb: string;
		server: string;
		user_interface: string;
		web_handler: string;
	};
}

export type IResponseError = null;

const responseDataShape = J.object({
	master_system: J.string().allow(""),
	mainboard_serial_nr: J.string().allow(""),
	public_version: J.string().allow(""),
	space_info: J.object({
		available: J.number(),
		capacity: J.number(),
		free: J.number(),
	}).unknown(),
	system_nr: J.number(),
	user_instructions_url: J.string().allow(""),
	versions: J.object({
		firm_ware: J.string().allow(""),
		frame_tracer: J.string().allow(""),
		hard_ware: J.string().allow(""),
		inet_tools_master: J.string().allow(""),
		inet_tools_tegra: J.string().allow(""),
		master: J.string().allow(""),
		nxweb: J.string().allow(""),
		server: J.string().allow(""),
		user_interface: J.string().allow(""),
		web_handler: J.string().allow(""),
	}),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "about";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
