import J from "joi";

export interface IRequestData {
	access_id: string;
	action: string;
}

export interface IResponseData {
	editable: {
		_L: {
			iris: {
				changed: boolean;
				position: number[];
			};
			iris_radius_pixels: number;
		};
		_R: {
			iris: {
				changed: boolean;
				position: number[];
			};
			iris_radius_pixels: number;
		};
	};
	images: {
		_CO: string;
		_CU: string;
	};
	measurement: string;
	order_id: string;
	page: number;
	time_stamp: string;
}

export type IResponseError = null;

const responseDataShape = J.object({
	editable: J.object({
		_L: J.object({
			iris: J.object({
				changed: J.boolean(),
				position: J.array().items(J.number()),
			}).unknown(),
			iris_radius_pixels: J.number(),
		}).unknown(),
		_R: J.object({
			iris: J.object({
				changed: J.boolean(),
				position: J.array().items(J.number()),
			}).unknown(),
			iris_radius_pixels: J.number(),
		}).unknown(),
	}).unknown(),
	images: J.object({
		_CO: J.string(),
		_CU: J.string(),
	}).unknown(),
	measurement: J.string(),
	order_id: J.string().allow(""),
	page: J.number(),
	time_stamp: J.string(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "shot";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
