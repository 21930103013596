import {
	RouteHome,
	RouteMeasurement,
	RouteHistory,
	RouteSettings,
	RouteHelp,
	RouteMaintenance,
} from "./components/icons";

export const routes = [
	{
		name: "home",
		path: "/",
		icon: RouteHome,
	},
	{
		name: "measurement",
		path: "/measurement",
		icon: RouteMeasurement,
	},
	{
		name: "history",
		path: "/history",
		icon: RouteHistory,
	},
	{
		name: "settings",
		path: "/settings",
		icon: RouteSettings,
	},
	{
		name: "help",
		path: "/help",
		icon: RouteHelp,
	},
	{
		name: "maintainance",
		path: "/maintainance",
		icon: RouteMaintenance,
	},
];

export default routes;
