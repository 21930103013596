import { SVGProps } from 'react';
const Dot = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		width={props.width || 24}
		height={props.height || 24}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 10 8"
		role="img"
		{...props}
	>
		<path transform="translate(2.5,2)" d="M2.388 0.116C1.884 0.116 1.452 0.296 1.116 0.632C0.768 0.968 0.6 1.376 0.6 1.844C0.6 2.336 0.768 2.744 1.104 3.068C1.44 3.404 1.872 3.56 2.388 3.56C2.892 3.56 3.324 3.404 3.66 3.068C3.996 2.744 4.176 2.336 4.176 1.844C4.176 1.376 3.996 0.968 3.66 0.632C3.312 0.296 2.892 0.116 2.388 0.116Z"/>
	</svg>
);
export default Dot;
