import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextButton } from "./components/buttons";
import { Back } from "./components/icons";
import { FrameLayout } from "./components/layouts";
import { HeadingText } from "./components/typography";

function NotFound() {
	const { t } = useTranslation();
	const error = useRouteError();
	console.error(error);

	return (
		<FrameLayout headerType="onboarding" hiddenFooter>
			<div className="container py-16 text-center">
				<HeadingText h={1} className="mb-8">
					404
				</HeadingText>
				<TextButton color="primary" icon={Back} to="/">
					{t("notFound.back")}
				</TextButton>
			</div>
		</FrameLayout>
	);
}

export default NotFound;
