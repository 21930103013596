import { useEffect, useState, useRef, Fragment } from "react";
import { SolidButton, TextButton } from "../../components/buttons";
import { Info } from "../../components/icons";
import { PlainCheckbox } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import { BodyText } from "../../components/typography";
import HeadingText from "../../components/typography/TextHeading";
import { ModalBase } from "../../components/modals";
import clsx from "clsx";
import { getAccessIdAsPromise, useHeaderStore } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackStore } from "../../store";
import { getStereoTweakingOptions, setStereoTweakingOptions } from "../../util/api/api-store";
import { type Option } from "../../util/api/request-definitions/get-stereo-tweaking-options";
import { useTranslation } from "react-i18next";

const formstate: Option[] = [
	{
		active: false,
		auto: false,
	},
	{
		active: false,
		auto: false,
	},
	{
		active: false,
		auto: false,
	},
	{
		active: false,
		auto: false,
	},
	{
		active: false,
		auto: false,
	},
];

enum ModalContentState {
	active = "active",
	auto = "auto",
	reset = "reset",
}

const Calibration = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { loggedOut } = useHeaderStore();
	const { state } = useLocation();
	const openSnack = useSnackStore((state) => state.open);

	const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
	const [isLeaveModalOpen, setIsLeaveModalOpen] = useState<boolean>(false);
	const [modalContentState, setModalContentState] =
		useState<ModalContentState>(ModalContentState.active);
	const [formState, setFormState] = useState<Option[]>([]);
	// const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [initialState, setInitialState] = useState<string>("");
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);

	const onSubmit = () => {
		setIsSubmited(true);
		getAccessIdAsPromise().then((access_id) => {
			setStereoTweakingOptions.setRequest({
				access_id,
				options: formState
			});
			setStereoTweakingOptions.fetchData().then(() => {
				getStereoTweakingOptions.invalidate();
				getStereoTweakingOptions.setRequest({ access_id });
				getStereoTweakingOptions.fetchData();
				openSnack(t("maintainance.savedSuccessfully"), "ok");
				navigate("/");
			})
			.catch((error) => {
				console.error(error);
				setIsErrorModalOpen(true);
			})
			.finally(() => {
				setIsSubmited(false);
			});
		});
	};

	const handleCheckboxChange = (index: number, type: string) => {
		const newState = [...formState];
		if (type === "active") {
			newState[index].active = !newState[index].active;
		} else if (type === "auto") {
			newState[index].auto = !newState[index].auto;
		} else if (type === "reset") {
			newState[index].reset = !newState[index].reset;
		}
		setFormState(newState);
	};

	const hasChanged = () => {
		return initialState !== JSON.stringify(formState);
	};

	const handleModalOpen = (type: ModalContentState) => {
		setModalContentState(type);
		setIsInfoModalOpen(true);
	};

	useEffect(() => {
		if (!state?.loggedIn) {
			navigate("/maintainance/login");
		}

		const unSub = useHeaderStore.subscribe((state) => {
			if (state.logout) {
				if (hasChanged()) {
					setIsLeaveModalOpen(true);
					return;
				}

				navigate("/");
				loggedOut();
			}
		});

		getAccessIdAsPromise().then((access_id) => {
			getStereoTweakingOptions.setRequest({ access_id });
			getStereoTweakingOptions.fetchData().then((resp) => {
				const initState: Option[] = [];
				resp.map((item) => {
					initState.push({
						active: item.active,
						auto: item.auto,
						reset: false,
					});
				});
				setInitialState(JSON.stringify(initState));
				setFormState(initState);
			});
		});

		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}

		return () => unSub();
	}, []);

	return (
		<>
			<FrameLayout hiddenFooter headerType="admin">
				<div className="container">
					<HeadingText h={2} className="mb-8 mt-16 md:mb-12">
						{t("maintainance.pageTitle")}
					</HeadingText>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							onSubmit();
						}}
					>
						<div className="flex items-center justify-between py-6">
							<BodyText
								type="normal"
								className="text-xs text-primary-dark-100"
							>
								{t("maintainance.cameras")}
							</BodyText>
							<div className="flex w-[14rem] items-center justify-between md:w-[18rem]">
								<div className="inline-flex items-center gap-1">
									<BodyText
										type="normal"
										className="text-xs text-primary-dark-100"
									>
										{t("maintainance.active")}
									</BodyText>
									<Info
										width={16}
										height={16}
										onClick={() =>
											handleModalOpen(
												ModalContentState.active
											)
										}
										className="cursor-pointer"
									/>
								</div>
								<div className="inline-flex items-center gap-1">
									<BodyText
										type="normal"
										className="text-xs text-primary-dark-100"
									>
										{t("maintainance.auto")}
									</BodyText>
									<Info
										width={16}
										height={16}
										onClick={() =>
											handleModalOpen(
												ModalContentState.auto
											)
										}
										className="cursor-pointer"
									/>
								</div>
								<div className="inline-flex items-center gap-1">
									<BodyText
										type="normal"
										className="text-xs text-primary-dark-100"
									>
										{t("maintainance.reset")}
									</BodyText>
									<Info
										width={16}
										height={16}
										onClick={() =>
											handleModalOpen(
												ModalContentState.reset
											)
										}
										className="cursor-pointer"
									/>
								</div>
							</div>
						</div>
						<div>
							{formstate.map((_item, index) => (
								<Fragment key={index}>
									<div className="flex h-16 items-center justify-between last:border-none">
										<BodyText
											type="bold16"
											className="text-base text-primary-dark-100"
										>
											{"CP" + (index + 1)}
										</BodyText>
										<div className="flex w-[14rem] items-center justify-between px-4 md:w-[18rem]">
											<PlainCheckbox
												checked={
													formState[index]?.active
														? "checked"
														: "unchecked"
												}
												setChecked={() =>
													handleCheckboxChange(
														index,
														"active"
													)
												}
											/>
											<PlainCheckbox
												checked={
													formState[index]?.auto
														? "checked"
														: "unchecked"
												}
												setChecked={() =>
													handleCheckboxChange(
														index,
														"auto"
													)
												}
											/>
											<PlainCheckbox
												checked={
													formState[index]?.reset
														? "checked"
														: "unchecked"
												}
												setChecked={() =>
													handleCheckboxChange(
														index,
														"reset"
													)
												}
											/>
										</div>
									</div>
									<hr className="my-3 border-t border-t-secondary-40 last:hidden" />
								</Fragment>
							))}
						</div>
						<div
							ref={buttonRef}
							className={clsx(
								"sticky bottom-[-1px] mt-4 backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
								stuck && "shadow-[0px_-1px_0px_#e6e8e9]"
							)}
						>
							<div className="grid h-[72px] items-center justify-end md:container">
								<SolidButton
									color="primary"
									disabled={hasChanged() || isSubmited ? false : true}
									onClick={onSubmit}
									loading={isSubmited}
								>
									{t("maintainance.saveNLogout")}
								</SolidButton>
							</div>
						</div>
					</form>
				</div>
			</FrameLayout>
			<ModalBase
				title={t(`maintainance.modalContent.${modalContentState}.title`)}
				open={isInfoModalOpen}
				close={() => setIsInfoModalOpen(false)}
			>
				<div className="px-6 py-5">
					<BodyText type="normal">
						{t(`maintainance.modalContent.${modalContentState}.content`)}
					</BodyText>
				</div>
			</ModalBase>
			<ModalBase
				title={t("maintainance.failedToSave")}
				open={isErrorModalOpen}
				close={() => setIsErrorModalOpen(false)}
			>
				<div className="flex flex-col px-6 py-5">
					<BodyText type="normal">
						{t("maintainance.errorDescription")}
					</BodyText>
					<SolidButton
						color="primary"
						className="mt-8 w-fit self-end"
						onClick={() => {
							onSubmit();
							setIsErrorModalOpen(false);
						}}
					>
						{t("maintainance.tryAgain")}
					</SolidButton>
				</div>
			</ModalBase>
			<ModalBase
				title={t("maintainance.leaveMeasurement")}
				open={isLeaveModalOpen}
				close={() => setIsLeaveModalOpen(false)}
			>
				<div className="p-6">
					<p className="mb-6">
						{t("maintainance.leaveMeasurementConfirm")}
					</p>
					<SolidButton color="primary" onClick={onSubmit} fullWidth>
						{t("maintainance.saveNLogout")}
					</SolidButton>
					<TextButton
						color="primary"
						className="mt-4 text-primary-100"
						onClick={() => {
							loggedOut();
							navigate("/");
						}}
						fullWidth
					>
						{t("maintainance.quitWithoutSave")}
					</TextButton>
				</div>
			</ModalBase>
		</>
	);
};

export default Calibration;
