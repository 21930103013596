type labelInput = {
	eye_to_lens_dist: number;
	lens_thickness: number;
};

type FrameProps = {
	className?: string;
	data: labelInput;
};

const Frame2 = ({ className, data }: FrameProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="165"
		height="130"
		fill="none"
		className={className}
	>
		<text
			xmlSpace="preserve"
			fill="#2D2926"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="73.199" y="122.54">
				{data.eye_to_lens_dist.toFixed(1)}
			</tspan>
		</text>
		<text
			xmlSpace="preserve"
			fill="#2D2926"
			fontFamily="var(--page-font)"
			fontSize="12"
			letterSpacing="0em"
		>
			<tspan x="38.152" y="20.54">
				{data.lens_thickness.toFixed(1)}
			</tspan>
		</text>
	</svg>
);
export default Frame2;
