import { SVGProps } from "react";

const OSArrowDown = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 25 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.60839 9.16699C5.92044 9.16699 5.57352 9.91108 6.06155 10.3408C7.40805 11.5407 10.1363 13.9721 11.718 15.3817C11.7239 15.3869 11.7298 15.3922 11.7357 15.3974C12.3883 15.9791 13.4408 15.9791 14.0935 15.3974L14.1112 15.3817C15.7046 13.9616 18.4505 11.5198 19.7735 10.3408C19.9969 10.1416 20.0616 9.8482 19.9381 9.59144C19.8264 9.35039 19.5677 9.16699 19.2267 9.16699L6.60839 9.16699Z"
				fill="#DD052B"
			/>
		</svg>
	);
};

export default OSArrowDown;
