import { Done, GreenTick, OSWarning, Warning } from "../icons";
import { ButtonText } from "../typography";
import { useBrandingStore } from "../../store";

type SettingsConnectivityStatusProps = {
	children: string | React.ReactNode;
	type: "success" | "warning";
};

const SettingsConnectivityStatus = ({
	children,
	type,
}: SettingsConnectivityStatusProps) => {
	const { branding } = useBrandingStore();

	return (
		<div className="text-primary-dark-100 flex flex-row gap-3">
			{type === "success" ? (
				<>
					{branding === "optiswiss" ? (
						<GreenTick className="size-6 [&>g]:filter-none translate-y-0.5" />
					) : (
						<Done />
					)}
				</>
			) : (
				<>
					{branding === "optiswiss" ? (
						<OSWarning className="size-6 [&>g]:filter-none" />
					) : (
						<Warning />
					)}
				</>
			)}
			<ButtonText type="normal">{children}</ButtonText>
		</div>
	);
};

export default SettingsConnectivityStatus;
