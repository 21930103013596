import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="ArrowDown_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="ArrowDown_svg__a"
			height={5}
			maskUnits="userSpaceOnUse"
			width={10}
			x={7}
			y={10}
		>
			<path d="m7 10 5 5 5-5z" fill="#fff" />
		</mask>
		<g
			fill={`unset`}
			mask="url(#ArrowDown_svg__a)"
			clipPath="url(#ArrowDown_svg__b)"
		>
			<path d="m7 10 5 5 5-5z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgArrowDown;
