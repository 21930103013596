import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeadingText from "../../components/typography/TextHeading";
import TextField from "../../components/input/TextField";
import SolidButton from "../../components/buttons/SolidButton";
import { useTranslation } from "react-i18next";
import { Close } from "../../components/icons";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const Login = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [isInputError, setInputError] = useState<boolean>(false);
	const [formData, setFormData] = useState({
		access_id: "",
		password: "",
	});
	const onSubmit = () => {
		const pw = "StereoAdjustment";
		if (formData.password === pw) {
			setInputError(false);
			navigate("/maintainance/calibration", {
				state: { loggedIn: true },
			});
		} else {
			setInputError(true);
		}
	};

	return (
		<div
			className={cn(
				"h-dvh flex w-screen flex-row items-center justify-center  bg-cover bg-center",
				branding === "optiswiss"
					? "bg-[url('/images/optiswiss-login.jpeg')]"
					: "bg-[url('/images/calibration_bg.jpg')]"
			)}
		>
			<div
				className={cn(
					"mx-4 w-full max-w-lg bg-pure-white px-6 pb-6 md:mx-0",
					branding === "optiswiss" && "rounded-[50px]"
				)}
			>
				<div
					className={cn(
						"flex items-center justify-between gap-4 px-6 ",
						branding === "optiswiss" ? "py-8" : "py-5"
					)}
				>
					<HeadingText h={4}>
						{t("maintainance.loginTitle")}
					</HeadingText>
					<Link
						to="/"
						className={cn(
							branding === "optiswiss" &&
								"!rounded-[10px] border border-quaternary-80 p-[5px] h-fit"
						)}
					>
						<Close
							className={cn(
								branding === "optiswiss" && "size-[17px]"
							)}
						/>
					</Link>
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						onSubmit();
					}}
					className={cn(
						"flex flex-col  border-t border-secondary-40 px-6",
						branding === "optiswiss" ? "py-8 gap-16" : "py-6 gap-6"
					)}
				>
					<TextField
						label={t("maintainance.password")}
						htmlFor="admin-password"
						type={isInputError ? "alert" : "default"}
						showPasswordButton={true}
						fieldOptions={{ type: "password", autoFocus: true }}
						value={formData.password}
						setValue={(v) =>
							setFormData({
								...formData,
								password: v,
							})
						}
						fullWidth
					/>
					<div className="flex flex-row justify-end">
						<SolidButton
							color="primary"
							submit={true}
							fullWidth={branding === "optiswiss"}
						>
							{t("maintainance.login")}
						</SolidButton>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
