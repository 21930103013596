import * as React from 'react';
import { SVGProps } from 'react';
const SvgFaq = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M3 4v18l4-4h14V4zm12 10H5v-2h10zm4-4H5V8h14z" fill={`unset`} />
	</svg>
);
export default SvgFaq;
