import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import routes from "../../routes";
import { useBrandingStore, useMenuStore } from "../../store";
import { Close, OSInfo, OSLoading, RouteLogout, TurnOff } from "../icons";
import BrandLogo from "../logo/BrandLogo";
import { HeadingText } from "../typography";
import { TextButton } from "../buttons";
import { getSettings, shutdown } from "../../util/api/api-store";
import LoadingPanel from "../modals/LoadigPanel";
import { ModalBase, ModalFullScreen } from "../modals";
import TextHeading from "../typography/TextHeading";
import TextBody from "../typography/TextBody";
import EyesWithCheck from "../icons/EyesWithCheck";
import { cn } from "../../util/helpers";
import Footer from "./Footer";

type ShutdownProcess = {
	started: boolean;
	errorOccured: boolean;
	finished: boolean;
};

// TODO change names according to language
const SidebarMenu = () => {
	const location = useLocation();
	const { close, opened } = useMenuStore();
	const { t } = useTranslation();
	const [shutdownProcess, setShutdownProcess] = useState<ShutdownProcess>({
		started: false,
		errorOccured: false,
		finished: false,
	});

	const submitShutdown = () => {
		setShutdownProcess({
			started: true,
			errorOccured: false,
			finished: false,
		});
		shutdown.setRequest(null, true);
		shutdown.invalidate();
		shutdown
			.fetchData()
			.then((resp) => {
				setShutdownProcess({ ...shutdownProcess, finished: true });
			})
			.catch((err) => {
				setShutdownProcess({ ...shutdownProcess, errorOccured: true });
			});
	};

	// const { branding } = getSettings
	// 	.useGetResult()
	// 	.map((resp) => ({
	// 		branding: resp.branding,
	// 	}))
	// 	.unwrapOrDefault({
	// 		branding: "hoya",
	// 	});
	const { branding } = useBrandingStore();

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData();
	});

	useEffect(() => {
		close();
		document.body.classList.remove("overflow-hidden");
	}, [location]);

	return (
		<>
			<div
				className={cn(
					opened ? "opacity-100" : "pointer-events-none opacity-0",
					"fixed z-30  transition-opacity duration-300 ease-in-out",
					branding === "optiswiss"
						? "bg-black/30 backdrop-blur-sm left-0 top-[85px] w-full h-full"
						: "bg-black/50 inset-0"
				)}
				onClick={() => {
					close();
					document.body.classList.remove("overflow-hidden");
				}}
			></div>
			<div
				className={cn(
					branding !== "optiswiss" &&
						(opened ? "translate-x-0" : "-translate-x-full"),
					opened
						? "opacity-100 pointer-events-auto"
						: "opacity-0 pointer-events-none",
					"fixed left-0 z-30 grid  overflow-y-auto duration-300 ease-in-out",
					branding === "optiswiss"
						? "w-full top-[85px] h-[calc(100%-85px)] transition-opacity grid-rows-1"
						: "bg-quaternary-20 w-[360px] top-0 h-dvh transition-transform grid-rows-[auto_1fr]"
				)}
			>
				<nav
					className={cn(
						"flex flex-col gap-7",
						branding === "optiswiss" && "container py-12"
					)}
				>
					<div
						className={cn(
							"ml-3 mt-3",
							branding === "optiswiss" && "hidden"
						)}
					>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							onClick={() => {
								close();
								document.body.classList.remove(
									"overflow-hidden"
								);
							}}
						/>
					</div>
					<ul
						className={cn(
							"flex flex-col px-6",
							branding === "optiswiss" ? "p-0 gap-4" : "gap-8"
						)}
					>
						{routes.map((route) => (
							<li
								key={route.path}
								className={cn(
									branding === "optiswiss" &&
										"bg-white rounded-[10px]"
								)}
							>
								<Link
									to={route.path}
									className={cn(
										branding === "optiswiss" &&
											"grid grid-cols-[20px_1fr] items-center gap-3 py-4 px-5"
									)}
								>
									{branding === "optiswiss" &&
										route.icon({ className: "size-5" })}
									<HeadingText
										h={4}
										className={cn(
											branding === "optiswiss" &&
												"font-normal text-lg"
										)}
									>
										{t(`menu.${route.name}`)}
									</HeadingText>
								</Link>
							</li>
						))}
						<li>
							<button
								type="button"
								onClick={submitShutdown}
								className={cn(
									branding === "optiswiss"
										? "grid grid-cols-[20px_1fr] items-center gap-3 bg-white rounded-[10px] w-full text-left py-4 px-5"
										: "flex flex-row items-center gap-2"
								)}
							>
								{branding === "optiswiss" ? (
									<RouteLogout className="size-5" />
								) : (
									<TurnOff className="fill-primary-dark-100" />
								)}
								<HeadingText
									h={4}
									className={cn(
										branding === "optiswiss" &&
											"font-normal text-lg"
									)}
								>
									{t("menu.shutdown")}
								</HeadingText>
							</button>
						</li>
					</ul>
				</nav>
				<div
					className={cn(
						"m-6 self-end",
						branding === "optiswiss" && "hidden"
					)}
				>
					<BrandLogo
						override={branding}
						size={branding === "vs" ? "40px" : undefined}
					/>
				</div>
				{branding === "optiswiss" && <Footer stickyFooter />}
			</div>
			<LoadingPanel
				open={shutdownProcess.started}
				loadingClass={cn("min-w-[48px] min-h-[48px] fill-white")}
				className={cn(branding === "optiswiss" && "bg-pure-white")}
			>
				{branding === "optiswiss" ? (
					<OSLoading className="animate-spin-slow" />
				) : (
					<TextHeading
						h={4}
						className="text-2xl font-bold text-white"
					>
						{t("sidebarMenu.shuttingDown")}
					</TextHeading>
				)}
			</LoadingPanel>
			<ModalBase
				title={t("sidebarMenu.error") || undefined}
				open={shutdownProcess.errorOccured}
				close={() =>
					setShutdownProcess({
						...shutdownProcess,
						started: false,
						errorOccured: false,
					})
				}
			>
				<TextBody className="px-6 py-5" type={"normal"}>
					{t("sidebarMenu.pleasUnplug")}
				</TextBody>
			</ModalBase>
			{branding === "optiswiss" ? (
				<ModalBase
					open={shutdownProcess.finished}
					disableHeader
					close={() => undefined}
				>
					<div
						className={cn(
							"flex flex-col items-center px-16 py-16 gap-9"
						)}
					>
						<OSInfo />
						<div className={cn("flex flex-col items-center gap-4")}>
							<TextHeading className={cn("!text-[26px]")} h={1}>
								{t("shutdown.title")}
							</TextHeading>
							<TextBody type={"normal"}>
								{t("shutdown.text")}
							</TextBody>
						</div>
					</div>
				</ModalBase>
			) : (
				<ModalFullScreen
					open={shutdownProcess.finished}
					close={() => undefined}
				>
					<div className="flex min-h-full flex-col items-center justify-center">
						<EyesWithCheck className="min-h-[80px] min-w-[128px]" />
						<TextHeading className="mb-3 mt-10" h={2}>
							{t("shutdown.title")}
						</TextHeading>
						<TextBody type={"normal"}>
							{t("shutdown.text")}
						</TextBody>
					</div>
				</ModalFullScreen>
			)}
		</>
	);
};

export default SidebarMenu;
