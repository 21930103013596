import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	success: boolean
}

export type IResponseError = null;

const responseDataShape = J.object({
	success: J.boolean()
})
	.required();

const responseErrorShape = J.object({
	error: J.string(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "test";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
