import * as React from 'react';
import { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Download_svg__a">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<g clipPath="url(#Download_svg__a)">
			<path
				clipRule="evenodd"
				d="M13 13h4l-5 5-5-5h4V2h2zm-9 7h16v-4h2v6H2v-6h2z"
				fill={`unset`}
				fillRule="evenodd"
			/>
		</g>
	</svg>
);
export default SvgDownload;
