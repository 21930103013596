import * as React from 'react';
import { SVGProps } from 'react';
const SvgNext = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M3 13h13v4l5-5-5-5v4H3z" fill={`unset`} />
	</svg>
);
export default SvgNext;
