import * as React from 'react';
import { SVGProps } from 'react';
const SvgDone = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Done_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Done_svg__a"
			height={14}
			maskUnits="userSpaceOnUse"
			width={18}
			x={3}
			y={5}
		>
			<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" fill="#fff" />
		</mask>
		<g fill={`unset`} mask="url(#Done_svg__a)" clipPath="url(#Done_svg__b)">
			<path d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgDone;
