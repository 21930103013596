import { useEffect, useState } from "react";
import { useSnackStore } from "../../store";
import { Close, Done, SnackOk, SnackWarning, Warning } from "../icons";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import { BodyText } from "../typography";
import { TextButton } from "../buttons";

type SnackbarProps = {
	text: string | JSX.Element;
	icon: "ok" | "warning" | null;
};

const Snackbar = ({ text, icon }: SnackbarProps) => {
	const { branding } = useBrandingStore();
	const [distance, setDistance] = useState("0");
	const clearSnack = useSnackStore((state) => state.clear);
	const closeTime = useSnackStore((state) => state.closeTime);

	useEffect(() => {
		setDistance("100%");

		setTimeout(() => {
			clearSnack();
		}, closeTime * 1000);
	});

	if (branding === "optiswiss") {
		return (
			<div
				className={cn(
					"bg-primary-100 max-w-[700px] fixed top-8 left-1/2 z-50 rounded-[20px] -translate-x-1/2 w-full h-[72px] px-11 grid"
				)}
			>
				<div className={cn("flex gap-3 items-center [grid-area:1/1]")}>
					{icon === "ok" && <SnackOk />}
					{icon === "warning" && <SnackWarning />}
					<BodyText type="bold16" className="text-pure-white">
						{text}
					</BodyText>
				</div>
				<TextButton
					color="secondary"
					icon={Close}
					iconClasses={"size-[17px] text-pure-white"}
					onClick={clearSnack}
					className={cn(
						"!rounded-[10px] [grid-area:1/1] self-center place-self-end border border-pure-white p-[5px] h-fit"
					)}
				/>
			</div>
		);
	}

	return (
		<div className="bg-primary-dark-100 fixed inset-x-0 z-40 p-6">
			<div className="grid grid-cols-[auto_auto_auto] items-center">
				<div className="flex flex-row gap-3 md:col-start-2 md:justify-self-center">
					{icon === "ok" && <Done className="fill-pure-white" />}
					{icon === "warning" && (
						<Warning className="fill-pure-white" />
					)}
					<p className="text-pure-white text-xs leading-5 md:text-center md:text-base md:leading-6">
						{text}
					</p>
				</div>
				<Close
					className="fill-primary-dark-60 cursor-pointer justify-self-end"
					onClick={clearSnack}
				/>
			</div>
			<div
				style={{
					width: distance,
					transition: `width ${closeTime - 1}s linear 1s`,
				}}
				className="bg-secondary-40 absolute bottom-0 left-0 h-1"
			></div>
		</div>
	);
};

export default Snackbar;
