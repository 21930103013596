import * as React from 'react';
import { SVGProps } from 'react';
const SvgFullscreen = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			d="M20 11.1V4h-7.1l2.8 2.8-8.9 8.9L4 12.9V20h7.1l-2.9-2.9 8.9-8.9z"
			fill={`unset`}
		/>
	</svg>
);
export default SvgFullscreen;
