import { SVGProps } from "react";
const RouteHistory = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<mask
				id="mask0_1_3308"
				style={{ maskType: "luminance" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="18"
				height="19"
			>
				<path d="M0 0.500002H18V18.5H0V0.500002Z" fill="white" />
			</mask>
			<g mask="url(#mask0_1_3308)">
				<path
					d="M1.44313 1.20313V3.96876M1.44313 3.96876H4.20877M1.44313 3.96876C2.39952 2.31547 4.18704 1.20313 6.2344 1.20313H11.7656C14.8205 1.20313 17.2969 3.67953 17.2969 6.73436V12.2656C17.2969 15.3205 14.8205 17.7969 11.7656 17.7969H6.2344C3.17957 17.7969 0.703125 15.3205 0.703125 12.2656V9.5M11.7656 12.2656L9 9.5V5.35156"
					stroke="#DD052B"
					strokeWidth="1.5"
					strokeMiterlimit="10"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};

export default RouteHistory;
