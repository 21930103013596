import { SVGProps } from "react";

const OSPrint = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_2072)">
				<path
					d="M13.2018 1C13.8876 1 14.4327 1.54738 14.4327 2.20811V4.30817H3.69922V2.20811C3.69922 1.54738 4.24432 1 4.93003 1H13.2018Z"
					stroke="#DD052B"
				/>
				<path
					d="M13.0248 10.712H5.10848C3.3114 10.712 1.8399 12.1547 1.8399 13.9442V14.8068H1.73081C1.0451 14.8068 0.5 14.2595 0.5 13.5987V7.54014C0.5 6.87941 1.0451 6.33203 1.73081 6.33203H16.4025C17.0882 6.33203 17.6333 6.87941 17.6333 7.54014V13.5987C17.6333 14.2595 17.0882 14.8068 16.4025 14.8068H16.2934V13.9442C16.2934 12.1547 14.8219 10.712 13.0248 10.712Z"
					stroke="#DD052B"
				/>
				<path
					d="M13.0246 16.9999H5.10825C4.42254 16.9999 3.87744 16.4525 3.87744 15.7918V13.9444C3.87744 13.2837 4.42254 12.7363 5.10825 12.7363H13.0246C13.7103 12.7363 14.2554 13.2837 14.2554 13.9444V15.7918C14.2554 16.4525 13.7103 16.9999 13.0246 16.9999Z"
					stroke="#DD052B"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_2072">
					<rect
						width="18.1333"
						height="17"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSPrint;
