import J from "joi";

export type IRequestData = null;

export interface IResponseData {
	measurement_count: number;
	measurement_count_max: number;
}

export type IResponseError = null;

const responseDataShape = J.object({
	measurement_count: J.number(),
	measurement_count_max: J.number(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_measurement_count";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

// eslint-disable-next-line
export function request(_: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "GET",
	});
}
