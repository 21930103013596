import { useMemo, useState } from "react";
import {
	ImageObjects,
	IrisPositions,
	HandleObjects,
	IrisRadii,
} from "../../../util/drawing";
import * as drawing from "../../../util/drawing";
import { EyesCanvas } from ".";

export type EyesCanvasControllerProps = {
	maxWidth: number;
	maxHeight: number;

	imageObjects: ImageObjects;
	handleObjects: HandleObjects;

	irisRadii: IrisRadii;
	startingIrisPositions: IrisPositions;
	onIrisPositionsChange: (p: IrisPositions) => void;

	leftHand: boolean;
	alwaysUseLoupe: boolean;
};

const EyesCanvasController = ({
	maxWidth,
	maxHeight,
	irisRadii,
	startingIrisPositions,
	imageObjects,
	handleObjects,
	leftHand,
	alwaysUseLoupe,
	onIrisPositionsChange,
}: EyesCanvasControllerProps) => {
	const { topWidth, topHeight, bottomWidth, bottomHeight } = useMemo(
		() => ({
			topWidth: maxWidth,
			topHeight: maxHeight / 2,
			bottomWidth: maxWidth,
			bottomHeight: maxHeight / 2,
		}),
		[maxWidth, maxHeight]
	);

	const [irisPositions, setIrisPositions] = useState<IrisPositions>(
		startingIrisPositions
	);

	const topImgUtils = drawing.createImageUtils(
		topWidth,
		topHeight,
		{ x: startingIrisPositions.left.x, y: startingIrisPositions.left.y1 },
		{ x: startingIrisPositions.right.x, y: startingIrisPositions.right.y1 },
		irisRadii.left,
		irisRadii.right,
		0
	);

	const bottomImgUtils = drawing.createImageUtils(
		bottomWidth,
		bottomHeight,
		{ x: startingIrisPositions.left.x, y: startingIrisPositions.left.y2 },
		{ x: startingIrisPositions.right.x, y: startingIrisPositions.right.y2 },
		irisRadii.left,
		irisRadii.right,
		0
	);

	return (
		<div>
			<EyesCanvas
				width={topWidth}
				height={topHeight}
				imageUtils={topImgUtils}
				imageObject={imageObjects.topImage}
				leftHandleObject={handleObjects.handle1}
				rightHandleObject={handleObjects.handle1}
				irisPosition={{
					left: {
						x: irisPositions.left.x,
						y: irisPositions.left.y1,
					},
					right: {
						x: irisPositions.right.x,
						y: irisPositions.right.y1,
					},
				}}
				irisRadii={irisRadii}
				leftHand={leftHand}
				alwaysUseLoupe={alwaysUseLoupe}
				onIrisPositionChange={(p) => {
					const { left, right } =
						typeof p === "function"
							? p({
									left: {
										x: irisPositions.left.x,
										y: irisPositions.left.y1,
									},
									right: {
										x: irisPositions.right.x,
										y: irisPositions.right.y1,
									},
							  })
							: p;
					setIrisPositions({
						left: {
							...irisPositions.left,
							x: left.x,
							y1: left.y,
						},
						right: {
							...irisPositions.right,
							x: right.x,
							y1: right.y,
						},
					});
				}}
				onIrisPositionFinialized={({ left, right }) => {
					onIrisPositionsChange({
						left: {
							...irisPositions.left,
							x: left.x,
							y1: left.y,
						},
						right: {
							...irisPositions.right,
							x: right.x,
							y1: right.y,
						},
					});
				}}
			></EyesCanvas>
			<EyesCanvas
				width={bottomWidth}
				height={bottomHeight}
				imageUtils={bottomImgUtils}
				imageObject={imageObjects.bottomImage}
				leftHandleObject={handleObjects.handle2Left}
				rightHandleObject={handleObjects.handle2Right}
				irisPosition={{
					left: {
						x: irisPositions.left.x,
						y: irisPositions.left.y2,
					},
					right: {
						x: irisPositions.right.x,
						y: irisPositions.right.y2,
					},
				}}
				irisRadii={irisRadii}
				leftHand={leftHand}
				alwaysUseLoupe={alwaysUseLoupe}
				onIrisPositionChange={(p) => {
					const { left, right } =
						typeof p === "function"
							? p({
									left: {
										x: irisPositions.left.x,
										y: irisPositions.left.y2,
									},
									right: {
										x: irisPositions.right.x,
										y: irisPositions.right.y2,
									},
							  })
							: p;
					setIrisPositions({
						left: {
							...irisPositions.left,
							y2: left.y,
						},
						right: {
							...irisPositions.right,
							y2: right.y,
						},
					});
				}}
				onIrisPositionFinialized={({ left, right }) => {
					onIrisPositionsChange({
						left: {
							...irisPositions.left,
							y2: left.y,
						},
						right: {
							...irisPositions.right,
							y2: right.y,
						},
					});
				}}
			></EyesCanvas>
		</div>
	);
};

export default EyesCanvasController;
