import J from "joi";

export interface Measurement {
	camera_pair: number;
	finished: boolean;
	id: string;
	image_1: string | undefined;
	image_2: string | undefined;
	last_modified: string;
	measurement_data: string;
	order_id: string;
	preview_1: string;
	preview_2: string;
	time_stamp: string;
}

export interface IRequestData {
	client_time: number;
	version: number;
}

export interface IResponseData {
	measurement_count: number;
	measurement_count_max: number;
	measurements: Measurement[];
}

export type IResponseError = null;

const measurementShape = () =>
	J.object({
		camera_pair: J.number(),
		finished: J.boolean(),
		id: J.string(),
		image_1: J.string().optional(),
		image_2: J.string().optional(),
		last_modified: J.string(),
		measurement_data: J.string(),
		order_id: J.string().allow(""),
		preview_1: J.string(),
		preview_2: J.string(),
		time_stamp: J.string(),
	}).unknown();

const responseDataShape = J.object({
	measurement_count: J.number(),
	measurement_count_max: J.number(),
	measurements: J.array().items(measurementShape()),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "list_measurements";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
