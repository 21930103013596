import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import { Close } from "../../components/icons";
import { Checkbox, TextField } from "../../components/input";
import {
	ModalBase,
	ModalFullScreen,
	ModalPrivacyPolicy,
} from "../../components/modals";
import { InfoPanel } from "../../components/panels";
import { ButtonText, HeadingText } from "../../components/typography";
import { useBrandingStore, useSnackStore } from "../../store";
import {
	about,
	getInternetConnectionState,
	getSettings,
	loadGDPR,
	setGDPR,
	setRetentionPeriod,
} from "../../util/api/api-store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { cn } from "../../util/helpers";

type IFormValues = {
	branding: string;
	measurement_retention_days: number;
	system_nr: number;
	companyName: string;
	name: string;
	customerNumber: string;
	privacyPolicy: boolean;
};

const PrivacyPolicy = () => {
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [stuck, setStuck] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const openSnack = useSnackStore((state) => state.open);

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<IFormValues>({
		// mode: "onChange",
		defaultValues: {
			branding: "hoya",
			measurement_retention_days: 3,
			system_nr: 0,
			companyName: "",
			name: "",
			customerNumber: "",
			privacyPolicy: false,
		},
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	useEffect(() => {
		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				if (!resp.internet_connected) {
					openSnack(
						t("settingsPrivacyPolicy.connectToInternet"),
						"warning"
					);
				}
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
				openSnack(
					t("settingsPrivacyPolicy.connectToInternet"),
					"warning"
				);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const onSubmit: SubmitHandler<IFormValues> = (formData) => {
		setIsSubmited(true);
		const data = {
			companyName: formData.companyName,
			name: formData.name,
			customerNumber: formData.customerNumber,
			serialNumber: formData.system_nr,
			version: "1",
			branding: formData.branding,
			html: templateContent,
		};
		console.log(data);
		setGDPR.setRequest(data);
		setGDPR.setUrlparams({
			code: "Y8Hqd8dfIuHMqiBkmZTD5DiL8DfHp2qFOYCa9lYJVPtBcjK2MVN7mw==",
		});
		setGDPR.fetchData();

		setRetentionPeriod.setRequest({ measurement_retention_days: 183 });
		setRetentionPeriod
			.fetchData()
			.then(() => {
				setValue("measurement_retention_days", 183);
				setValue("privacyPolicy", true);
				getSettings.invalidate();
				getSettings.fetchData();
				setIsSubmited(true);
				openSnack(
					t("settingsPrivacyPolicy.acceptedSuccessfully"),
					"ok"
				);
			})
			.catch((error) => {
				console.error(error);
				setIsSubmited(false);
			});
	};

	const loadGDPRResponse = loadGDPR.useGetResult();
	const { templateContent } = loadGDPRResponse.unwrapOrDefault({
		templateContent: "",
	});

	useEffect(() => {
		about.setRequest(null);
		about.fetchData().then((resp) => {
			setValue("system_nr", resp.system_nr);
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			setValue(
				"privacyPolicy",
				resp.measurement_retention_days > 3 ? true : false
			);
			setValue("branding", resp.branding);
			setValue(
				"measurement_retention_days",
				resp.measurement_retention_days
			);
			loadGDPR.setRequest(null);
			loadGDPR.setUrlparams({
				branding: resp.branding === "optiswiss" ? "vs" : resp.branding,
				language: getCurrentLng().split("-")[0],
				code: "aMHEfEz2MzChHOcahdv8LhbY1XGRwTWNM4gw5LYkQ6ivtXPDN3FFOQ==",
			});
			loadGDPR.fetchData();
			if (resp.measurement_retention_days > 3) {
				setIsSubmited(true);
			}
		});
	}, []);

	return (
		<>
			<div>
				<HeadingText
					h={4}
					className={cn(
						branding === "optiswiss" && "!text-[26px] mt-2"
					)}
				>
					{t("settingsPrivacyPolicy.pageTitle")}
				</HeadingText>
				<SettingsFragment
					className={cn(branding === "optiswiss" && "py-8")}
				>
					{getValues("measurement_retention_days") > 3 ? (
						<InfoPanel
							type="done"
							title={t("settingsPrivacyPolicy.acceptDescription")}
							message={
								t("settingsPrivacyPolicy.cancelDescription") ||
								undefined
							}
							className={cn(
								"mb-8",
								branding === "optiswiss" && "py-8 pr-8"
							)}
						/>
					) : (
						<p className="mb-8">
							{t(
								"privacyPolicy.toBeAbleToKeepYourMeasurementPleaseAccept"
							)}{" "}
							<a
								onClick={() => setModalOpen(true)}
								className="text-primary-80 cursor-pointer underline"
							>
								{t("privacyPolicy.privacyPolicy")}
							</a>{" "}
							{t(
								"privacyPolicy.ifYouDontAcceptYourMeasurementWillBeDeleted"
							)}
						</p>
					)}
					<form
						onSubmit={handleSubmit(onSubmit)}
						id="form-privacy_policy"
						className="flex flex-col gap-8"
					>
						<Controller
							name="companyName"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterCompanyName") ||
									undefined,
								minLength: {
									value: 4,
									message: t("privacyPolicy.minLength4"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isSubmited || isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.companyName")}
									info={
										t("privacyPolicy.min4Char") || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="name"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterName") || undefined,
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isSubmited || isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.name")}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="customerNumber"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterCustomerNumber") ||
									undefined,
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isSubmited || isLoading || !hasInternet
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.customerNumber")}
									info={
										t(
											"privacyPolicy.pleaseAskSalesPartner"
										) || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<TextField
							value={getValues("system_nr").toString()}
							type="disabled"
							htmlFor="serial-number"
							label={t("gdpa.serialNumber")}
						/>
						<Controller
							name="privacyPolicy"
							control={control}
							rules={{
								required:
									t("privacyPolicy.acceptPrivacyPolicy") ||
									undefined,
							}}
							render={({ field: { value, onChange } }) => (
								<Checkbox
									label={
										<>
											{t("privacyPolicy.iAccept")}
											<a
												onClick={() =>
													setModalOpen(true)
												}
												className="text-primary-80 cursor-pointer underline"
											>
												{t(
													"privacyPolicy.privacyPolicy"
												)}
											</a>
											.
										</>
									}
									checked={value ? "checked" : "unchecked"}
									disabled={
										isSubmited || isLoading || !hasInternet
									}
									alert={
										errors.privacyPolicy &&
										errors.privacyPolicy.message
									}
									setChecked={(v) => onChange(v)}
								/>
							)}
						/>
					</form>
				</SettingsFragment>
				<div
					ref={buttonRef}
					className="before:bg-pure-white sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]"
				>
					<div
						className={clsx(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]"
						)}
					>
						<SolidButton
							color="primary"
							form="form-privacy_policy"
							submit={true}
							disabled={isSubmited || isLoading || !hasInternet}
						>
							{t("settings.save")}
						</SolidButton>
					</div>
				</div>
			</div>
			{branding === "optiswiss" ? (
				<ModalBase
					title={t("privacyPolicy.privacyPolicy") || undefined}
					open={modalOpen}
					close={() => setModalOpen(false)}
				>
					<div className={cn("max-h-[712px] overflow-y-auto")}>
						<ModalPrivacyPolicy />
					</div>
				</ModalBase>
			) : (
				<ModalFullScreen
					open={modalOpen}
					close={() => setModalOpen(false)}
				>
					<div className="before:bg-pure-white fixed top-0 z-10 w-full backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]">
						<div className="relative grid h-[72px] grid-cols-[1fr_auto_1fr] items-center px-6 shadow-[0px_1px_0px_#e6e8e9]">
							<ButtonText type="bold" className="col-start-2">
								{t("privacyPolicy.privacyPolicy")}
							</ButtonText>
							<Close
								className="fill-primary-dark-100 cursor-pointer select-none justify-self-end"
								onClick={() => setModalOpen(false)}
							/>
						</div>
					</div>
					<ModalPrivacyPolicy />
				</ModalFullScreen>
			)}
		</>
	);
};

export default PrivacyPolicy;
