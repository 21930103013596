import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Close } from "../../components/icons";
import { TextField, CustomSelect, Checkbox } from "../../components/input";
import { BodyText, HeadingText } from "../../components/typography";
import { getSettings, listMeasurements, home } from "../../util/api/api-store";
import { getAccessIdAsPromise, useBrandingStore } from "../../store";
import { ModalBase } from "../../components/modals";
import { cn } from "../../util/helpers";

const Start = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const { state } = useLocation();
	const [searchParams] = useSearchParams();
	const searchOrderId = searchParams.get("order_id")
		? searchParams.get("order_id")
		: "";
	const [isInputErrors, setInputErrors] = useState({
		order_id: false,
		frame_type: false,
	});
	let order_id = "";
	if (state?.order_id) {
		order_id = state?.order_id;
	} else if (searchOrderId) {
		order_id = searchOrderId;
	}
	const [formData, setFormData] = useState({
		order_id: order_id,
		eye_catcher: false,
		frame_type: state?.frame_type ? state.frame_type : "",
		order_id_required: false,
		pms: state?.pms || false,
	});
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);

	const { measurementCount, measurementCountMax } = listMeasurements
		.useGetResult()
		.map(({ measurement_count, measurement_count_max }) => ({
			measurementCount: measurement_count,
			measurementCountMax: measurement_count_max,
		}))
		.unwrapOrDefault({
			measurementCount: 0,
			measurementCountMax: 110,
		});

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			setFormData({
				...formData,
				// order_id: order_id ? order_id : "",
				eye_catcher: resp.eye_catcher,
				order_id_required: resp.order_id_required,
				// pms: order_id ? true : false,
			});
		});
		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
		});
		listMeasurements.setRequest(
			{
				client_time: Math.floor(Date.now() / 1000),
				version: 2,
			},
			true
		);
		listMeasurements.invalidate();
		listMeasurements.fetchData();
	}, []);

	const inputType = () => {
		if (isInputErrors.order_id) {
			return "alert";
		} else if (formData.pms) {
			return "disabled";
		} else {
			return "default";
		}
	};

	return (
		<>
			<div className="before:bg-pure-white fixed top-0 z-10 w-full backdrop-blur shadow-[0px_1px_0px_#e6e8e9] before:absolute before:inset-0 before:opacity-[0.88]">
				<div
					className={cn(
						"relative grid h-[72px] grid-cols-[1fr_auto_1fr] items-center px-6",
						branding === "optiswiss" && "container"
					)}
				>
					<BodyText type="bold16" className="col-start-2">
						{t("start.title")}
					</BodyText>
					<TextButton
						color="secondary"
						disabled={false}
						fullWidth={false}
						icon={Close}
						iconClasses={
							branding === "optiswiss" ? "size-[17px]" : undefined
						}
						onClick={() => {
							setModalLeaveOpen(true);
						}}
						className={cn(
							"justify-self-end",
							branding === "optiswiss" &&
								"!rounded-[10px] border border-quaternary-80 p-[5px] h-fit col-start-3"
						)}
					/>
				</div>
			</div>
			<div className="h-dvh flex items-center">
				<div className="container grid gap-6 py-[72px] md:grid-cols-8">
					<div
						className={cn(
							"flex flex-col gap-8 md:col-span-6 md:col-start-2",
							branding === "optiswiss" && "gap-5"
						)}
					>
						<HeadingText
							h={4}
							className={cn(branding === "optiswiss" && "mb-1")}
						>
							{t("start.orderId")}
						</HeadingText>
						<TextField
							type={inputType()}
							info={
								isInputErrors.order_id
									? t("start.orderIdCannotBeEmpty") ||
									  undefined
									: undefined
							}
							htmlFor="start-order_id"
							label={t("start.orderId")}
							value={formData.order_id}
							fieldOptions={{ autoFocus: true }}
							clearable
							setValue={(v) => {
								setFormData({
									...formData,
									order_id: v,
								});
								setInputErrors({
									...isInputErrors,
									order_id: false,
								});
							}}
							fullWidth
							className={cn(
								branding === "optiswiss" && "mt-4 mb-2.5"
							)}
						/>
						<CustomSelect
							alert={
								isInputErrors.frame_type
									? t("start.frameType") || undefined
									: undefined
							}
							label={t("results.frame type")}
							value={formData.frame_type}
							defaultLabel={
								t("start.frameTypeDefault") || undefined
							}
							setValue={(v) => {
								setFormData({
									...formData,
									frame_type: v,
								});
								setInputErrors({
									...isInputErrors,
									frame_type: false,
								});
							}}
							options={[
								{
									label: t("start.metal"),
									value: "metal",
								},
								{
									label: t("start.plastic"),
									value: "plastic",
								},
								{
									label: t("start.rimless"),
									value: "rimless",
								},
								{
									label: t("start.nylor"),
									value: "nylor",
								},
								{
									label: t("start.optyl"),
									value: "optyl",
								},
								{
									label: t("start.spx"),
									value: "spx",
								},
							]}
							fullWidth
							className={cn(
								branding === "optiswiss" &&
									"mt-4 mb-2.5 [&>p]:font-normal [&>p]:text-base"
							)}
						/>
						<Checkbox
							label={t("start.eyeCatcher")}
							info={t("start.eyeCatcherInfo") || undefined}
							checked={
								formData.eye_catcher ? "checked" : "unchecked"
							}
							setChecked={() =>
								setFormData({
									...formData,
									eye_catcher: !formData.eye_catcher,
								})
							}
							className={cn(
								branding === "optiswiss" &&
									"mt-3 mb-5 [&>input]:rounded-full [&>input]:border-2"
							)}
						/>
						<SolidButton
							disabled={measurementCount === measurementCountMax}
							className={cn(
								branding !== "optiswiss" ? "hidden" : "mt-4"
							)}
							onClick={() => {
								const errors = [
									!formData.frame_type,
									formData.order_id_required &&
										!formData.order_id,
								];
								if (errors.some(Boolean)) {
									setInputErrors({
										frame_type: errors[0],
										order_id: errors[1],
									});
									return;
								}
								navigate("/measurement/loading", {
									state: {
										...formData,
										pms: true,
										previousPage: state?.previousPage,
									},
								});
							}}
							color={"primary"}
						>
							{t("start.start")}
						</SolidButton>
					</div>
				</div>
			</div>
			<div
				hidden={branding === "optiswiss"}
				className="before:bg-pure-white fixed bottom-0 z-10 w-full shadow-[0px_-1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]"
			>
				<div className="container">
					<FloatingButton
						mx={false}
						num={1}
						label={t("start.start")}
						disabled={measurementCount === measurementCountMax}
						onClick={() => {
							const errors = [
								!formData.frame_type,
								formData.order_id_required &&
									!formData.order_id,
							];
							if (errors.some(Boolean)) {
								setInputErrors({
									frame_type: errors[0],
									order_id: errors[1],
								});
								return;
							}
							navigate("/measurement/loading", {
								state: {
									...formData,
									pms: true,
									previousPage: state?.previousPage,
								},
							});
						}}
					/>
				</div>
			</div>
			<ModalBase
				title={t("start.leave") || undefined}
				open={modalLeaveOpen}
				small
				close={() => {
					setModalLeaveOpen(false);
				}}
			>
				<div
					className={cn(
						branding === "optiswiss" ? "px-12 py-8" : "p-6"
					)}
				>
					<p
						className={cn(
							branding === "optiswiss"
								? "text-center mb-8"
								: "mb-6"
						)}
					>
						{t("start.leaveConfirm")}
					</p>
					<p className={cn(branding !== "optiswiss" && "text-right")}>
						<SolidButton
							color="primary"
							onClick={() => {
								navigate(
									state?.previousPage
										? `${state.previousPage}`
										: "/"
								);
							}}
							className={cn(branding === "optiswiss" && "w-full")}
						>
							{t("start.quit")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</>
	);
};

export default Start;
