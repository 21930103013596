import { SVGProps } from "react";

const OSFullScreen = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="17"
			viewBox="0 0 18 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<rect
				x="1.37832"
				y="1.1"
				width="11.2444"
				height="14.8"
				rx="2.4"
				stroke="#1D1D1D"
				strokeWidth="1.2"
			/>
			<path
				d="M14.2793 8.46289H10.3349"
				stroke="white"
				strokeWidth="6"
				strokeLinecap="round"
			/>
			<path
				d="M14.2607 5.5301C14.2607 5.20635 14.6328 5.0431 14.8476 5.27276C15.4476 5.90641 16.6633 7.19031 17.3681 7.93463C17.3707 7.9374 17.3733 7.94017 17.376 7.94293C17.6668 8.25007 17.6668 8.74537 17.376 9.05251L17.3681 9.06081C16.6581 9.81067 15.4371 11.1029 14.8476 11.7254C14.7481 11.8306 14.6013 11.861 14.473 11.8029C14.3524 11.7503 14.2607 11.6286 14.2607 11.4681L14.2607 5.5301Z"
				fill="#1D1D1D"
			/>
			<path
				d="M15.5938 8.49878H10.3345"
				stroke="#1D1D1D"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default OSFullScreen;
