import { create } from "zustand";
import { persist } from "zustand/middleware";
import { createAccessId } from "./util/api/api-store";
import { Option, newNone, newSome, newOption } from "./util/result";
import { BrandingType } from "./util/api/request-definitions/get-settings";

interface IMenuStore {
	opened: boolean;
	close: () => void;
	toggle: () => void;
}

interface IHeaderStore {
	logout: boolean;
	clickedLogout: () => void;
	loggedOut: () => void;
}

interface ISnackStore {
	clear: () => void;
	open: (text: string, icon: "ok" | "warning") => void;
	text: string;
	icon: "ok" | "warning" | null;
	closeTime: number;
}

interface IAccessIdStore {
	accessId: Option<string>;
	getAccessId: () => Promise<string>;
	unsetAccessId: () => void;
}

interface IBrandingStore {
	branding: BrandingType;
	setBranding: (branding: BrandingType) => void;
}

export const useMenuStore = create<IMenuStore>()((set) => ({
	opened: false,
	close: () => set(() => ({ opened: false })),
	toggle: () => set((state) => ({ opened: !state.opened })),
}));

export const useSnackStore = create<ISnackStore>()((set) => ({
	text: "",
	icon: "ok",
	closeTime: 5,
	open: (text, icon) => set(() => ({ text, icon })),
	clear: () => set(() => ({ text: "", icon: "ok" })),
}));

export const useHeaderStore = create<IHeaderStore>()((set) => ({
	logout: false,
	clickedLogout: () => set(() => ({ logout: true })),
	loggedOut: () => set(() => ({ logout: false })),
}));

export const useBrandingStore = create(
	persist<IBrandingStore>(
		(set) => ({
			branding: "hoya",
			setBranding: (branding) => {
				document.documentElement.dataset.theme = branding;
				set(() => ({ branding }));
			},
		}),
		{
			name: "branding",
		}
	)
);

export const useAccessIdStore = create<IAccessIdStore>()((set, get) => ({
	accessId: newNone(),
	getAccessId: async () => {
		const storageAccessId = newOption(sessionStorage.getItem("access_id"));
		const currentAccessId = get().accessId;
		if (currentAccessId.kind === "some") {
			return Promise.resolve(currentAccessId.data);
		} else if (storageAccessId.kind === "some") {
			console.log("Access id retrieved from sessionStorage");
			set(() => ({ accessId: storageAccessId }));
			return Promise.resolve(storageAccessId.data);
		} else {
			console.log("Access id is being retrieved from the server");
			createAccessId.setRequest(null);
			return createAccessId.fetchData().then((resp) => {
				set(() => ({ accessId: newSome(resp.access_id) }));
				sessionStorage.setItem("access_id", resp.access_id);
				return resp.access_id;
			});
		}
	},
	unsetAccessId: () => {
		set(() => ({ accessId: newNone() }));
		sessionStorage.removeItem("access_id");
		createAccessId.invalidate();
	},
}));

export const getAccessIdAsPromise = useAccessIdStore.getState().getAccessId;
export const getAccessIdAsOption = () => useAccessIdStore.getState().accessId;
export const unsetAccessId = useAccessIdStore.getState().unsetAccessId;
