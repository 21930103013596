import { SVGProps } from "react";

const Details = (props: SVGProps<SVGSVGElement> & { title?: string }) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M18.5 13.4545C18.5 15.9398 16.4853 17.9545 14 17.9545H6.54545C4.06017 17.9545 2.04545 15.9398 2.04545 13.4545V6C2.04545 3.51472 4.06017 1.5 6.54545 1.5H14C16.4853 1.5 18.5 3.51472 18.5 6V13.4545ZM7.45715 10.5682C6.9065 10.0176 6.02605 9.97988 5.43036 10.4815L2.7741 12.718C1.70381 13.6192 2.34108 15.3655 3.74023 15.3655H16.5441C17.7547 15.3655 18.4666 14.0054 17.7767 13.0107L14.2684 7.95161C13.6574 7.07049 12.346 7.09647 11.7703 8.00109L9.09463 12.2057L7.45715 10.5682Z"
				stroke="#DD052B"
			/>
			<path
				d="M16.5445 14.3655H2.59131V14.2576L4.06891 12.2874L6.04268 11.1032C6.23942 10.9851 6.49125 11.0161 6.65348 11.1784L8.82866 13.3536L9.26941 13.7943L9.60405 13.2684L12.6143 8.53797C12.8062 8.23643 13.2434 8.22777 13.447 8.52147L16.9554 13.5805C17.1854 13.9121 16.948 14.3655 16.5445 14.3655Z"
				fill="#DD052B"
				stroke="#DD052B"
			/>
		</svg>
	);
};

export default Details;
