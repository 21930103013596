import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Back, Close, OSArrowLeft } from "../../components/icons";
import { Checkbox, TextField } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import {
	ModalBase,
	ModalFullScreen,
	ModalPrivacyPolicy,
} from "../../components/modals";
import Footer from "../../components/panels/Footer";
import Info from "../../components/panels/Info";
import { BodyText, ButtonText, HeadingText } from "../../components/typography";
import { useBrandingStore, useSnackStore } from "../../store";
import {
	about,
	getInternetConnectionState,
	getSettings,
	loadGDPR,
	onboardingDone,
	setGDPR,
	setRetentionPeriod,
} from "../../util/api/api-store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { application, cn } from "../../util/helpers";
import BrandLogo from "../../components/logo/BrandLogo";
import Modal from "react-modal";

type IFormValues = {
	branding: string;
	measurement_retention_days: number;
	system_nr: number;
	companyName: string;
	name: string;
	customerNumber: string;
	privacyPolicy: boolean;
};

const PrivacyPolicy = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);
	const [finishErrorOccured, setFinishErrorOccured] =
		useState<boolean>(false);
	const openSnack = useSnackStore((state) => state.open);

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
	} = useForm<IFormValues>({
		// mode: "onChange",
		defaultValues: {
			branding: "hoya",
			measurement_retention_days: 3,
			system_nr: 0,
			companyName: "",
			name: "",
			customerNumber: "",
			privacyPolicy: false,
		},
	});

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const start = () => {
		onboardingDone.setRequest(null);
		onboardingDone.fetchData().then((resp) => {
			console.log(resp);
			getSettings.invalidate();
			getSettings.fetchData();
			navigate("/");
		});
	};

	useEffect(() => {
		getSettings.setRequest(null);
		const getSettingsPromise = getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
			setValue("privacyPolicy", false);
			setValue("branding", resp.branding);
			setValue(
				"measurement_retention_days",
				resp.measurement_retention_days,
			);
			loadGDPR.setRequest(null);
			loadGDPR.setUrlparams({
				branding: resp.branding === "optiswiss" ? "vs" : resp.branding,
				language: getCurrentLng().split("-")[0],
				code: "aMHEfEz2MzChHOcahdv8LhbY1XGRwTWNM4gw5LYkQ6ivtXPDN3FFOQ==",
			});
			loadGDPR.fetchData();
			setIsLoading(false);
		});

		getInternetConnectionState.setRequest(null);
		const checkInternet = getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
			})
			.finally(() => {
				setIsLoading(false);
			});

		Promise.all([getSettingsPromise, checkInternet]).finally(() =>
			setIsLoading(false),
		);

		about.setRequest(null);
		about.fetchData().then((resp) => {
			setValue("system_nr", resp.system_nr);
		});
	}, []);

	const loadGDPRResponse = loadGDPR.useGetResult();
	const { templateContent } = loadGDPRResponse.unwrapOrDefault({
		templateContent: "",
	});

	const onSubmit: SubmitHandler<IFormValues> = (formData) => {
		if (!formData.privacyPolicy) {
			openSnack(t("privacyPolicy.youNeedToAccept"), "warning");
			return;
		}

		setIsSubmited(true);
		const data = {
			companyName: formData.companyName,
			name: formData.name,
			customerNumber: formData.customerNumber,
			serialNumber: formData.system_nr,
			version: "1",
			branding: formData.branding,
			html: templateContent,
		};
		setGDPR.setRequest(data);
		setGDPR.setUrlparams({
			code: "Y8Hqd8dfIuHMqiBkmZTD5DiL8DfHp2qFOYCa9lYJVPtBcjK2MVN7mw==",
		});
		setGDPR.fetchData();

		setRetentionPeriod.setRequest({ measurement_retention_days: 183 });
		setRetentionPeriod
			.fetchData()
			.then(() => {
				getSettings.invalidate();
				getSettings.fetchData();
				setIsSubmited(false);
				openSnack(t("privacyPolicy.successfullyAccepted"), "ok");
				start();
			})
			.catch(() => setFinishErrorOccured(true));
	};

	if (branding === "optiswiss") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className="fixed inset-0 overflow-y-auto focus-visible:outline-0"
					overlayClassName="fixed inset-0 z-30 bg-transparent"
					bodyOpenClassName="overflow-hidden"
					style={{
						content: {
							transition: "none",
						},
						overlay: {
							transition: "none",
						},
					}}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo override={branding} size={"37px"} />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										{application[branding].title}
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-6 pb-12 sm:px-14",
								)}
							>
								<div className="place-self-start">
									<TextButton
										to="/onboarding/language"
										color="primary"
										icon={
											branding === "optiswiss"
												? OSArrowLeft
												: Back
										}
										iconClasses={
											branding === "optiswiss"
												? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
												: undefined
										}
										className={cn(
											"-m-3 mb-6",
											branding === "optiswiss"
												? "mt-6 h-fit"
												: "mt-3",
										)}
									>
										{t("shopName.previous")}
									</TextButton>
									<HeadingText
										h={4}
										className={cn(
											"mb-8 text-primary-dark-100",
										)}
									>
										{`${hasInternet ? "3/3" : "4/4"}. ${t(
											"privacyPolicy.pleaseAcceptOurPrivacyPolicy",
										)}`}
									</HeadingText>
									{hasInternet || (
										<div className="mb-8">
											<Info
												type={"alert"}
												title={t(
													"privacyPolicy.youNeedWifiToAccept",
												)}
												message={
													t(
														"privacyPolicy.wifiDescription",
													) || undefined
												}
											/>
										</div>
									)}
									{!getValues("privacyPolicy") && (
										<p
											className={cn(
												"mb-8 text-[16px] font-normal leading-[24px] text-primary-dark-80",
											)}
										>
											{t(
												"privacyPolicy.toBeAbleToKeepYourMeasurementPleaseAccept",
											)}
											<a
												onClick={() =>
													setModalOpen(true)
												}
												className={cn(
													"cursor-pointer text-primary-80 underline",
												)}
											>
												{t(
													"privacyPolicy.privacyPolicy",
												)}
											</a>
											{t(
												"privacyPolicy.ifYouDontAcceptYourMeasurementWillBeDeleted",
											)}
										</p>
									)}
									<form
										onSubmit={handleSubmit(onSubmit)}
										id="form-privacy_policy"
										className="mb-11 flex flex-col gap-1"
									>
										<Controller
											name="companyName"
											control={control}
											rules={{
												required:
													t(
														"privacyPolicy.enterCompanyName",
													) || undefined,
												minLength: {
													value: 4,
													message: t(
														"privacyPolicy.minLength4",
													),
												},
											}}
											render={({
												field: {
													name,
													value,
													onChange,
												},
											}) => (
												<TextField
													type={
														!hasInternet ||
														isLoading ||
														isSubmited
															? "disabled"
															: "default"
													}
													htmlFor={name}
													label={t(
														"gdpa.companyName",
													)}
													info={
														t(
															"privacyPolicy.min4Char",
														) || undefined
													}
													value={value}
													setValue={(v) =>
														onChange(v)
													}
													errors={errors}
												/>
											)}
										/>
										<Controller
											name="customerNumber"
											control={control}
											rules={{
												required:
													t(
														"privacyPolicy.enterCustomerNumber",
													) || undefined,
											}}
											render={({
												field: {
													name,
													value,
													onChange,
												},
											}) => (
												<TextField
													type={
														!hasInternet ||
														isLoading ||
														isSubmited
															? "disabled"
															: "default"
													}
													htmlFor={name}
													label={t(
														"gdpa.customerNumber",
													)}
													info={
														t(
															"privacyPolicy.pleaseAskSalesPartner",
														) || undefined
													}
													value={value}
													setValue={(v) =>
														onChange(v)
													}
													errors={errors}
												/>
											)}
										/>
										<Controller
											name="name"
											control={control}
											rules={{
												required:
													t(
														"privacyPolicy.enterName",
													) || undefined,
											}}
											render={({
												field: {
													name,
													value,
													onChange,
												},
											}) => (
												<TextField
													type={
														!hasInternet ||
														isLoading ||
														isSubmited
															? "disabled"
															: "default"
													}
													htmlFor={name}
													label={t("gdpa.name")}
													value={value}
													setValue={(v) =>
														onChange(v)
													}
													errors={errors}
													className="py-2.5"
												/>
											)}
										/>
										<TextField
											value={getValues(
												"system_nr",
											).toString()}
											type="disabled"
											htmlFor="serial-number"
											label={t("gdpa.serialNumber")}
											className="py-2.5"
										/>
										<Controller
											name="privacyPolicy"
											control={control}
											rules={{
												required:
													t(
														"privacyPolicy.acceptPrivacyPolicy",
													) || undefined,
											}}
											render={({
												field: { value, onChange },
											}) => (
												<Checkbox
													label={
														<>
															{t(
																"privacyPolicy.iAccept",
															)}{" "}
															<a
																onClick={() =>
																	setModalOpen(
																		true,
																	)
																}
																className={cn(
																	"ml-0.5 cursor-pointer text-primary-80 underline",
																)}
															>
																{t(
																	"privacyPolicy.privacyPolicy",
																)}
															</a>
														</>
													}
													checked={
														value
															? "checked"
															: "unchecked"
													}
													disabled={
														!hasInternet ||
														isLoading ||
														isSubmited
													}
													alert={
														errors.privacyPolicy &&
														errors.privacyPolicy
															.message
													}
													setChecked={(v) =>
														onChange(v)
													}
													className={cn(
														"pb-2.5 pt-5",
													)}
												/>
											)}
										/>
									</form>
								</div>
								<div
									className={cn(
										"flex w-full flex-col gap-5 bg-white",
									)}
								>
									<SolidButton
										color="primary"
										loading={isLoading}
										disabled={isSubmited}
										form="form-privacy_policy"
										fullWidth
										submit
										className="capitalize"
									>
										{t("application.btnNextCaption")}
									</SolidButton>

									<TextButton
										color="primary"
										onClick={() => start()}
										fullWidth
										className={cn(
											"!h-10 rounded-full border border-primary-100",
										)}
									>
										{t("privacyPolicy.skip")}
									</TextButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<ModalBase
					title={t("privacyPolicy.privacyPolicy") || undefined}
					open={modalOpen}
					close={() => setModalOpen(false)}
				>
					<div className={cn("max-h-[712px] overflow-y-auto")}>
						<ModalPrivacyPolicy />
					</div>
				</ModalBase>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div className="container pb-12">
					<TextButton
						to={
							hasInternet
								? "/onboarding/shop-name"
								: "/onboarding/wifi"
						}
						color="primary"
						icon={Back}
						className="-m-3 mb-6 mt-3"
					>
						{t("privacyPolicy.previous")}
					</TextButton>
					<HeadingText
						h={4}
						className={cn("mb-8 text-primary-dark-100")}
					>
						{`${hasInternet ? "3/3" : "4/4"}. ${t(
							"privacyPolicy.pleaseAcceptOurPrivacyPolicy",
						)}`}
					</HeadingText>
					{hasInternet || (
						<div className="mb-8">
							<Info
								type={"alert"}
								title={t("privacyPolicy.youNeedWifiToAccept")}
								message={
									t("privacyPolicy.wifiDescription") ||
									undefined
								}
							/>
						</div>
					)}
					{!getValues("privacyPolicy") && (
						<p
							className={cn(
								"mb-8 text-[16px] font-normal leading-[24px] text-primary-dark-80",
							)}
						>
							{t(
								"privacyPolicy.toBeAbleToKeepYourMeasurementPleaseAccept",
							)}{" "}
							<a
								onClick={() => setModalOpen(true)}
								className={cn(
									"cursor-pointer text-primary-80 underline",
								)}
							>
								{t("privacyPolicy.privacyPolicy")}
							</a>{" "}
							{t(
								"privacyPolicy.ifYouDontAcceptYourMeasurementWillBeDeleted",
							)}
						</p>
					)}
					<form
						onSubmit={handleSubmit(onSubmit)}
						id="form-privacy_policy"
						className={cn(
							"mb-6 grid grid-cols-1 gap-x-8 gap-y-6 md:grid-cols-2",
						)}
					>
						<Controller
							name="companyName"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterCompanyName") ||
									undefined,
								minLength: {
									value: 4,
									message: t("privacyPolicy.minLength4"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										!hasInternet || isLoading || isSubmited
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.companyName")}
									info={
										t("privacyPolicy.min4Char") || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="customerNumber"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterCustomerNumber") ||
									undefined,
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										!hasInternet || isLoading || isSubmited
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.customerNumber")}
									info={
										t(
											"privacyPolicy.pleaseAskSalesPartner",
										) || undefined
									}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<Controller
							name="name"
							control={control}
							rules={{
								required:
									t("privacyPolicy.enterName") || undefined,
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										!hasInternet || isLoading || isSubmited
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("gdpa.name")}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
						<TextField
							value={getValues("system_nr").toString()}
							type="disabled"
							htmlFor="serial-number"
							label={t("gdpa.serialNumber")}
						/>
						<Controller
							name="privacyPolicy"
							control={control}
							rules={{
								required:
									t("privacyPolicy.acceptPrivacyPolicy") ||
									undefined,
							}}
							render={({ field: { value, onChange } }) => (
								<Checkbox
									label={
										<>
											{t("privacyPolicy.iAccept")}{" "}
											<a
												onClick={() =>
													setModalOpen(true)
												}
												className={cn(
													"cursor-pointer text-primary-80 underline",
												)}
											>
												{t(
													"privacyPolicy.privacyPolicy",
												)}
											</a>
										</>
									}
									checked={value ? "checked" : "unchecked"}
									disabled={
										!hasInternet || isLoading || isSubmited
									}
									alert={
										errors.privacyPolicy &&
										errors.privacyPolicy.message
									}
									setChecked={(v) => onChange(v)}
								/>
							)}
						/>
					</form>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={2}
						buttons={{
							left: {
								label: t("privacyPolicy.skip"),
								onClick: () => start(),
							},
							right: {
								label: t("application.btnNextCaption"),
								form: "form-privacy_policy",
								disabled: isSubmited || isLoading,
							},
						}}
					/>
				</div>
			</Footer>
			<ModalBase
				title={t("privacyPolicy.error") || undefined}
				open={finishErrorOccured}
				close={() => setFinishErrorOccured(false)}
			>
				<BodyText className="px-6 py-5" type={"normal"}>
					{t("privacyPolicy.errorDescription")}
				</BodyText>
			</ModalBase>

			<ModalFullScreen open={modalOpen} close={() => setModalOpen(false)}>
				<div
					className={cn(
						"fixed top-0 z-10 w-full backdrop-blur before:absolute before:inset-0 before:bg-pure-white before:opacity-[0.88]",
					)}
				>
					<div
						className={cn(
							"relative grid h-[72px] grid-cols-[1fr_auto_1fr] items-center px-6 shadow-[0px_1px_0px_#e6e8e9]",
						)}
					>
						<ButtonText type="bold" className="col-start-2">
							{t("privacyPolicy.privacyPolicy")}
						</ButtonText>
						<Close
							className={cn(
								"cursor-pointer select-none justify-self-end fill-primary-dark-100",
							)}
							onClick={() => setModalOpen(false)}
						/>
					</div>
				</div>
				<ModalPrivacyPolicy />
			</ModalFullScreen>
		</>
	);
};

export default PrivacyPolicy;
