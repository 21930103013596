import * as React from 'react';
import { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Info_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Info_svg__a"
			height={20}
			maskUnits="userSpaceOnUse"
			width={20}
			x={2}
			y={2}
		>
			<path
				d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2zm0-8h-2V7h2z"
				fill="#fff"
			/>
		</mask>
		<g fill={`unset`} mask="url(#Info_svg__a)" clipPath="url(#Info_svg__b)">
			<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2zm0-8h-2V7h2z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgInfo;
