import * as React from 'react';
import { SVGProps } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Calendar_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Calendar_svg__a"
			height={22}
			maskUnits="userSpaceOnUse"
			width={20}
			x={2}
			y={1}
		>
			<path
				d="M22 3h-3V1h-2v2H7V1H5v2H2v20h20zm-2 18H4V8h16z"
				fill="#fff"
			/>
		</mask>
		<g
			fill={`unset`}
			mask="url(#Calendar_svg__a)"
			clipPath="url(#Calendar_svg__b)"
		>
			<path d="M22 3h-3V1h-2v2H7V1H5v2H2v20h20zm-2 18H4V8h16z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgCalendar;
