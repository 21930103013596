import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BodyText, ButtonText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type DraftItemShowAllProps = {
	draftCount: number;
};

const DraftItemShowAll = ({ draftCount }: DraftItemShowAllProps) => {
	const { branding } = useBrandingStore();

	const { t } = useTranslation();
	return (
		<Link
			to="/history"
			state={{ showDrafts: true }}
			className={cn(
				branding === "optiswiss"
					? "bg-quaternary-100 rounded-[10px] overflow-hidden items-center px-4 py-2.5 gap-2"
					: "bg-quaternary-20",
				"flex h-24 w-full shrink-0 grow-0 flex-col items-center justify-center"
			)}
		>
			<ButtonText
				type="bold"
				className={cn(branding === "optiswiss" && "text-primary-80")}
			>
				{t("draftItemShowAll.showAll")}
			</ButtonText>
			{draftCount > 5 && (
				<BodyText
					className={cn(
						branding === "optiswiss"
							? "text-primary-20"
							: "text-primary-dark-60"
					)}
					type={branding === "optiswiss" ? "normal" : "mini"}
				>
					{t("draftItemShowAll.draftsInHistory", {
						count: draftCount - 5,
					})}
				</BodyText>
			)}
		</Link>
	);
};

export default DraftItemShowAll;
