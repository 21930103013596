import { SVGProps } from "react";

const MeasurementReady = (
	props: SVGProps<SVGSVGElement> & { className?: string }
) => {
	return (
		<svg
			width="149"
			height="145"
			viewBox="0 0 149 145"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_d_1_1792)">
				<circle cx="74.5" cy="70.5" r="70" fill="#DD052B" />
				<g clipPath="url(#clip0_1_1792)">
					<path
						d="M84.3089 37H65.4684C62.5545 37 60.1924 39.3726 60.1924 42.2993V48.9339C60.1924 51.8606 62.5545 54.2332 65.4684 54.2332H84.3089C87.2227 54.2332 89.5848 51.8606 89.5848 48.9339V42.2993C89.5848 39.3726 87.2227 37 84.3089 37Z"
						fill="#DD052B"
					/>
					<path
						d="M44.776 45.6162H104.996C107.908 45.6162 110.272 47.9903 110.272 50.9155V92.4404C110.272 95.3656 107.908 97.7397 104.996 97.7397H44.776C41.8636 97.7397 39.5 95.3656 39.5 92.4404V50.9155C39.5 47.9903 41.8636 45.6162 44.776 45.6162Z"
						fill="white"
					/>
					<path
						d="M105.455 45.6377C105.465 45.7914 105.476 45.9398 105.476 46.0987V87.6237C105.476 90.5489 103.112 92.9229 100.2 92.9229H39.98C39.827 92.9229 39.674 92.9123 39.521 92.9017C39.7531 95.6149 42.0165 97.7399 44.7759 97.7399H104.996C107.908 97.7399 110.272 95.3659 110.272 92.4407V50.9158C110.272 48.1442 108.151 45.8709 105.455 45.6377Z"
						fill="white"
					/>
					<path
						d="M110.272 63.0771H39.5V75.8589H110.272V63.0771Z"
						fill="#DD052B"
					/>
					<path
						d="M110.272 63.0771H105.571V75.8589H110.272V63.0771Z"
						fill="#DD052B"
					/>
					<path
						d="M96.0004 71.6783C96.0004 83.3897 86.5458 92.8859 74.8859 92.8859C63.226 92.8859 53.7715 83.3897 53.7715 71.6783C53.7715 59.967 63.226 50.4707 74.8859 50.4707C86.5458 50.4707 96.0004 59.967 96.0004 71.6783Z"
						fill="#DD052B"
					/>
					<path
						d="M76.3709 89.6727C86.2673 88.8485 93.6247 80.1224 92.8041 70.1823C91.9835 60.2422 83.2957 52.8524 73.3992 53.6766C63.5028 54.5008 56.1454 63.227 56.966 73.167C57.7866 83.1071 66.4745 90.497 76.3709 89.6727Z"
						fill="white"
					/>
					<path
						d="M90.6507 71.6779C90.6507 80.4217 83.5914 87.5121 74.886 87.5121C66.1807 87.5121 59.1267 80.4217 59.1267 71.6779C59.1267 62.9341 66.186 55.8438 74.886 55.8438C83.5861 55.8438 90.6507 62.9341 90.6507 71.6779Z"
						fill="#DD052B"
					/>
					<path
						d="M77.7509 66.761C77.7509 71.0693 74.2741 74.5562 69.99 74.5562C65.7059 74.5562 62.229 71.064 62.229 66.761C62.229 62.458 65.7059 58.9658 69.99 58.9658C74.2741 58.9658 77.7509 62.458 77.7509 66.761Z"
						fill="white"
					/>
					<path
						d="M103.434 88.27C103.434 89.9393 102.084 91.2959 100.422 91.2959C98.7598 91.2959 97.4092 89.9393 97.4092 88.27C97.4092 86.6007 98.7598 85.2441 100.422 85.2441C102.084 85.2441 103.434 86.6007 103.434 88.27Z"
						fill="#DD052B"
					/>
					<path
						d="M103.592 51.9595C103.592 53.3267 102.484 54.4396 101.123 54.4396H92.0539C90.6874 54.4396 89.5847 53.3267 89.5847 51.9595C89.5847 50.587 90.6927 49.4795 92.0539 49.4795H101.123C102.484 49.4795 103.592 50.5923 103.592 51.9595Z"
						fill="#DD052B"
					/>
				</g>
			</g>
			<defs>
				<filter
					id="filter0_d_1_1792"
					x="0.5"
					y="0.5"
					width="148"
					height="148"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="4" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1_1792"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_dropShadow_1_1792"
						result="shape"
					/>
				</filter>
				<clipPath id="clip0_1_1792">
					<rect
						width="78"
						height="68"
						fill="white"
						transform="translate(39.5 37)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export default MeasurementReady;
