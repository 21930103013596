import { useTranslation } from "react-i18next";
import { TextButton } from "../../components/buttons";
import {
	Back,
	Faq,
	File,
	HelpFaq,
	HelpManual,
	HelpNews,
	HelpVideo,
	Info,
	OSArrowLeft,
	Video,
} from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import { BodyText, HeadingText } from "../../components/typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

const Help = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();

	const tNewslink = t("help.newslink");
	const tManuallink = t("help.manuallink");
	const tVideolink = t("help.videolink");
	const tFaqlink = t("help.faqlink");

	return (
		<FrameLayout>
			<div
				className={cn(
					"container",
					branding === "optiswiss" ? "py-8" : "py-16"
				)}
			>
				<TextButton
					to="/"
					color="primary"
					icon={branding === "optiswiss" ? OSArrowLeft : Back}
					iconClasses={
						branding === "optiswiss"
							? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
							: undefined
					}
					className={cn(
						"-m-3 mb-6",
						branding === "optiswiss" ? "mt-6 h-fit" : "mt-3"
					)}
				>
					{t("help.mainPage")}
				</TextButton>
				<HeadingText
					h={2}
					className={cn(
						branding === "optiswiss" ? "text-[26px] mb-8" : "mb-12"
					)}
				>
					{t("menu.help")}
				</HeadingText>
				<div
					className={cn(
						branding === "optiswiss"
							? "flex flex-col gap-8"
							: "grid gap-6 md:grid-cols-2"
					)}
				>
					<a href={tNewslink} target="_blank" rel="noreferrer">
						<div
							className={cn(
								"flex items-center",
								branding === "optiswiss"
									? "bg-quaternary-100 gap-10 px-6 py-2.5 rounded-[20px]"
									: "bg-primary-100 flex flex-col justify-start gap-3 p-6"
							)}
						>
							{branding === "optiswiss" ? (
								<HelpNews />
							) : (
								<Info
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
							<BodyText
								className={cn(
									branding === "optiswiss"
										? "text-pure-black !text-[22px]"
										: "text-pure-white"
								)}
								type="bold16"
							>
								{t("help.newstext")}
							</BodyText>
						</div>
					</a>
					<a href={tManuallink} target="_blank" rel="noreferrer">
						<div
							className={cn(
								"flex items-center",
								branding === "optiswiss"
									? "bg-quaternary-100 gap-10 px-6 py-2.5 rounded-[20px]"
									: "bg-primary-100 flex flex-col justify-start gap-3 p-6"
							)}
						>
							{branding === "optiswiss" ? (
								<HelpManual />
							) : (
								<File
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
							<BodyText
								className={cn(
									branding === "optiswiss"
										? "text-pure-black !text-[22px]"
										: "text-pure-white"
								)}
								type="bold16"
							>
								{t("help.manualtext")}
							</BodyText>
						</div>
					</a>
					<a href={tVideolink} target="_blank" rel="noreferrer">
						<div
							className={cn(
								"flex items-center",
								branding === "optiswiss"
									? "bg-quaternary-100 gap-10 px-6 py-2.5 rounded-[20px]"
									: "bg-primary-100 flex flex-col justify-start gap-3 p-6"
							)}
						>
							{branding === "optiswiss" ? (
								<HelpVideo />
							) : (
								<Video
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
							<BodyText
								className={cn(
									branding === "optiswiss"
										? "text-pure-black !text-[22px]"
										: "text-pure-white"
								)}
								type="bold16"
							>
								{t("help.videotext")}
							</BodyText>
						</div>
					</a>
					<a href={tFaqlink} target="_blank" rel="noreferrer">
						<div
							className={cn(
								"flex items-center",
								branding === "optiswiss"
									? "bg-quaternary-100 gap-10 px-6 py-2.5 rounded-[20px]"
									: "bg-primary-100 flex flex-col justify-start gap-3 p-6"
							)}
						>
							{branding === "optiswiss" ? (
								<HelpFaq />
							) : (
								<Faq
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
							<BodyText
								className={cn(
									branding === "optiswiss"
										? "text-pure-black !text-[22px]"
										: "text-pure-white"
								)}
								type="bold16"
							>
								{t("help.faqtext")}
							</BodyText>
						</div>
					</a>
				</div>
			</div>
		</FrameLayout>
	);
};

export default Help;
