import J from "joi";

export interface IRequestData {
	access_id: string;
	measurement: string;
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = {
	success: false;
	error: string;
};

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	success: J.boolean().valid(false),
	error: J.string(),
})
	.required()
	.unknown();

const uri =
	import.meta.env.VITE_MASTER_SERVER_HOST + "editor_change_notification";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
