import clsx from "clsx";
import { Add, Remove } from "../icons";
import { useState, useEffect } from "react";
import { cn } from "../../util/helpers";
import { useBrandingStore } from "../../store";

import RcSlider from "rc-slider";
import "rc-slider/assets/index.css";

export type SliderProps = {
	value: number;
	setValue: (value: number) => void;
	step: number; // amount got from backend * 100
	min: number; // amount got from backend * 100
	max: number; // amount got from backend * 100
	disabled?: boolean;
	fullWidth?: boolean;
};

const Slider = ({
	value,
	setValue,
	disabled,
	fullWidth,
	max,
	min,
	step,
}: SliderProps) => {
	const { branding } = useBrandingStore();
	const warmupTime = 300;
	const changeTime = 70;

	// @todo eslint
	const [maxClickStart, setMaxClickStart] = useState(0);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [maxClickCallback, setMaxClickCallback] = useState<any>(0);

	const [minClickStart, setMinClickStart] = useState(0);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [minClickCallback, setMinClickCallback] = useState<any>(0);

	const clickCallbackHandler =
		(
			clickStart: number,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			callback: any,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			setCallback: (callback: any) => void,
			stepFunc: (value: number) => number
		) =>
		() => {
			if (clickStart !== 0 && callback === 0) {
				const timeoutTime =
					Date.now() - clickStart < warmupTime
						? warmupTime
						: changeTime;
				setCallback(
					setTimeout(() => {
						setCallback(0);
						const newValue = stepFunc(value);
						if (newValue >= min && newValue <= max && !disabled) {
							setValue(newValue);
						}
					}, timeoutTime)
				);
			}
		};
	useEffect(
		clickCallbackHandler(
			maxClickStart,
			maxClickCallback,
			setMaxClickCallback,
			(n) => n + step
		)
	);
	useEffect(
		clickCallbackHandler(
			minClickStart,
			minClickCallback,
			setMinClickCallback,
			(n) => n - step
		)
	);

	return (
		<div
			className={clsx(
				"flex flex-row items-center gap-3",
				fullWidth ? "w-full" : "max-w-md"
			)}
		>
			<Remove
				className={clsx(
					!disabled
						? "fill-primary-dark-100 cursor-pointer"
						: "fill-secondary-40"
				)}
				onMouseDown={() => {
					setMinClickStart(Date.now());
				}}
				onMouseUp={() => {
					clearTimeout(minClickCallback);
					setMinClickCallback(0);
					setMinClickStart(0);
					const newValue = value - step;
					if (
						Date.now() - minClickStart < warmupTime &&
						newValue >= min &&
						!disabled
					) {
						setValue(newValue);
					}
				}}
			/>

			{branding === "optiswiss" ? (
				<div className={cn("w-full relative")}>
					<RcSlider
						value={value}
						min={min}
						max={max}
						step={step}
						onChange={(v) =>
							setValue(typeof v === "number" ? v : v[0])
						}
						className={cn(
							"relative before:absolute before:left-0 before:z-10 before:-translate-x-1/2 after:translate-x-1/2 after:z-10 before:top-0 before:-translate-y-[calc(25%-2px)] before:rounded-full before:h-5 before:w-1 before:bg-quaternary-80 after:absolute after:right-0 after:top-0 after:-translate-y-[calc(25%-2px)] after:rounded-full after:h-5 after:w-1 after:bg-quaternary-80"
						)}
						styles={{
							handle: {
								borderRadius: "50%",
								width: "22px",
								height: "22px",
								transform: "translate(-50%,-4px)",
								zIndex: 11,
								backgroundColor: "var(--primary--100)",
								borderColor: "#fff",
								opacity: 1,
								boxShadow: "none",
							},
							track: {
								backgroundColor: "var(--primary--100)",
								height: "4px",
							},
							rail: {
								backgroundColor: "#BBBEC3",
								height: "4px",
							},
						}}
					/>
					<span
						className={cn(
							"absolute px-2 text-[10px] py-0.5 rounded-full bg-quaternary-100 top-0"
						)}
						style={{
							transform: `translate(-50%, -30px)`,
							left: `${((value - min) * 100) / (max - min)}%`,
						}}
					>
						{value}
					</span>
				</div>
			) : (
				// <input
				// 	style={{
				// 		backgroundSize: `${
				// 			((value - min) * 100) / (max - min)
				// 		}% 100%`,
				// 	}}
				// 	className={cn("accent-primary-100 grow rounded-md")}
				// 	type="range"
				// 	value={value}
				// 	disabled={disabled}
				// 	min={min}
				// 	max={max}
				// 	step={step}
				// 	onChange={(e) => setValue(parseFloat(e.target.value))}
				// />
				<RcSlider
					value={value}
					min={min}
					max={max}
					step={step}
					onChange={(v) => setValue(typeof v === "number" ? v : v[0])}
					className="!p-0 !h-3"
					styles={{
						handle: {
							borderRadius: "50%",
							width: "20px",
							height: "20px",
							transform: "translate(-50%,0.5px)",
							zIndex: 11,
							backgroundColor: "var(--primary--100)",
							border: "none",
							opacity: 1,
							boxShadow: "none",
						},
						track: {
							backgroundColor: "var(--primary--60)",
							height: "12px",
							borderRadius: "6px",
						},
						rail: {
							backgroundColor: "#F3F2F4",
							height: "12px",
							borderRadius: "6px",
						},
					}}
				/>
			)}

			<Add
				className={clsx(
					!disabled
						? "fill-primary-dark-100 cursor-pointer"
						: "fill-secondary-40"
				)}
				onMouseDown={() => {
					setMaxClickStart(Date.now());
				}}
				onMouseUp={() => {
					clearTimeout(maxClickCallback);
					setMaxClickCallback(0);
					setMaxClickStart(0);
					const newValue = value + step;
					if (
						Date.now() - maxClickStart < warmupTime &&
						newValue <= max &&
						!disabled
					) {
						setValue(newValue);
					}
				}}
			/>
		</div>
	);
};

export default Slider;
