export default function createRipple(
	event: React.MouseEvent<Element, MouseEvent>,
	color: "white" | "gray" = "white"
) {
	const button = event.currentTarget as HTMLElement;

	const circle = document.createElement("span");
	const diameter = Math.max(button.clientWidth, button.clientHeight);
	const radius = diameter / 2;

	const buttonRect = button.getBoundingClientRect();

	circle.style.width = circle.style.height = `${diameter}px`;
	circle.style.left = `${event.clientX - buttonRect.left - radius}px`;
	circle.style.top = `${event.clientY - buttonRect.top - radius}px`;
	circle.style.backgroundColor =
		color === "white" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.2)";
	circle.classList.add("ripple");

	const ripple = button.getElementsByClassName("ripple")[0];

	if (ripple) {
		ripple.remove();
	}

	button.appendChild(circle);
}
