import * as React from 'react';
import { SVGProps } from 'react';
const SvgZoomIn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="ZoomIn_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="ZoomIn_svg__a"
			height={18}
			maskUnits="userSpaceOnUse"
			width={18}
			x={3}
			y={3}
		>
			<path
				d="M15.5 14h-.79l-.28-.27A6.47 6.47 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z"
				fill="#fff"
			/>
		</mask>
		<g
			fill={`unset`}
			mask="url(#ZoomIn_svg__a)"
			clipPath="url(#ZoomIn_svg__b)"
		>
			<path d="M15.5 14h-.79l-.28-.27A6.47 6.47 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm.5-7H9v2H7v1h2v2h1v-2h2V9h-2z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgZoomIn;
