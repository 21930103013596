import J from "joi";

interface MeasurementPosition {
	changed: boolean;
	position: number[];
}

interface MeasurementSide {
	bottom: MeasurementPosition;
	bottom_nasal: MeasurementPosition;
	bottom_temporal: MeasurementPosition;
	iris: MeasurementPosition;
	iris_radius_pixels: number;
	nasal: MeasurementPosition;
	temporal: MeasurementPosition;
	top: MeasurementPosition;
	top_nasal: MeasurementPosition;
	top_temporal: MeasurementPosition;
}

export interface IRequestData {
	measurement: string;
	reevaluate: boolean; // What is this?
	access_id: string;
}

export interface IResponseData {
	editable: {
		_L: MeasurementSide;
		_R: MeasurementSide;
	};
	images: {
		_CO: string;
		_CU: string;
	};
	measurement: string;
	order_id: string;
	page: number;
	time_stamp: string;
}

export type IResponseError = {
	error: "access conflict";
	success: false;
};

const measurementPosition = () =>
	J.object({
		changed: J.boolean(),
		position: J.array().items(J.number()),
	}).unknown();

const measurementSide = () =>
	J.object({
		bottom: measurementPosition(),
		bottom_nasal: measurementPosition(),
		bottom_temporal: measurementPosition(),
		iris: measurementPosition(),
		iris_radius_pixels: J.number(),
		nasal: measurementPosition(),
		temporal: measurementPosition(),
		top: measurementPosition(),
		top_nasal: measurementPosition(),
		top_temporal: measurementPosition(),
	}).unknown();

const responseDataShape = J.object({
	editable: J.object({
		_L: measurementSide(),
		_R: measurementSide(),
	}),
	images: J.object({
		_CO: J.string(),
		_CU: J.string(),
	}),
	measurement: J.string(),
	order_id: J.string().allow(""),
	page: J.number(),
	time_stamp: J.string(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	error: J.string(),
	success: J.boolean(),
}).required();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "process_measurement";

// eslint-disable-next-line
export function isValidResponse(resp: unknown): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
