import { SVGProps } from "react";

const OSSearch = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<circle
				cx="8.8254"
				cy="8.82491"
				r="6.74142"
				stroke="#BBBEC3"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.5146 13.8638L16.1577 16.4999"
				stroke="#BBBEC3"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default OSSearch;
