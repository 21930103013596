import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TextButton } from "../../components/buttons";
import {
	ArrowLeft,
	ArrowRight,
	Back,
	Close,
	OSArrowLeft,
	OSZoomIn,
	ZoomIn,
} from "../../components/icons";
import { FrameLayout } from "../../components/layouts";
import { ModalFullScreen } from "../../components/modals";
import { ButtonText, HeadingText } from "../../components/typography";
import TextBody from "../../components/typography/TextBody";
import { format } from "date-fns";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import Footer from "../../components/panels/Footer";

interface userInfo {
	name: string;
	imageSrcs: Array<string>;
	date: Date;
	lastModified: Date;
	cameraPair: number;
}

const DEFAULT_USERINFO: userInfo = {
	name: "",
	imageSrcs: [],
	date: new Date(0),
	lastModified: new Date(0),
	cameraPair: 0,
};

const Details = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [userInfo, setUserInfo] = useState<userInfo>(DEFAULT_USERINFO);
	const [currentlyViewedImageIndex, setCurrentlyViewedImageIndex] =
		useState<number>(-1);
	const data = useLocation();
	// const [_, setForcedUpdate] = useState<object>();

	// const forecUpdate = () => {
	// 	setForcedUpdate({});
	// }

	const decreaseCurrentlyViewedImageIndex = () => {
		setCurrentlyViewedImageIndex((state) => {
			if (state > 0) {
				return state - 1;
			}
			return state;
		});
	};

	const increaseCurrentlyViewedImageIndex = () => {
		setCurrentlyViewedImageIndex((state) => {
			if (state + 1 < userInfo.imageSrcs.length) {
				return state + 1;
			}
			return state;
		});
	};

	const displayImage = (index: number) => {
		setModalOpen(true);
		setCurrentlyViewedImageIndex(index);
		// forecUpdate();
	};

	useEffect(() => {
		// Get userInfo
		setUserInfo({
			name: data.state?.measurementData.name,
			imageSrcs: [
				data.state?.measurementData.image1,
				data.state?.measurementData.image2,
			],
			date: new Date(data.state?.measurementData.date),
			lastModified: new Date(data.state?.measurementData.lastModified),
			cameraPair: data.state?.measurementData.cameraPair,
		});
	}, []);

	return (
		<>
			<FrameLayout hiddenFooter={true}>
				<div className="container pb-[72px]">
					<TextButton
						to="/history"
						color="primary"
						icon={branding === "optiswiss" ? OSArrowLeft : Back}
						iconClasses={
							branding === "optiswiss"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
								: undefined
						}
						className={cn(
							"-m-3 mb-6",
							branding === "optiswiss" ? "mt-6 h-fit" : "mt-3"
						)}
					>
						{t("details.pageTitle")}
					</TextButton>
					{branding !== "optiswiss" ? (
						<>
							<HeadingText h={1} className="mb-8">
								{userInfo.name}
							</HeadingText>
							<div className="mb-8 flex gap-2">
								<HeadingText h={4}>
									<>
										{t("details.photos")}{" "}
										<span className="font-normal">
											{t("details.cameraPair", {
												pair: userInfo.cameraPair + 1,
											})}
										</span>
									</>
								</HeadingText>
							</div>
						</>
					) : (
						<div
							className={cn(
								"flex md:items-center md:justify-between md:flex-row flex-col mb-8 md:mb-12"
							)}
						>
							<HeadingText h={1} className="!text-[26px]">
								{userInfo.name}
							</HeadingText>
							<HeadingText
								h={4}
								className="!text-base text-primary-dark-60 !font-normal"
							>
								<>
									{t("details.photos")}{" "}
									{t("details.cameraPair", {
										pair: userInfo.cameraPair + 1,
									})}
								</>
							</HeadingText>
						</div>
					)}
					<div
						className={cn(
							"grid grid-cols-2",
							branding === "optiswiss" ? "gap-10 mb-12" : "gap-6"
						)}
					>
						{userInfo.imageSrcs.map((src, index) => (
							<div className="relative" key={index}>
								<img
									className={cn(
										"min-w-full",
										branding === "optiswiss" &&
											"rounded-[10px] border-quaternary-80"
									)}
									src={src}
									key={index}
									loading="lazy"
								/>
								<div
									className={cn(
										"absolute cursor-pointer",
										branding === "optiswiss"
											? "top-5 right-5 bg-[#DD052B1A] rounded-[10px] border border-quaternary-80 grid place-items-center size-[30px]"
											: "bottom-6 right-6 bg-white/[.88] p-3"
									)}
									onClick={() => displayImage(index)}
								>
									{branding === "optiswiss" ? (
										<OSZoomIn />
									) : (
										<ZoomIn />
									)}
								</div>
							</div>
						))}
					</div>
					{branding !== "optiswiss" && <hr className="my-12" />}
					<HeadingText
						h={4}
						className={cn(
							branding === "optiswiss"
								? "mb-4"
								: "text-primary-dark-100 mb-8"
						)}
					>
						{t("details.measurement")}
					</HeadingText>
					<div
						className={cn(
							"grid grid-cols-[auto,auto] gap-x-16 md:w-1/2",
							branding !== "optiswiss" && "gap-y-4"
						)}
					>
						<TextBody
							type="normal"
							className={cn(
								branding === "optiswiss" &&
									"text-primary-dark-60"
							)}
						>
							{t("details.recorded")}
						</TextBody>
						<TextBody type="bold16" className="text-right">
							{format(userInfo.date, "Pp") || ""}
						</TextBody>
						<TextBody
							type="normal"
							className={cn(
								branding === "optiswiss" &&
									"text-primary-dark-60"
							)}
						>
							{t("details.lastModified")}
						</TextBody>
						<TextBody type="bold16" className="text-right">
							{format(userInfo.lastModified, "Pp") || ""}
						</TextBody>
					</div>
				</div>
			</FrameLayout>
			<ModalFullScreen open={modalOpen} close={() => setModalOpen(false)}>
				<div className="grid grid-rows-[auto_1fr]">
					<div className="bg-pure-white grid h-[72px] grid-cols-[auto_auto] items-center px-6 shadow-[0px_1px_0px_#e6e8e9]">
						<ButtonText
							type="bold"
							className="translate-x-1/2 text-center"
						>
							{`${currentlyViewedImageIndex + 1}/${
								userInfo.imageSrcs.length
							}`}
						</ButtonText>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							iconClasses={
								branding === "optiswiss"
									? "size-[17px]"
									: undefined
							}
							onClick={() => setModalOpen(false)}
							className={cn(
								"justify-self-end",
								branding === "optiswiss" &&
									"!rounded-[10px] border border-quaternary-80 p-[5px] h-fit"
							)}
						/>
					</div>
					<div className="relative">
						<div
							className={cn(
								"absolute left-12 top-1/2 cursor-pointer ",
								branding === "optiswiss"
									? "bg-[#DD052B1A] border border-quaternary-80 size-16 -translate-y-1/2 grid place-items-center rounded-[10px]"
									: "rounded-full bg-black p-3",
								currentlyViewedImageIndex <= 0 && "hidden"
							)}
							onClick={decreaseCurrentlyViewedImageIndex}
						>
							{branding === "optiswiss" ? (
								<OSArrowLeft width={28} height={28} />
							) : (
								<ArrowLeft
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
						</div>
						<div className="flex">
							<img
								src={
									userInfo.imageSrcs[
										currentlyViewedImageIndex
									]
								}
								className={cn(
									branding === "optiswiss"
										? "h-[calc(100vh-72px-72px)]"
										: "h-[calc(100vh-72px)]",
									"w-full object-contain object-center"
								)}
								loading="lazy"
							/>
						</div>
						<div
							className={cn(
								"absolute right-12 top-1/2 cursor-pointer ",
								branding === "optiswiss"
									? "bg-[#DD052B1A] border border-quaternary-80 size-16 -translate-y-1/2 grid place-items-center rounded-[10px]"
									: "rounded-full bg-black p-3",
								currentlyViewedImageIndex + 1 >=
									userInfo.imageSrcs.length && "hidden"
							)}
							onClick={increaseCurrentlyViewedImageIndex}
						>
							{branding === "optiswiss" ? (
								<OSArrowLeft
									width={28}
									height={28}
									className="-scale-x-100"
								/>
							) : (
								<ArrowRight
									width={48}
									height={48}
									className="fill-pure-white"
								/>
							)}
						</div>
					</div>
				</div>
				{branding === "optiswiss" && <Footer />}
			</ModalFullScreen>
		</>
	);
};

export default Details;
