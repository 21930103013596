import { SVGProps } from "react";

const CaretRight = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="9"
			height="14"
			viewBox="0 0 9 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M0.657681 13.4999C0.463531 13.4999 0.272672 13.4175 0.141045 13.2591C-0.0860119 12.9835 -0.0366517 12.5876 0.246346 12.369L7.27852 6.99986L0.249637 1.63071C-0.0366517 1.41215 -0.0827212 1.01302 0.141045 0.740605C0.368101 0.46502 0.782727 0.420673 1.06572 0.636073L8.74945 6.50254C8.90741 6.62292 8.99955 6.80664 8.99955 6.99986C8.99955 7.19309 8.90741 7.37681 8.74945 7.49718L1.06572 13.3637C0.94397 13.4555 0.79918 13.4999 0.657681 13.4999Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default CaretRight;
