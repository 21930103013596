interface Props {
	xLeft: number;
	xRight: number;
	yLeft: number;
	yRight: number;
	lensWidth: number;
	frameHeight: number;
	distance: number;
}

const Sizing = ({
	xLeft,
	xRight,
	yLeft,
	yRight,
	lensWidth,
	frameHeight,
	distance,
}: Props) => {
	const padding = 30;
	const middleX = padding + lensWidth + distance / 2;
	const width = lensWidth * 2 + distance + padding * 2;
	const height = frameHeight + padding * 2 + 8;

	return (
		<svg
			width={`${width}mm`}
			height={`${height}mm`}
			xmlns="http://www.w3.org/2000/svg"
		>
			{/* left L */}
			<rect
				x={`${padding + lensWidth}mm`}
				y={`${padding}mm`}
				width="1px"
				height={`${frameHeight}mm`}
			/>
			<rect
				x={`${padding}mm`}
				y={`${frameHeight + padding}mm`}
				width={`${lensWidth}mm`}
				height="1px"
			/>

			{/* middle line */}
			<line
				x1={`${middleX}mm`}
				y1={`${padding / 1.4}mm`}
				x2={`${middleX}mm`}
				y2={`${frameHeight + padding}mm`}
				strokeDasharray="8"
				stroke="black"
			/>

			{/* right L */}
			<rect
				x={`${padding + lensWidth + distance}mm`}
				y={`${padding}mm`}
				width="1px"
				height={`${frameHeight}mm`}
			/>
			<rect
				x={`${padding + lensWidth + distance}mm`}
				y={`${frameHeight + padding}mm`}
				width={`${lensWidth}mm`}
				height="1px"
			/>

			{/* pluses */}
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${padding + frameHeight - yLeft - 3}mm`}
				width="1px"
				height="6mm"
			/>
			<rect
				x={`${middleX - xLeft - 3}mm`}
				y={`${padding + frameHeight - yLeft}mm`}
				width="6mm"
				height="1px"
			/>
			<rect
				x={`${middleX + xRight}mm`}
				y={`${padding + frameHeight - yRight - 3}mm`}
				width="1px"
				height="6mm"
			/>
			<rect
				x={`${middleX + xRight - 3}mm`}
				y={`${padding + frameHeight - yRight}mm`}
				width="6mm"
				height="1px"
			/>

			{/* left circles */}
			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${padding + frameHeight - yLeft}mm`}
				r="27.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${padding + frameHeight - yLeft - 28.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${padding + frameHeight - yLeft - 26.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			>
				55
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${padding + frameHeight - yLeft}mm`}
				r="30mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${padding + frameHeight - yLeft - 31}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${padding + frameHeight - yLeft - 29}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			>
				60
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${padding + frameHeight - yLeft}mm`}
				r="32.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${padding + frameHeight - yLeft - 33.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${padding + frameHeight - yLeft - 31.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			>
				65
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${padding + frameHeight - yLeft}mm`}
				r="35mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${padding + frameHeight - yLeft - 36}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${padding + frameHeight - yLeft - 34}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			>
				70
			</text>

			<circle
				cx={`${middleX - xLeft}mm`}
				cy={`${padding + frameHeight - yLeft}mm`}
				r="37.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX - xLeft - 2.5}mm`}
				y={`${padding + frameHeight - yLeft - 38.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			/>
			<text
				x={`${middleX - xLeft - 2}mm`}
				y={`${padding + frameHeight - yLeft - 36.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(-30, ${(middleX - xLeft) * 3.78}, ${
					(padding + frameHeight - yLeft) * 3.78
				})`}
			>
				75
			</text>

			{/* right circles */}
			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${padding + frameHeight - yRight}mm`}
				r="27.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${padding + frameHeight - yRight - 28.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${padding + frameHeight - yRight - 26.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			>
				55
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${padding + frameHeight - yRight}mm`}
				r="30mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${padding + frameHeight - yRight - 31}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${padding + frameHeight - yRight - 29}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			>
				60
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${padding + frameHeight - yRight}mm`}
				r="32.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${padding + frameHeight - yRight - 33.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${padding + frameHeight - yRight - 31.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			>
				65
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${padding + frameHeight - yRight}mm`}
				r="35mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${padding + frameHeight - yRight - 36}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${padding + frameHeight - yRight - 34}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			>
				70
			</text>

			<circle
				cx={`${middleX + xRight}mm`}
				cy={`${padding + frameHeight - yRight}mm`}
				r="37.5mm"
				stroke="black"
				opacity={0.5}
				fill="none"
			/>
			<rect
				x={`${middleX + xRight - 2.5}mm`}
				y={`${padding + frameHeight - yRight - 38.5}mm`}
				width="4.15mm"
				height="2mm"
				fill="white"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			/>
			<text
				x={`${middleX + xRight - 2}mm`}
				y={`${padding + frameHeight - yRight - 36.5}mm`}
				fill="black"
				fontSize="11"
				transform={`rotate(30, ${(middleX + xRight) * 3.78}, ${
					(padding + frameHeight - yRight) * 3.78
				})`}
			>
				75
			</text>

			{/* lines above pluses */}
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${padding / 1.4}mm`}
				width="1px"
				height={`${padding / 8 + frameHeight - yLeft}mm`}
			/>
			<rect
				x={`${middleX - xLeft}mm`}
				y={`${padding / 1.15}mm`}
				height="1px"
				width={`${xLeft}mm`}
			/>
			<text
				x={`${middleX - xLeft / 2 - 1}mm`}
				y={`${padding / 1.15 - 1}mm`}
				fill="black"
			>
				x
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${middleX - xLeft},${padding / 1.15 + 0.15} ${
						middleX - xLeft + 2
					},${padding / 1.15 - 1.125 + 0.15} ${middleX - xLeft + 2},${
						padding / 1.15 + 1.125 + 0.15
					}`}
					fill="black"
				/>
				<polygon
					points={`${middleX},${padding / 1.15 + 0.15} ${
						middleX - 2
					},${padding / 1.15 - 1.125 + 0.15} ${middleX - 2},${
						padding / 1.15 + 1.125 + 0.15
					}`}
					fill="black"
				/>
				<polygon
					points={`${middleX},${padding / 1.15 + 0.15} ${
						middleX + 2
					},${padding / 1.15 - 1.125 + 0.15} ${middleX + 2},${
						padding / 1.15 + 1.125 + 0.15
					}`}
					fill="black"
				/>
				<polygon
					points={`${middleX + xRight},${padding / 1.15 + 0.15} ${
						middleX + xRight - 2
					},${padding / 1.15 - 1.125 + 0.15} ${
						middleX + xRight - 2
					},${padding / 1.15 + 1.125 + 0.15}`}
					fill="black"
				/>
			</g>

			<rect
				x={`${middleX + xRight}mm`}
				y={`${padding / 1.4}mm`}
				width="1px"
				height={`${padding / 8 + frameHeight - yRight}mm`}
			/>
			<rect
				x={`${middleX}mm`}
				y={`${padding / 1.15}mm`}
				height="1px"
				width={`${xRight}mm`}
			/>
			<text
				x={`${middleX + xRight / 2 - 1}mm`}
				y={`${padding / 1.15 - 1}mm`}
				fill="black"
			>
				x
			</text>

			{/* lines besides pluses */}
			<rect
				x={`${padding + 10}mm`}
				y={`${padding + frameHeight - yLeft}mm`}
				height="1px"
				width={`${middleX - xLeft - padding - 10 - 5}mm`}
			/>
			<rect
				x={`${
					padding + 10 + (middleX - xLeft - padding - 10 - 5) / 2
				}mm`}
				y={`${padding + frameHeight - yLeft}mm`}
				width="1px"
				height={`${yLeft}mm`}
			/>
			<text
				x={`${
					padding + 7 + (middleX - xLeft - padding - 10 - 5) / 2
				}mm`}
				y={`${padding + frameHeight - yLeft / 2 + 1}mm`}
			>
				y
			</text>
			<rect
				x={`${middleX + xRight + 5}mm`}
				y={`${padding + frameHeight - yRight}mm`}
				height="1px"
				width={`${middleX - xRight - padding - 10 - 5}mm`}
			/>
			<rect
				x={`${
					middleX +
					xRight +
					5 +
					(middleX - xRight - padding - 10 - 5) / 2
				}mm`}
				y={`${padding + frameHeight - yRight}mm`}
				width="1px"
				height={`${yRight}mm`}
			/>
			<text
				x={`${
					middleX +
					xRight +
					6 +
					(middleX - xRight - padding - 10 - 5) / 2
				}mm`}
				y={`${padding + frameHeight - yRight / 2 + 1}mm`}
			>
				y
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 +
						0.15
					},${padding + frameHeight - yRight} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 -
						1.125 +
						0.15
					},${padding + frameHeight - yRight + 2} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 +
						1.125 +
						0.15
					},${padding + frameHeight - yRight + 2}`}
					fill="black"
				/>
				<polygon
					points={`${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 +
						0.15
					},${padding + frameHeight} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 -
						1.125 +
						0.15
					},${padding + frameHeight - 2} ${
						middleX +
						xRight +
						5 +
						(middleX - xRight - padding - 10 - 5) / 2 +
						1.125 +
						0.15
					},${padding + frameHeight - 2}`}
					fill="black"
				/>
				<polygon
					points={`${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 +
						0.15
					},${padding + frameHeight - yLeft} ${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 -
						1.125 +
						0.15
					},${padding + frameHeight - yLeft + 2} ${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 +
						1.125 +
						0.15
					},${padding + frameHeight - yLeft + 2}`}
					fill="black"
				/>
				<polygon
					points={`${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 +
						0.15
					},${padding + frameHeight} ${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 -
						1.125 +
						0.15
					},${padding + frameHeight - 2} ${
						padding +
						10 +
						(middleX - xLeft - padding - 10 - 5) / 2 +
						1.125 +
						0.15
					},${padding + frameHeight - 2}`}
					fill="black"
				/>
			</g>

			<text
				x="0"
				y="10mm"
				fill="black"
				style={{
					fontSize: "3rem",
					color: "black",
					fontWeight: "bold",
					fontFamily: "sans-serif",
				}}
			>
				L
			</text>
			<text
				x={`${width - 10}mm`}
				y="10mm"
				fill="black"
				style={{
					fontSize: "3rem",
					color: "black",
					fontWeight: "bold",
					fontFamily: "sans-serif",
				}}
			>
				R
			</text>

			<rect
				x={`${padding}mm`}
				y={`${height - 15}mm`}
				height="1px"
				width="10cm"
			/>
			<rect
				x={`${padding}mm`}
				y={`${height - 18}mm`}
				height="6mm"
				width="1px"
			/>
			<rect
				x={`${padding + 100}mm`}
				y={`${height - 18}mm`}
				height="6mm"
				width="1px"
			/>
			<text x={`${padding + 46}mm`} y={`${height - 18}mm`} fill="black">
				10cm
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${padding},${height - 15 + 0.15} ${padding + 2},${
						height - 15 - 1.125 + 0.15
					} ${padding + 2},${height - 15 + 1.125 + 0.15} `}
					fill="black"
				/>
				<polygon
					points={`${padding + 100},${height - 15 + 0.15} ${
						padding + 100 - 2
					},${height - 15 - 1.125 + 0.15} ${padding + 100 - 2},${
						height - 15 + 1.125 + 0.15
					} `}
					fill="black"
				/>
			</g>

			<rect
				x={`${padding}mm`}
				y={`${height - 5}mm`}
				height="1px"
				width="5in"
			/>
			<rect
				x={`${padding}mm`}
				y={`${height - 8}mm`}
				height="6mm"
				width="1px"
			/>
			<rect
				x={`${padding + 127}mm`}
				y={`${height - 8}mm`}
				height="6mm"
				width="1px"
			/>
			<text x={`${padding + 57}mm`} y={`${height - 8}mm`} fill="black">
				5inch
			</text>
			<g transform="scale(3.78)">
				<polygon
					points={`${padding},${height - 5 + 0.15} ${padding + 2},${
						height - 5 - 1.125 + 0.15
					} ${padding + 2},${height - 5 + 1.125 + 0.15} `}
					fill="black"
				/>
				<polygon
					points={`${padding + 127},${height - 5 + 0.15} ${
						padding + 127 - 2
					},${height - 5 - 1.125 + 0.15} ${padding + 127 - 2},${
						height - 5 + 1.125 + 0.15
					} `}
					fill="black"
				/>
			</g>
		</svg>
	);
};

export default Sizing;
