import { formatRelative } from "date-fns";
import { Link } from "react-router-dom";
import { BodyText, ButtonText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

type DraftItemProps = {
	id: string;
	name: string;
	image: string;
	date: Date;
};

const DraftItem = ({ id, date, image, name }: DraftItemProps) => {
	const { branding } = useBrandingStore();

	return (
		<Link
			to={`/measurement/eyes/${id}/process`}
			state={{ previousPage: "/" }}
			className={cn(
				branding === "optiswiss"
					? "bg-quaternary-100 rounded-[10px] overflow-hidden items-center px-4 py-2.5 gap-2.5"
					: "bg-quaternary-20",
				"flex w-full h-24 shrink-0 grow-0 flex-row"
			)}
		>
			<img
				className={cn(
					branding === "optiswiss"
						? "size-[75px] rounded-full object-cover object-center"
						: "h-full w-16 object-cover"
				)}
				src={image}
				loading="lazy"
			/>
			<div
				className={cn(
					branding === "optiswiss"
						? "gap-3"
						: "relative justify-between py-3 pl-3",
					"flex flex-col"
				)}
			>
				<ButtonText
					type="bold"
					className={cn(
						branding === "optiswiss"
							? "text-pure-black"
							: "text-primary-dark-100"
					)}
				>
					{name}
				</ButtonText>
				<BodyText
					type={branding === "optiswiss" ? "normal" : "mini"}
					className={cn(
						branding === "optiswiss"
							? "text-primary-dark-100"
							: "text-primary-dark-60"
					)}
				>
					{formatRelative(date, new Date())}
				</BodyText>
			</div>
		</Link>
	);
};
export default DraftItem;
