import { Loading, OSLoading } from "../icons";
import { useTranslation } from "react-i18next";
import { ButtonText, HeadingText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

// TODO primary gray
const ModalUpdate = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	return (
		<div className="p-6 md:py-20">
			<div
				className={cn(
					"flex flex-col items-center gap-3",
					branding === "optiswiss" && "gap-4"
				)}
			>
				{branding === "optiswiss" ? (
					<OSLoading className="animate-spin-slow mb-4" />
				) : (
					<Loading
						height={48}
						width={48}
						// eslint-disable-next-line tailwindcss/no-custom-classname
						className="fill-primary-dark-100 animate-spin-slow"
					/>
				)}
				<HeadingText h={4}>
					{t("modalUpdate.workingOnUpdate")}
				</HeadingText>
				<ButtonText
					type="normal"
					className="text-primary-dark-60 text-center"
				>
					asd
				</ButtonText>
			</div>
		</div>
	);
};

export default ModalUpdate;
