import clsx from "clsx";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SolidButton, TextButton } from "../../components/buttons";
import { Checkbox } from "../../components/input";
import { FrameLayout } from "../../components/layouts";
import { ModalBase, ModalLoading } from "../../components/modals";
import { HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useHeaderStore,
	useSnackStore,
} from "../../store";
import {
	home,
	fatoryReset,
	listMeasurements,
	getSettings,
	testServerPresence,
} from "../../util/api/api-store";
import TextBody from "../../components/typography/TextBody";
import { cn } from "../../util/helpers";

type FactoryResetProcess = {
	started: boolean;
	errorOccured: boolean;
	refreshRequired: boolean;
	finished: boolean;
};

const FactoryReset = () => {
	const { t, i18n } = useTranslation();
	const { branding } = useBrandingStore();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		access_id: "",
		retention_period_72h: false,
		admin_settings: false,
		export_settings: false,
		other_settings: false,
		comm_settings: false,
		ap_password: false,
		language_en_GB: false,
		delete_measurements: false,
		module_b2b_optic_off: false,
	});
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const openSnack = useSnackStore((state) => state.open);
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);
	const { loggedOut } = useHeaderStore();
	const { state } = useLocation();
	const [factoryResetProcess, setFactoryResetProcess] =
		useState<FactoryResetProcess>({
			started: false,
			errorOccured: false,
			refreshRequired: false,
			finished: false,
		});

	const checkChecked = () => {
		const isChecked = Object.values(formData).reduce(
			(a, item) => a + (item === true ? 1 : 0),
			0
		);
		console.log("checkChecked", isChecked);
		if (isChecked === Object.values(formData).length - 1) {
			return "checked";
		} else if (isChecked !== 0) {
			return "indeterminate";
		} else {
			return "unchecked";
		}
	};

	const setAllChecked = (value: boolean) => {
		console.log(value);
		setFormData((current) => ({
			...current,
			retention_period_72h: value,
			admin_settings: value,
			export_settings: value,
			other_settings: value,
			comm_settings: value,
			ap_password: value,
			language_en_GB: value,
			delete_measurements: value,
			module_b2b_optic_off: value,
		}));
	};

	useEffect(() => {
		if (!state?.loggedIn) {
			navigate("/admin/login");
		}
	}, []);

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	useEffect(() => {
		const unSub = useHeaderStore.subscribe((state) => {
			console.log(state);

			if (state.logout) {
				console.log(checkChecked());
				if (checkChecked() !== "unchecked") {
					setModalLeaveOpen(true);
					return;
				}
				navigate("/");
				loggedOut();
			}
		});

		return () => unSub();
	}, [formData]);

	useEffect(() => {
		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
			setFormData({
				...formData,
				access_id: access_id,
			});
		});
	}, []);

	const onSubmit = () => {
		setIsSubmitted(true);
		setFactoryResetProcess({ ...factoryResetProcess, started: true });
		fatoryReset.setRequest(formData, true);
		fatoryReset.invalidate();
		fatoryReset
			.fetchData()
			.then(() => {
				setTimeout(
					() => {
						if (formData.ap_password) {
							setIsSubmitted(false);
							setFactoryResetProcess((p) => ({
								...p,
								refreshRequired: true,
							}));
						} else {
							goHome();
						}
					},
					formData.ap_password ? 12000 : 100
				);
			})
			.catch(() => {
				setFactoryResetProcess({
					...factoryResetProcess,
					errorOccured: true,
				});
			});
	};

	const goHome = () => {
		if (formData.language_en_GB) {
			i18n.changeLanguage("en-US");
		}
		if (formData.delete_measurements) {
			listMeasurements.invalidate();
		}
		getSettings.setRequest(null, true);
		getSettings.invalidate();
		getSettings
			.fetchData()
			.then(() => {
				loggedOut();
				setFactoryResetProcess({
					...factoryResetProcess,
					finished: true,
				});
				setIsSubmitted(false);
				openSnack(t("factoryReset.resetSuccessfully"), "ok");
				navigate("/");
			})
			.catch(() => {
				setFactoryResetProcess({
					...factoryResetProcess,
					errorOccured: true,
				});
			});
	};

	const refresh = () => {
		setIsSubmitted(true);
		testServerPresence.setRequest(null, true);
		testServerPresence.invalidate();
		testServerPresence
			.fetchData()
			.then((resp) => {
				if (resp.success) {
					goHome();
				}
			})
			.finally(() => {
				setIsSubmitted(false);
			});
	};

	const closeLeaveModal = () => {
		setModalLeaveOpen(false);
	};

	return (
		<FrameLayout hiddenFooter={true} headerType="admin">
			<div className="container pt-12 md:pt-16">
				<HeadingText
					h={2}
					className={cn(branding === "optiswiss" && "!text-[26px]")}
				>
					{t("factoryReset.pageTitle")}
				</HeadingText>
				<div
					className={cn(branding === "optiswiss" ? "mt-6" : "mt-12")}
				>
					<div className="py-3">
						<Checkbox
							checked={checkChecked()}
							setChecked={() => {
								setAllChecked(
									checkChecked() === "checked" ? false : true
								);
							}}
							label={t("reset.all")}
						/>
					</div>
					<div className="ml-6 mt-3 flex flex-col gap-3">
						<div className="p-3">
							<Checkbox
								checked={
									formData.retention_period_72h
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										retention_period_72h:
											!current.retention_period_72h,
									}));
								}}
								label={t("reset.retentionPeriod")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.admin_settings
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										admin_settings: !current.admin_settings,
									}));
								}}
								label={t("reset.adminSettings")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.export_settings
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										export_settings:
											!current.export_settings,
									}));
								}}
								label={t("reset.exportSettings")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.other_settings
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										other_settings: !current.other_settings,
									}));
								}}
								label={t("reset.otherSettings")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.comm_settings
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										comm_settings: !current.comm_settings,
									}));
								}}
								label={t("factoryReset.resetWifiSettingsShop")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.ap_password
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										ap_password: !current.ap_password,
									}));
								}}
								label={t(
									"factoryReset.resetWifiSettingsDirect"
								)}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.language_en_GB
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										language_en_GB: !current.language_en_GB,
									}));
								}}
								label={t("reset.language")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.delete_measurements
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										delete_measurements:
											!current.delete_measurements,
									}));
								}}
								label={t("reset.allMeasurements")}
							/>
						</div>
						<div className="p-3">
							<Checkbox
								checked={
									formData.module_b2b_optic_off
										? "checked"
										: "unchecked"
								}
								setChecked={() => {
									setFormData((current) => ({
										...current,
										module_b2b_optic_off:
											!current.module_b2b_optic_off,
									}));
								}}
								label={t("reset.b2bSettings")}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				ref={buttonRef}
				className={clsx(
					"before:bg-pure-white sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]",
					stuck && "shadow-[0px_-1px_0px_#e6e8e9]"
				)}
			>
				<div className="grid h-[72px] items-center justify-end md:container">
					<SolidButton
						color="primary"
						disabled={isSubmitted || checkChecked() === "unchecked"}
						onClick={onSubmit}
					>
						{t("factoryReset.resetAndLogout")}
					</SolidButton>
				</div>
			</div>
			<ModalBase
				disableHeader
				open={
					factoryResetProcess.started &&
					!factoryResetProcess.refreshRequired &&
					!factoryResetProcess.errorOccured &&
					!factoryResetProcess.finished
				}
				close={() => undefined}
			>
				<ModalLoading
					title={t("factoryReset.resetting")}
					text={t("factoryReset.pleaseWaitDontRefresh")}
				/>
			</ModalBase>
			<ModalBase
				disableClose
				title={t("factoryReset.successfullyReset") || undefined}
				open={
					factoryResetProcess.started &&
					factoryResetProcess.refreshRequired &&
					!factoryResetProcess.errorOccured &&
					!factoryResetProcess.finished
				}
				close={() => undefined}
			>
				<div className="px-6 py-5">
					<HeadingText h={5} className="mb-3">
						{t("factoryReset.follow")}
					</HeadingText>
					<ol className="pl-4" style={{ listStyle: "auto" }}>
						<li>
							<TextBody type={"normal"}>
								{t("factoryReset.goToWifiSettings")}
							</TextBody>
						</li>
						<li>
							<TextBody type={"normal"}>
								{t("factoryReset.connectWithDefaultPassword")}
							</TextBody>
						</li>
						<li>
							<TextBody type={"normal"}>
								{t("factoryReset.clickRefresh")}
							</TextBody>
						</li>
					</ol>
					<p className="mt-6 text-right">
						<SolidButton
							color="primary"
							onClick={refresh}
							loading={isSubmitted}
						>
							{t("factoryReset.refresh")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
			<ModalBase
				title={t("factoryReset.failedToReset") || undefined}
				open={factoryResetProcess.errorOccured}
				close={() =>
					setFactoryResetProcess({
						...factoryResetProcess,
						started: false,
						errorOccured: false,
					})
				}
			>
				<TextBody className="px-6 py-5" type={"normal"}>
					{t("factoryReset.errorChangingPassword")}
				</TextBody>
			</ModalBase>
			<ModalBase
				title={t("factoryReset.leaveAdmin") || undefined}
				open={modalLeaveOpen}
				close={closeLeaveModal}
			>
				<div className="p-6">
					<p className="mb-6">
						{t("factoryReset.leaveAdminConfirm")}
					</p>
					<SolidButton color="primary" onClick={onSubmit} fullWidth>
						{t("factoryReset.saveAdmin")}
					</SolidButton>
					<TextButton
						color="primary"
						className="text-primary-100 mt-4"
						onClick={() => {
							loggedOut();
							navigate("/");
						}}
						fullWidth
					>
						{t("factoryReset.quitWithoutSave")}
					</TextButton>
				</div>
			</ModalBase>
		</FrameLayout>
	);
};

export default FactoryReset;
