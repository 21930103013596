import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BodyText } from "../typography";

const DraftTag = ({ className }: { className?: string }) => {
	const { t } = useTranslation();
	return (
		<div>
			<BodyText
				type="normal"
				className={clsx(
					"text-primary-dark-80 bg-quaternary-20 h-fit w-fit p-2",
					className
				)}
			>
				{t("draftTag.draft")}
			</BodyText>
		</div>
	);
};

export default DraftTag;
