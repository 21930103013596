import clsx from "clsx";
import { SVGProps } from "react";
import { Link } from "react-router-dom";
import { BodyText, HeadingText } from "../typography";
import { useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";

export type MainCardProps = {
	title: string;
	to: string;
	icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	message: string;
	className?: string;
};

const MainCard = ({ title, to, icon, message, className }: MainCardProps) => {
	const { branding } = useBrandingStore();

	return (
		<Link
			to={to}
			className={clsx(
				branding === "optiswiss"
					? "bg-[#EBECED] flex w-full flex-row items-center justify-start gap-3 px-3.5 py-9 rounded-[20px]"
					: "bg-primary-100 flex w-full flex-row items-center justify-start gap-6 px-6 py-5",
				className
			)}
		>
			{icon?.call(null, {
				width: branding === "optiswiss" ? 86 : 48,
				height: branding === "optiswiss" ? 86 : 48,
				className: "fill-pure-white",
			})}
			<div
				className={cn(
					branding === "optiswiss" && "flex flex-col gap-1"
				)}
			>
				<HeadingText
					className={cn(
						branding === "optiswiss"
							? "text-pure-black"
							: "text-pure-white"
					)}
					h={4}
				>
					{title}
				</HeadingText>
				<BodyText
					className={cn(
						branding === "optiswiss"
							? "text-primary-dark-60"
							: "text-primary-20"
					)}
					type="normal"
				>
					{message}
				</BodyText>
			</div>
		</Link>
	);
};

export default MainCard;
