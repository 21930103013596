import * as React from 'react';
import { SVGProps } from 'react';
const SvgLeftHand = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="LeftHand_svg__a">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<g clipPath="url(#LeftHand_svg__a)">
			<path
				d="M12.23 24c1.22 0 2.33-.23 3.34-.69s1.88-1.07 2.61-1.82c.73-.76 1.3-1.64 1.71-2.64s.61-2.03.61-3.1v-10c0-.35-.12-.65-.36-.89s-.54-.36-.89-.36-.65.12-.89.36-.36.54-.36.89V12h-1V2.75c0-.35-.12-.65-.36-.89s-.54-.36-.89-.36-.65.12-.89.36-.36.54-.36.89V11h-1V1.25c0-.35-.12-.65-.36-.89S12.6 0 12.25 0s-.65.12-.89.36-.36.54-.36.89V11h-1V3.25c0-.35-.12-.65-.36-.89S9.1 2 8.75 2s-.65.12-.89.36-.36.54-.36.89v10.77c1.03.13 1.88.58 2.52 1.34.65.76.98 1.64.98 2.64h-1c0-.83-.29-1.54-.88-2.12A2.9 2.9 0 0 0 7 15h-.5v-4.75c0-.35-.12-.65-.36-.89S5.6 9 5.25 9s-.65.12-.89.36-.36.54-.36.89v5.5c0 1.07.2 2.1.6 3.09s.96 1.87 1.69 2.64c.72.77 1.59 1.38 2.6 1.84s2.12.69 3.34.69z"
				fill={`unset`}
			/>
		</g>
	</svg>
);
export default SvgLeftHand;
