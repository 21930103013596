import clsx from "clsx";
import { useContext } from "react";
import { BrandThemeContext } from "../../util/BrandThemeContext";

type BrandLogoProps = {
	className?: string;
	override?: "hoya" | "vs" | "ollendorf" | "optiswiss";
	size?: "24px" | "40px" | "37px";
};

const paths = {
	hoya: "/icons/hoya_logo.svg",
	vs: "/icons/visusolution_logo.svg",
	ollendorf: "/icons/ollendorf_logo.svg",
	optiswiss: "/icons/optiswiss_logo.svg",
};

const BrandLogo = ({ className, override, size = "24px" }: BrandLogoProps) => {
	const brandcontext = useContext(BrandThemeContext);

	const selected = override ?? brandcontext;
	return (
		<img
			className={clsx(
				"col-start-3 w-auto justify-self-end",
				size === "24px"
					? "h-[24px]"
					: size === "40px"
					? "h-[40px]"
					: "h-[37px]",
				className
			)}
			src={paths[selected] ?? paths.hoya}
		/>
	);
};
export default BrandLogo;
