import { Loading } from "../icons";
import { ButtonText, HeadingText } from "../typography";

type ModalLoadingParams = {
	title: string,
	text: string
}

// TODO primary gray
const ModalLoading = ({title, text}: ModalLoadingParams) => {
	return (
		<div className="p-6 md:py-20">
			<div className="flex flex-col items-center gap-3">
				<Loading
					height={48}
					width={48}
					// eslint-disable-next-line tailwindcss/no-custom-classname
					className="fill-primary-dark-100 animate-spin-slow"
				/>
				<HeadingText h={4}>{title}</HeadingText>
				<ButtonText
					type="normal"
					className="text-primary-dark-60 text-center"
				>
					{text}
				</ButtonText>
			</div>
		</div>
	);
};

export default ModalLoading;
