import * as React from 'react';
import { SVGProps } from 'react';
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Add_svg__b">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<mask
			id="Add_svg__a"
			height={14}
			maskUnits="userSpaceOnUse"
			width={14}
			x={5}
			y={5}
		>
			<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" fill="#fff" />
		</mask>
		<g fill={`unset`} mask="url(#Add_svg__a)" clipPath="url(#Add_svg__b)">
			<path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
			<path clipRule="evenodd" d="M0 0h24v24H0z" fillRule="evenodd" />
		</g>
	</svg>
);
export default SvgAdd;
