import { SVGProps } from "react";

const Hand = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="14"
			height="22"
			viewBox="0 0 14 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_1846)">
				<path
					d="M12.5535 8.5101C11.682 7.59022 10.8792 8.96142 10.2265 9.48491C10.2336 8.10336 10.2817 6.71889 10.3334 5.3384C10.3702 4.35484 10.5984 3.04652 10.2039 2.133C9.76435 1.11494 8.57835 1.7313 8.39716 2.661C8.27076 3.30919 8.30868 4.0322 8.26566 4.69551C8.21288 5.50821 8.16276 6.3209 8.10221 7.13279C7.98246 5.8717 7.87845 4.60689 7.78575 3.34262C7.72166 2.46784 7.67687 1.33198 6.75743 1.23036C5.56854 1.09903 5.68696 2.67718 5.7719 3.6358C5.89298 5.00196 6.02782 6.36627 6.13404 7.73429C5.88012 6.5902 5.60225 5.44294 5.38824 4.28744C5.21637 3.3604 4.8285 2.41955 3.86094 2.88414C2.93285 3.32962 3.36285 4.48936 3.55313 5.33468C3.8106 6.4785 4.15899 7.59446 4.32532 8.76561C4.00464 8.15218 3.7077 7.52123 3.39434 6.90249C3.15705 6.43366 2.90402 5.7722 2.46004 5.53182C2.05244 5.31107 1.52353 5.56684 1.28757 6.00145C1.00171 6.52785 1.28735 7.1901 1.47939 7.68812C2.36779 9.99778 2.95081 12.4749 3.67333 14.8615C3.83322 15.39 3.99755 15.9167 4.17784 16.4359C4.23816 16.6094 4.30004 16.9342 4.44108 17.0427C4.63002 17.1878 5.0068 17.0605 5.21105 17.0472C6.39262 16.9705 7.57397 16.884 8.75487 16.797C9.04095 16.7761 9.9462 16.8944 10.1203 16.5861C10.3598 16.1621 10.5239 15.6739 10.684 15.2021C11.114 13.9331 11.454 12.6144 11.9536 11.3801C12.2656 10.6088 13.3343 9.3342 12.5535 8.51037V8.5101Z"
					stroke="#1D1D1D"
					strokeWidth="0.7"
				/>
				<path
					d="M8.55422 17.2868C7.80795 17.3388 7.06193 17.3916 6.31566 17.4464C5.64402 17.4956 4.97238 17.5462 4.30099 17.5982C3.78725 17.6379 3.53174 17.6385 3.53174 18.2321V19.5923C3.53174 19.8275 3.46033 20.2284 3.53174 20.4528C3.53174 20.9146 4.47223 20.7492 4.70476 20.7492H11.2573C12.291 20.7492 11.0631 17.7703 10.88 17.3182C10.7055 16.8867 8.94316 17.2598 8.55422 17.2871V17.2868Z"
					stroke="#1D1D1D"
					strokeWidth="0.7"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_1846">
					<rect width="14" height="22" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Hand;
