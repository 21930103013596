import { SVGProps } from "react";

const OSPerson = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="17"
			height="22"
			viewBox="0 0 17 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g clipPath="url(#clip0_1_2727)">
				<path
					d="M16.5 18.0732C16.5 19.1145 15.7749 19.9641 14.3164 20.578C12.8641 21.1894 10.8137 21.4998 8.5 21.4998C6.19354 21.4998 4.14278 21.1511 2.68864 20.52C1.21672 19.8812 0.5 19.0285 0.5 18.0732C0.5 15.3264 1.79495 12.8966 3.78277 11.3939C5.03553 12.5395 6.68783 13.2384 8.5 13.2384C10.3122 13.2384 11.9644 12.5395 13.2172 11.3938C15.2051 12.8966 16.5 15.3264 16.5 18.0732Z"
					stroke="white"
				/>
				<path
					d="M13.7424 5.92465C13.7424 8.93541 11.3805 11.3493 8.49961 11.3493C5.61869 11.3493 3.25684 8.93541 3.25684 5.92465C3.25684 2.91389 5.61869 0.5 8.49961 0.5C11.3805 0.5 13.7424 2.91389 13.7424 5.92465Z"
					stroke="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1_2727">
					<rect width="17" height="22" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default OSPerson;
