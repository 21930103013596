import { createElement } from "react";
import { cn } from "../../util/helpers";
type HeadingTextProps = {
	h: 1 | 2 | 3 | 4 | 5;
	children: string | JSX.Element | null;
	className?: string;
};

const style = {
	1: "text-[40px] leading-[48px] font-bold md:text-5xl md:leading-[52px]",
	2: "text-[32px] leading-[40px] font-bold md:text-[40px] md:leading-[48px]",
	3: "text-2xl leading-[32px] font-bold md:text-[32px] md:leading-[40px]",
	4: "text-xl leading-[32px] font-bold md:text-2xl",
	5: "text-lg font-bold md:text-xl print:md:text-base md:leading-[32px]",
};

const HeadingText = ({ h, children, className }: HeadingTextProps) => {
	return createElement(
		`h${h}`,
		{ className: cn(style[h], className) },
		children
	);
};

export default HeadingText;
