import { SVGProps } from "react";
const RouteHelp = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M10.7667 13.3732C10.7646 13.3732 10.7625 13.3732 10.7605 13.3732C10.381 13.3698 10.0757 13.0594 10.0792 12.6795L10.0823 12.3358C10.0823 12.3162 10.0833 12.2963 10.0854 12.2767C10.1806 11.2767 10.8444 10.6638 11.3782 10.1712C11.559 10.0041 11.7302 9.84636 11.876 9.6817C12.0537 9.48129 12.3115 9.07086 12.0403 8.57551C11.7278 8.00351 10.9647 7.84161 10.3717 7.97739C9.75226 8.11901 9.52401 8.64804 9.44289 8.95054C9.34458 9.31732 8.96748 9.53492 8.6007 9.43695C8.23392 9.33864 8.01633 8.96154 8.1143 8.59476C8.38826 7.57142 9.09914 6.85814 10.0644 6.63711C11.3634 6.34045 12.6717 6.86536 13.2461 7.91551C13.7243 8.79001 13.5933 9.81611 12.9041 10.5937C12.7126 10.8095 12.5081 10.9986 12.3101 11.1811C11.8168 11.6366 11.5085 11.9412 11.4562 12.3808L11.4535 12.6912C11.4507 13.0697 11.1434 13.3732 10.7667 13.3732Z"
				fill="#DD052B"
			/>
			<path
				d="M10.7666 15.4345C10.5879 15.4345 10.4091 15.3623 10.2819 15.2351C10.1513 15.1079 10.0791 14.9292 10.0791 14.747C10.0791 14.5683 10.1513 14.3895 10.2819 14.2623C10.5363 14.0079 10.9969 14.0079 11.2513 14.2623C11.3819 14.3895 11.4541 14.5683 11.4541 14.747C11.4541 14.9292 11.3819 15.1045 11.2547 15.2351C11.1241 15.3623 10.9488 15.4345 10.7666 15.4345Z"
				fill="#DD052B"
			/>
			<path
				d="M10.9739 19.9365C10.1919 19.9365 9.41675 19.8348 8.66978 19.6337C5.4509 18.7678 2.93053 16.1013 2.24887 12.8402C1.55416 9.51783 2.83634 6.02533 5.51587 3.94254C7.06481 2.73839 9.01834 2.07495 11.0169 2.07495C12.4005 2.07495 13.7745 2.3967 14.9907 3.0048C17.994 4.50733 19.9345 7.64645 19.9345 11.0025C19.9345 13.7408 18.6423 16.3801 16.4781 18.0624C14.9236 19.271 12.9687 19.9365 10.9739 19.9365ZM11.0172 3.44995C9.3229 3.44995 7.66878 4.01026 6.36012 5.02811C4.09241 6.79086 3.00684 9.74676 3.595 12.5586C4.17181 15.3172 6.30409 17.5733 9.02728 18.3058C9.65772 18.4753 10.3129 18.5615 10.9739 18.5615C12.6645 18.5615 14.3197 17.9988 15.6345 16.9769C17.4663 15.553 18.5598 13.3197 18.5598 11.0025C18.5598 8.16276 16.9177 5.50626 14.376 4.23473C13.3496 3.72151 12.1884 3.44995 11.0172 3.44995Z"
				fill="#DD052B"
			/>
		</svg>
	);
};

export default RouteHelp;
