import { useState, useRef, useEffect, useLayoutEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	Back,
	Close,
	Hand,
	LeftHand,
	OSArrowLeft,
	RightHand,
} from "../../components/icons";
import { BodyText, HeadingText } from "../../components/typography";
import { newSome, newNone, newOption, Option } from "../../util/result";
import { ModalBase } from "../../components/modals";
import {
	ImageObjectOptions,
	HandleObjectOptions,
	FramePosition,
	FramesPositions,
	FramesPositionsSide,
} from "../../util/drawing";
import {
	editorPage2,
	editorChange,
	getSettings,
	processMeasurement,
	listMeasurements,
	editorChangeNotification,
} from "../../util/api/api-store";
import {
	IResponseData as Measurement,
	MeasurementSide,
	MeasurementPosition,
} from "../../util/api/request-definitions/editor-page-2";
import EyesHandle1 from "../../assets/icons/svg/eyes_handle_1.svg";
import OSEyesHandle1 from "../../assets/icons/svg/os_eyes_handle_1.svg";
import EyesHandle2Right from "../../assets/icons/svg/eyes_handle_2_right.svg";
import OSEyesHandle2Right from "../../assets/icons/svg/os_eyes_handle_2_right.svg";
import EyesHandle2Left from "../../assets/icons/svg/eyes_handle_2_left.svg";
import OSEyesHandle2Left from "../../assets/icons/svg/os_eyes_handle_2_left.svg";
import { CanvasErrors } from "../../components/measurement/eyes";
import { FramesCanvasController } from "../../components/measurement/frames";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import deepEqual from "deep-equal";
import { cn, measurementOwnerHelper } from "../../util/helpers";

const maxTryAgainCount = 3;

const Frames = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { branding } = useBrandingStore();
	const [leftHand, setLeftHand] = useState<boolean>(false);
	const [modalLeaveOpen, setModalLeaveOpen] = useState<boolean>(false);
	const [tryAgainCount, setTryAgainCount] = useState<number>(0);
	const [isTryingAgain, setIsTryingAgain] = useState<boolean>(false);

	const [setEdgeCount, setSetEdgeCount] = useState<number>(0);
	const [framesPositionsChangedSignal, setFramesPositionsChangedSignal] =
		useState<boolean>(false);

	const canvasWrapperRef = useRef<HTMLDivElement>(null);

	const [imageObjects, setImageObjects] = useState<ImageObjectOptions>({
		topImage: newNone(),
		bottomImage: newNone(),
	});
	const [handleObjects, setHandleObjects] = useState<HandleObjectOptions>({
		handle1: newNone(),
		handle2Right: newNone(),
		handle2Left: newNone(),
	});

	const [canvasMaxSize, setCanvasMaxSize] = useState<{
		width: number;
		height: number;
	}>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const [framesPositions, setFramesPositions] = useState<
		Option<FramesPositions>
	>(newNone());

	const measurementResult = editorPage2.useGetResult();
	const getSettingsResult = getSettings.useGetResult();

	const setFramesPositionsWrapper = async (newpos: FramesPositions) => {
		setFramesPositions(() => {
			return newSome(newpos);
		});
		if (
			framesPositions.happy &&
			haveSidePositionsChanged(newpos, framesPositions.data)
		) {
			await saveMeasurement(newpos);
			editorPage2.invalidate();
			loadMeasurementData(true);
			return Promise.resolve();
		} else {
			return getAccessIdAsPromise().then((access_id) => {
				editorChangeNotification.setRequest({
					access_id,
					measurement: measurementId,
				});
				editorChangeNotification.invalidate();
				return editorChangeNotification.fetchData();
			});
		}
	};

	const loadMeasurementData = (keep: boolean) => {
		getAccessIdAsPromise().then((access_id) => {
			editorPage2.setRequest({
				access_id,
				measurement: measurementId,
			});
			editorPage2.fetchData(keep).then((resp) => {
				if (isMeasurementZero(resp)) {
					setIsTryingAgain(true);
					setTimeout(() => setTryAgainCount((c) => c + 1), 2000);
				} else {
					setIsTryingAgain(false);
					setFramesPositions(newSome(transformToBeProcessed(resp)));
					setFramesPositions(() => {
						return newSome(transformToBeProcessed(resp));
					});
					setFramesPositionsChangedSignal((s) => {
						return !s;
					});
				}
			});
		});
	};

	const measurementId = newOption(useParams().measurement).expect(
		"A measurement id has not been provided."
	);

	useEffect(() => {
		if (tryAgainCount < maxTryAgainCount) {
			editorPage2.invalidate();
			loadMeasurementData(false);
		} else {
			setIsTryingAgain(false);
		}
	}, [tryAgainCount]);

	useEffect(() => {
		getSettings.setRequest(null);
		getSettings.fetchData();
	}, []);

	const topImageSrc =
		(measurementResult.happy && measurementResult.data.images._CO) ?? [];
	const bottomImageSrc =
		(measurementResult.happy && measurementResult.data.images._CU) ?? [];

	useEffect(() => {
		const measurementResult = editorPage2.getResult();
		if (
			measurementResult.kind === "ok" &&
			measurementResult.data.measurement === measurementId
		) {
			const measurement = measurementResult.data;
			setImageObjects({ topImage: newNone(), bottomImage: newNone() });
			const topImage = new Image();
			topImage.addEventListener("load", () => {
				setImageObjects(({ bottomImage }) => ({
					topImage: newSome(topImage),
					bottomImage,
				}));
			});
			const bottomImage = new Image();
			bottomImage.addEventListener("load", () => {
				setImageObjects(({ topImage }) => ({
					bottomImage: newSome(bottomImage),
					topImage,
				}));
			});
			topImage.src =
				import.meta.env.VITE_MASTER_SERVER_HOST +
				measurement.images._CO;
			bottomImage.src =
				import.meta.env.VITE_MASTER_SERVER_HOST +
				measurement.images._CU;
		} else {
			setImageObjects({ topImage: newNone(), bottomImage: newNone() });
		}
	}, [topImageSrc, bottomImageSrc]);

	useEffect(() => {
		const handle1 = new Image(
			branding === "optiswiss" ? 30 : 40,
			branding === "optiswiss" ? 30 : 40
		);
		handle1.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle1: newSome(handle1),
			}));
		});
		handle1.src = branding === "optiswiss" ? OSEyesHandle1 : EyesHandle1;
		handle1.dataset.branding = branding;
		const handle2Right = new Image(
			branding === "optiswiss" ? 30 : 48,
			branding === "optiswiss" ? 30 : 24
		);
		handle2Right.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle2Right: newSome(handle2Right),
			}));
		});
		handle2Right.src =
			branding === "optiswiss" ? OSEyesHandle2Right : EyesHandle2Right;
		handle2Right.dataset.branding = branding;
		const handle2Left = new Image(
			branding === "optiswiss" ? 30 : 48,
			branding === "optiswiss" ? 30 : 24
		);
		handle2Left.addEventListener("load", () => {
			setHandleObjects((handles) => ({
				...handles,
				handle2Left: newSome(handle2Left),
			}));
		});
		handle2Left.src =
			branding === "optiswiss" ? OSEyesHandle2Left : EyesHandle2Left;
		handle2Left.dataset.branding = branding;
	}, []);

	useLayoutEffect(() => {
		const canvasWrapperRefOpt = newOption(canvasWrapperRef.current);
		const canvasWrapperRefInner = canvasWrapperRefOpt.expect(
			"componentDidMount has already fired"
		);
		const updateResize = () =>
			setCanvasMaxSize({
				width: canvasWrapperRefInner.clientWidth,
				height: window.innerHeight,
			});
		window.addEventListener("resize", updateResize);
		return () => window.removeEventListener("resize", updateResize);
	});

	const saveMeasurement = async (framesPositions: FramesPositions) => {
		if (measurementResult.kind === "ok") {
			return getAccessIdAsPromise().then(async (access_id) => {
				editorChange.setRequest(
					transformToBeSent(
						framesPositions,
						measurementResult.data,
						access_id
					)
				);
				editorChange.invalidate();
				editorPage2.invalidate();
				processMeasurement.invalidate();
				return editorChange.fetchData().catch((err) => {
					if (
						err.kind === "api-response-error" &&
						err.responseCode === 423
					) {
						useSnackStore
							.getState()
							.open(t("frames.coulndtBeSaved"), "warning");
					}
				});
			});
		} else {
			return Promise.reject();
		}
	};

	const saveCurrentMeasurement = () => {
		if (
			framesPositions.kind === "some" &&
			measurementResult.kind === "ok"
		) {
			saveMeasurement(framesPositions.data);
		}
	};

	const closeMeasurement = () => {
		editorPage2.invalidate();
		listMeasurements.invalidate();
		processMeasurement.invalidate();
	};

	measurementOwnerHelper(
		saveCurrentMeasurement,
		() => {
			closeMeasurement();
			navigate(state?.previousPage ? `${state.previousPage}` : "/");
		},
		measurementResult.asOption().map((resp) => resp.measurement)
	);

	const canShow =
		canvasWrapperRef.current !== undefined && // Sorry
		canvasWrapperRef.current !== null &&
		handleObjects.handle1.kind === "some" &&
		handleObjects.handle2Left.kind === "some" &&
		handleObjects.handle2Right.kind === "some" &&
		imageObjects.topImage.kind === "some" &&
		imageObjects.bottomImage.kind === "some" &&
		measurementResult.kind === "ok" &&
		getSettingsResult.kind === "ok" &&
		measurementResult.data.measurement === measurementId;

	const hasLBottomNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.bottom_nasal.position,
								Object.values(pos.right.bottomNasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLBottomMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.bottom.position,
								Object.values(pos.right.bottom)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLBottomTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.bottom_temporal.position,
								Object.values(pos.right.bottomTemporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.nasal.position,
								Object.values(pos.right.nasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.temporal.position,
								Object.values(pos.right.temporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLTopNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.top_nasal.position,
								Object.values(pos.right.topNasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLTopMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.top.position,
								Object.values(pos.right.top)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasLTopTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._L.top_temporal.position,
								Object.values(pos.right.topTemporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRBottomNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.bottom_nasal.position,
								Object.values(pos.left.bottomNasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRBottomMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.bottom.position,
								Object.values(pos.left.bottom)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRBottomTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.bottom_temporal.position,
								Object.values(pos.left.bottomTemporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.nasal.position,
								Object.values(pos.left.nasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.temporal.position,
								Object.values(pos.left.temporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRTopNasalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.top_nasal.position,
								Object.values(pos.left.topNasal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRTopMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.top.position,
								Object.values(pos.left.top)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasRTopTemporalMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return !deepEqual(
								res.editable._R.top_temporal.position,
								Object.values(pos.left.topTemporal)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const hasMeasurementChanged = useMemo(
		() =>
			measurementResult
				.map((res) =>
					framesPositions
						.map((pos) => {
							return (
								!deepEqual(
									res.editable._L.bottom_nasal.position,
									Object.values(pos.right.bottomNasal)
								) ||
								!deepEqual(
									res.editable._L.bottom.position,
									Object.values(pos.right.bottom)
								) ||
								!deepEqual(
									res.editable._L.bottom_temporal.position,
									Object.values(pos.right.bottomTemporal)
								) ||
								!deepEqual(
									res.editable._L.nasal.position,
									Object.values(pos.right.nasal)
								) ||
								!deepEqual(
									res.editable._L.temporal.position,
									Object.values(pos.right.temporal)
								) ||
								!deepEqual(
									res.editable._L.top_nasal.position,
									Object.values(pos.right.topNasal)
								) ||
								!deepEqual(
									res.editable._L.top.position,
									Object.values(pos.right.top)
								) ||
								!deepEqual(
									res.editable._L.top_temporal.position,
									Object.values(pos.right.topTemporal)
								) ||
								!deepEqual(
									res.editable._R.bottom_nasal.position,
									Object.values(pos.left.bottomNasal)
								) ||
								!deepEqual(
									res.editable._R.bottom.position,
									Object.values(pos.left.bottom)
								) ||
								!deepEqual(
									res.editable._R.bottom_temporal.position,
									Object.values(pos.left.bottomTemporal)
								) ||
								!deepEqual(
									res.editable._R.nasal.position,
									Object.values(pos.left.nasal)
								) ||
								!deepEqual(
									res.editable._R.temporal.position,
									Object.values(pos.left.temporal)
								) ||
								!deepEqual(
									res.editable._R.top_nasal.position,
									Object.values(pos.left.topNasal)
								) ||
								!deepEqual(
									res.editable._R.top.position,
									Object.values(pos.left.top)
								) ||
								!deepEqual(
									res.editable._R.top_temporal.position,
									Object.values(pos.left.topTemporal)
								)
							);
						})
						.unwrapOrDefault(false)
				)
				.unwrapOrDefault(false),
		[measurementResult, framesPositions]
	);

	const transformFramePosition = (pos: FramePosition) => ({
		changed: true,
		position: [pos.x, pos.y1, pos.y2],
	});

	const transformSide = (side: FramesPositionsSide) => ({
		bottom_nasal: transformFramePosition(side.bottomNasal),
		bottom: transformFramePosition(side.bottom),
		bottom_temporal: transformFramePosition(side.bottomTemporal),
		nasal: transformFramePosition(side.nasal),
		temporal: transformFramePosition(side.temporal),
		top_nasal: transformFramePosition(side.topNasal),
		top: transformFramePosition(side.top),
		top_temporal: transformFramePosition(side.topTemporal),
	});

	const transformToBeSent = (
		framesPositions: FramesPositions,
		measurementData: Measurement,
		access_id: string
	) => ({
		...measurementData,
		access_id,
		editable: {
			_L: {
				...measurementData.editable._L,
				...transformSide(framesPositions.right),
			},
			_R: {
				...measurementData.editable._R,
				...transformSide(framesPositions.left),
			},
		},
	});

	const transformToBeProcessedSingle = (pos: MeasurementPosition) => ({
		x: pos.position[0],
		y1: pos.position[1],
		y2: pos.position[2],
	});

	const transformToBeProcessedSide = (side: MeasurementSide) => ({
		bottomNasal: transformToBeProcessedSingle(side.bottom_nasal),
		bottom: transformToBeProcessedSingle(side.bottom),
		bottomTemporal: transformToBeProcessedSingle(side.bottom_temporal),
		nasal: transformToBeProcessedSingle(side.nasal),
		temporal: transformToBeProcessedSingle(side.temporal),
		topNasal: transformToBeProcessedSingle(side.top_nasal),
		top: transformToBeProcessedSingle(side.top),
		topTemporal: transformToBeProcessedSingle(side.top_temporal),
	});

	const transformToBeProcessed = (measurementData: Measurement) => ({
		left: transformToBeProcessedSide(measurementData.editable._R),
		right: transformToBeProcessedSide(measurementData.editable._L),
	});

	const haveSidePositionsChanged = (
		a: FramesPositions,
		b: FramesPositions
	) => {
		return !(
			deepEqual(a.left.nasal, b.left.nasal) &&
			deepEqual(a.left.temporal, b.left.temporal) &&
			deepEqual(a.right.nasal, b.right.nasal) &&
			deepEqual(a.right.temporal, b.right.temporal)
		);
	};

	const isPositionZero = (pos: number[]): boolean => pos.every((p) => p == 0);
	const isSideZero = (side: MeasurementSide): boolean =>
		isPositionZero(side.bottom_nasal.position) &&
		isPositionZero(side.bottom.position) &&
		isPositionZero(side.bottom_temporal.position) &&
		isPositionZero(side.nasal.position) &&
		isPositionZero(side.temporal.position) &&
		isPositionZero(side.top_nasal.position) &&
		isPositionZero(side.top.position) &&
		isPositionZero(side.top_temporal.position);
	const isMeasurementZero = (measurement: Measurement): boolean =>
		isSideZero(measurement.editable._L) &&
		isSideZero(measurement.editable._R);

	return (
		<>
			<div className="before:bg-pure-white fixed top-0 z-10 w-full backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]">
				<div
					className={cn(
						"relative grid grid-cols-[minmax(48px,1fr)_auto_1fr] items-center px-6",
						branding === "optiswiss"
							? "container h-[85px]"
							: "h-[72px]"
					)}
				>
					<TextButton
						color="primary"
						icon={branding === "optiswiss" ? OSArrowLeft : Back}
						iconClasses={
							branding === "optiswiss"
								? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
								: undefined
						}
						to={`/measurement/eyes/${measurementId}/editor`}
						onClick={() => {
							saveCurrentMeasurement();
						}}
						className="-ml-3 rounded-full md:rounded-none [&>span:not(.ripple)]:hidden md:[&>span:not(.ripple)]:inline-block"
					>
						{t("frames.eyes")}
					</TextButton>
					<BodyText
						type="bold16"
						// eslint-disable-next-line tailwindcss/no-custom-classname
						className="line-clamp-2 col-start-2 text-center"
					>
						{measurementResult.kind === "ok"
							? measurementResult.data.order_id
							: ""}
					</BodyText>
					<div className="flex items-center gap-3 justify-self-end col-start-3 md:gap-6">
						<TextButton
							color="secondary"
							// @todo az ikon nevek forditva vannak
							//icon={leftHand ? RightHand : LeftHand}
							icon={
								branding === "optiswiss"
									? Hand
									: leftHand
									? RightHand
									: LeftHand
							}
							iconClasses={cn(
								branding === "optiswiss"
									? "size-[17px]"
									: undefined,
								branding === "optiswiss" &&
									!leftHand &&
									"-scale-x-100"
							)}
							className={cn(
								branding === "optiswiss" &&
									"!rounded-[10px] border border-quaternary-80 p-[5px] h-fit"
							)}
							onClick={() => setLeftHand(!leftHand)}
						/>
						<TextButton
							color="secondary"
							disabled={false}
							fullWidth={false}
							icon={Close}
							iconClasses={
								branding === "optiswiss"
									? "size-[17px]"
									: undefined
							}
							onClick={() => {
								setModalLeaveOpen(true);
							}}
							className={cn(
								"justify-self-end",
								branding === "optiswiss" &&
									"!rounded-[10px] border border-quaternary-80 p-[5px] h-fit"
							)}
						/>
					</div>
				</div>
			</div>
			<div
				className={cn(
					"min-h-dvh grid items-center sm:hidden",
					branding === "optiswiss" ? "pt-[85px]" : "pt-[72px]"
				)}
			>
				<div className="container text-center">
					<svg
						fill="none"
						width={48}
						height={48}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 48 48"
						className="mx-auto mb-6"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M36 8h-2v4l-6-6 6-6v4h2c4.4 0 8 3.6 8 8v2h4l-6 6-6-6h4v-2c0-2.2-1.8-4-4-4ZM8 8h14v12h4V4H4v16h4V8ZM4 46h40V24H4v22Zm4-18h32v14H8V28Z"
							fill="#2D2926"
						/>
					</svg>
					<HeadingText h={4}>{t("frames.toEditRotate")}</HeadingText>
				</div>
			</div>
			<div className="min-h-dvh relative hidden grid-cols-1 grid-rows-[auto_1fr] justify-items-center pt-[72px] sm:grid">
				<div
					className="flex h-full w-full max-w-[64rem] flex-col items-center justify-center place-self-center lg:h-auto"
					ref={canvasWrapperRef}
				>
					{canvasWrapperRef.current !== undefined && // Sorry
					canvasWrapperRef.current !== null &&
					handleObjects.handle1.kind === "some" &&
					handleObjects.handle2Left.kind === "some" &&
					handleObjects.handle2Right.kind === "some" &&
					imageObjects.topImage.kind === "some" &&
					imageObjects.bottomImage.kind === "some" &&
					measurementResult.kind === "ok" &&
					getSettingsResult.kind === "ok" &&
					measurementResult.data.measurement === measurementId &&
					!isTryingAgain &&
					tryAgainCount < maxTryAgainCount ? (
						<>
							<BodyText
								type="small"
								className="absolute top-24 left-1/2 z-[5] -translate-x-1/2 bg-black/[0.88] px-3 py-1.5 text-white backdrop-blur"
							>
								{t("frames.settingEdges", {
									count: setEdgeCount,
								})}
							</BodyText>
							<FramesCanvasController
								maxWidth={canvasWrapperRef.current.clientWidth}
								maxHeight={
									canvasMaxSize.height >= 1024
										? canvasMaxSize.height - 144
										: canvasWrapperRef.current.clientWidth *
										  (769 / 808)
								}
								imageObjects={{
									topImage: imageObjects.topImage.data,
									bottomImage: imageObjects.bottomImage.data,
								}}
								handleObjects={{
									handle1: handleObjects.handle1.data,
									handle2Left: handleObjects.handle2Left.data,
									handle2Right:
										handleObjects.handle2Right.data,
								}}
								irisRadii={{
									left: measurementResult.data.editable._R
										.iris_radius_pixels,
									right: measurementResult.data.editable._L
										.iris_radius_pixels,
								}}
								startingIrisPositions={{
									left: {
										x: measurementResult.data.editable._R
											.iris.position[0],
										y1: measurementResult.data.editable._R
											.iris.position[1],
										y2: measurementResult.data.editable._R
											.iris.position[2],
									},
									right: {
										x: measurementResult.data.editable._L
											.iris.position[0],
										y1: measurementResult.data.editable._L
											.iris.position[1],
										y2: measurementResult.data.editable._L
											.iris.position[2],
									},
								}}
								startingFramesPositions={transformToBeProcessed(
									measurementResult.data
								)}
								framesPositionsChangedSignal={
									framesPositionsChangedSignal
								}
								onFramesPositionsChange={(pos) => {
									setFramesPositionsWrapper(pos);
								}}
								onSetEdgeCountChange={(c) => {
									setSetEdgeCount(c);
								}}
								leftHand={leftHand}
								alwaysUseLoupe={
									getSettingsResult.data.direct_loupe
								}
							/>
						</>
					) : (
						<CanvasErrors
							status={[
								{
									isActive: isTryingAgain,
									message: `Please wait while we are loading the measurement details. Retry: ${tryAgainCount}/${
										maxTryAgainCount - 1
									}`,
								},
								{
									isActive:
										measurementResult.kind === "loading" ||
										(measurementResult.kind === "ok" &&
											measurementResult.data
												.measurement !== measurementId),
									message: t("frames.waitLoadintDetails"),
								},
								{
									isActive:
										measurementResult.kind === "ok" &&
										measurementResult.data.measurement ===
											measurementId &&
										(imageObjects.topImage.kind ===
											"none" ||
											imageObjects.bottomImage.kind ===
												"none"),
									message: t("frames.waitLoadingPictures"),
								},
								{
									isActive:
										getSettingsResult.kind === "loading",
									message: t("frames.waitLoadingSettings"),
								},
							]}
							errors={[
								{
									isActive:
										measurementResult.kind === "ok" &&
										measurementResult.data.measurement !==
											measurementId,
									message: t("frames.errorIdMismatch"),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										(measurementResult.error.kind !==
											"api-response-error" ||
											measurementResult.error
												.responseCode !== 423),
									message: t("frames.errorUnexpectedDetails"),
								},
								{
									isActive: tryAgainCount >= maxTryAgainCount,
									message: t("frames.errorTryAgainCount"),
								},
								{
									isActive: getSettingsResult.kind === "err",
									message: t(
										"frames.errorUnexpectedSettings"
									),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										measurementResult.error.kind ===
											"api-response-error" &&
										(measurementResult.error
											.responseCode === 423 ||
											measurementResult.error
												.responseCode === 470),
									message: t("frames.anotherEditing"),
								},
								{
									isActive:
										measurementResult.kind === "err" &&
										(measurementResult.error.kind !==
											"api-response-error" ||
											!(
												measurementResult.error
													.responseCode === 423 ||
												measurementResult.error
													.responseCode === 470
											)),
									message: t("frames.errorUnexpectedDetails"),
								},
							]}
						/>
					)}
				</div>
				<div className="before:bg-pure-white relative w-full self-end shadow-[0px_-1px_0px_#e6e8e9] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]">
					<div
						className={cn(
							"container",
							branding === "optiswiss" && "py-4"
						)}
					>
						<FloatingButton
							mx={false}
							num={2}
							col={branding === "optiswiss" ? true : false}
							buttons={{
								left: {
									label: t("frames.saveAsDraftAndQuit"),
									onClick: () => {
										saveCurrentMeasurement();
										closeMeasurement();
										useSnackStore.getState().open(
											t("frames.savedAsDraft", {
												orderid:
													measurementResult.kind ===
													"ok"
														? measurementResult.data
																.order_id
														: "",
											}),
											"ok"
										);
										navigate(
											state?.previousPage
												? `${state.previousPage}`
												: "/"
										);
									},
									disabled: !canShow,
								},
								right: {
									label: t("frames.next"),
									onClick: () => {
										saveCurrentMeasurement();
										navigate(
											`/measurement/result/${measurementId}`,
											{ state: state }
										);
									},
									disabled: !canShow,
								},
							}}
						/>
					</div>
				</div>
			</div>
			<ModalBase
				title={t("frames.leave") || undefined}
				open={modalLeaveOpen}
				close={() => {
					setModalLeaveOpen(false);
				}}
				small
			>
				<div
					className={cn(
						branding === "optiswiss" ? "px-12 py-10 gap-10" : "p-6"
					)}
				>
					<p
						className={cn(
							branding === "optiswiss"
								? "mb-10 text-center"
								: "mb-6"
						)}
					>
						{hasMeasurementChanged
							? t("frames.leaveConfirmNoSave")
							: t("frames.leaveConfirm")}
					</p>
					{hasMeasurementChanged ? (
						<>
							<SolidButton
								color="primary"
								onClick={() => {
									saveCurrentMeasurement();
									closeMeasurement();
									useSnackStore.getState().open(
										t("frames.savedAsDraft", {
											orderid:
												measurementResult.kind === "ok"
													? measurementResult.data
															.order_id
													: "",
										}),
										"ok"
									);
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/"
									);
								}}
								fullWidth
							>
								{t("frames.saveAsDraft")}
							</SolidButton>
							<TextButton
								color="primary"
								className="text-primary-100 mt-4"
								onClick={() => {
									closeMeasurement();
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/"
									);
								}}
								fullWidth
							>
								{t("frames.quitWithoutSave")}
							</TextButton>
						</>
					) : (
						<p className="text-right">
							<SolidButton
								color="primary"
								onClick={() => {
									closeMeasurement();
									navigate(
										state?.previousPage
											? `${state.previousPage}`
											: "/"
									);
								}}
								fullWidth={branding === "optiswiss"}
							>
								{t("frames.quit")}
							</SolidButton>
						</p>
					)}
				</div>
			</ModalBase>
		</>
	);
};

export default Frames;
