import { SVGProps } from "react";
const RouteLogout = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M7.4871 14.3421L7.4871 15.1196C7.4871 16.8154 8.8621 18.1904 10.5579 18.1904L14.6204 18.1904C16.3154 18.1904 17.6904 16.8154 17.6904 15.1196L17.6904 5.8446C17.6904 4.14876 16.3154 2.77376 14.6204 2.77376L10.5496 2.77376C8.85876 2.77376 7.48709 4.1446 7.48709 5.83543L7.48709 6.62126"
				stroke="#DD052B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.82618 10.4823L11.8604 10.4823"
				stroke="#DD052B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.26562 12.9114L1.82562 10.4822L4.26562 8.05221"
				stroke="#DD052B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default RouteLogout;
