import J from "joi";

export interface HoyaTarget {
	applications: string[];
	changeApp: string;
	changeTime: string;
	commission: string;
	createApp: string;
	createTime: string;
	orderNumber: number;
	sessionId: string;
}

export interface IRequestData {
	access_id: string;
	measurement: string;
}

// eslint-disable-next-line
export interface IResponseData extends Array<HoyaTarget>{}

export type IResponseError = null;

const responseDataShape = J.array<HoyaTarget>().required();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "get_sessions";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

// eslint-disable-next-line
export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
