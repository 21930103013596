import clsx from "clsx";
import { ButtonText } from "../typography";
import BaseButtonProps from "./baseProps";
import createRipple from "./rippleEffect";
import { cva } from "class-variance-authority";
import { useBrandingStore } from "../../store";

const OutlineButton = ({
	children,
	onClick,
	className,
	color,
	fullWidth,
	disabled,
	icon,
	submit,
}: BaseButtonProps) => {
	const { branding } = useBrandingStore();

	const buttonVariants = cva(
		"inline-flex items-center justify-center gap-x-3 px-6 border relative overflow-hidden transition duration-200 ease-in-out border-primary-100 text-primary-100 disabled:border-secondary-20 disabled:text-secondary-20 active:bg-primary-20",
		{
			variants: {
				brand: {
					hoya: "h-12",
					optiswiss: "h-10 rounded-[20px]",
				},
				intent: {
					primary: "",
					secondary: "",
				},
				width: {
					default: "min-w-[136px] md:min-w-[225px]",
					full: "w-full",
				},
			},
			compoundVariants: [
				{
					brand: "hoya",
					width: "default",
				},
			],
			defaultVariants: {
				brand: "hoya",
				width: "default",
			},
		}
	);

	return (
		<button
			className={clsx(
				buttonVariants({
					brand: branding !== "optiswiss" ? "hoya" : "optiswiss",
					width: fullWidth ? "full" : "default",
					intent: color,
					className,
				})
			)}
			disabled={disabled}
			onClick={(e) => {
				if (submit) {
					return;
				}
				createRipple(e, "gray");
				onClick && onClick();
			}}
			type={submit ? "submit" : "button"}
		>
			{icon?.call(null, {})}
			<ButtonText type="bold">{children}</ButtonText>
		</button>
	);
};

export default OutlineButton;
