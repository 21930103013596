import clsx from "clsx";
import { capitalize, cn } from "../../util/helpers";
import { BodyText } from "../typography";
import { useId } from "react";
import { useBrandingStore } from "../../store";

type SwitchProps = {
	label?: string | null;
	value: boolean;
	setValue: (value: boolean) => void;
	disabled?: boolean;
	alert?: string;
	info?: string;
	className?: string;
};

const Switch = ({
	value,
	setValue,
	disabled,
	label,
	alert,
	info,
	className,
}: SwitchProps) => {
	const id = useId();
	const { branding } = useBrandingStore();

	return (
		<label
			htmlFor={id}
			className={cn(
				"flex cursor-pointer flex-row items-start gap-3",
				branding === "optiswiss" && "flex-row-reverse justify-between",
				className
			)}
			onClick={(e) => {
				if (disabled) {
					return;
				}
				setValue(!value);
				e.preventDefault();
			}}
		>
			<div className="relative mt-1">
				<input
					id={id}
					type="checkbox"
					className="sr-only"
					checked={value}
					disabled={disabled}
					onChange={() => setValue(!value)}
				/>
				{branding === "optiswiss" ? (
					<>
						<div
							className={cn(
								value && "bg-[#8DB41F]",
								!value && "bg-quaternary-80",
								disabled && "bg-quaternary-100",
								"h-6 w-12 rounded-full"
							)}
						></div>
						<div
							// eslint-disable-next-line tailwindcss/no-custom-classname
							className={cn(
								"size-[18px] rounded-full absolute top-[3px] left-1/2 -translate-x-[calc(50%+12px)] transition bg-pure-white",
								value && "translate-x-[calc(50%-6px)]",
								disabled && "bg-pure-white"
							)}
						></div>
					</>
				) : (
					<>
						<div
							className={clsx(
								value && !disabled && "bg-primary-60",
								!value && !disabled && "bg-secondary-100",
								value && disabled && "bg-secondary-40",
								!value && disabled && "bg-secondary-40"
							)}
						></div>
						<div
							// eslint-disable-next-line tailwindcss/no-custom-classname
							className={clsx(
								value &&
									!disabled &&
									"bg-primary-100 translate-x-full",
								!value &&
									!disabled &&
									"bg-secondary-20 translate-x-0",
								value &&
									disabled &&
									"bg-secondary-40 translate-x-full",
								!value &&
									disabled &&
									"bg-secondary-40 translate-x-0"
							)}
						></div>
					</>
				)}
			</div>
			{label && (
				<div
					className={cn(
						"flex flex-col gap-1",
						branding === "optiswiss" && "gap-2.5"
					)}
				>
					<BodyText
						type="normal"
						className="text-primary-dark-100 select-none"
					>
						{label}
					</BodyText>
					{alert && (
						<BodyText
							type="mini"
							className="text-system-danger-100 select-none"
						>
							{capitalize(alert)}
						</BodyText>
					)}
					{info && (
						<BodyText
							type="mini"
							className="text-primary-dark-60 select-none"
						>
							{capitalize(info)}
						</BodyText>
					)}
				</div>
			)}
		</label>
	);
};

export default Switch;
