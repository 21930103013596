import * as React from 'react';
import { SVGProps } from 'react';
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<clipPath id="Menu_svg__a">
			<path d="M0 0h24v24H0z" />
		</clipPath>
		<g clipPath="url(#Menu_svg__a)" fill={`unset`}>
			<path d="M21 13v-2H3v2zm0-6V5H3v2zm0 12v-2H3v2z" />
		</g>
	</svg>
);
export default SvgMenu;
