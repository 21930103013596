import clsx from "clsx";
import { useEffect, useRef } from "react";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { SolidButton } from "../../components/buttons";
import { SettingsFragment } from "../../components/fragments";
import { CustomSelect, TextField } from "../../components/input";
import { TabComponent } from "../../components/tabs";
import { HeadingText } from "../../components/typography";
import {
	getAccessIdAsPromise,
	useBrandingStore,
	useSnackStore,
} from "../../store";
import {
	getHoyasettings,
	getSettings,
	home,
	setHoyasettings,
	setShopName,
} from "../../util/api/api-store";
import languageIcons from "../../util/LanguageIcons";
import { cn, CountryCodes } from "../../util/helpers";

const Profile = () => {
	const { t, i18n } = useTranslation();
	const [selectedLanguageCode, setSelectedLanguageCode] =
		useState<string>("");
	const [state, updateState] = useState<"Option1" | "Option2">("Option1");
	const setValue = (v: SetStateAction<"Option1" | "Option2">) =>
		updateState(v);
	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const openSnack = useSnackStore((state) => state.open);
	const { branding } = useBrandingStore();

	const languageOptions = languageIcons.map((lang) => ({
		value: lang.code,
		label: lang.name,
	}));

	const [formData, setFormData] = useState({
		ciam_credentials: {
			login: "",
			password: "",
		},
		shop_name: "",
		country_code: "",
		customer_email: "",
		customer_login: "",
		customer_number: "",
		customer_password: "",
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}
	}, []);

	const getCurrentLng = () =>
		i18n.language || window.localStorage.i18nextLng || "";

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		setSelectedLanguageCode(lng);
	};

	useEffect(() => {
		setSelectedLanguageCode(getCurrentLng());

		getAccessIdAsPromise().then((access_id) => {
			home.setRequest({ access_id });
			home.invalidate();
			home.fetchData();
		});

		getSettings.setRequest(null);
		getSettings.fetchData().then((resp) => {
			getHoyasettings.setRequest(null);
			getHoyasettings.fetchData().then((hoyaResp) => {
				setFormData({
					...formData,
					ciam_credentials: hoyaResp.ciam_credentials,
					shop_name: resp.shop_name || "",
					country_code: hoyaResp.country_code,
					customer_email: hoyaResp.customer_email,
					customer_login: hoyaResp.customer_login,
					customer_number: hoyaResp.customer_number,
					customer_password: hoyaResp.customer_password,
				});
			});
		});
	}, []);

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const tHoyasettingsEmail = t("hoyasettings.email");
	const tHoyasettingsTitle = t("hoyasettings.title");

	const onSubmit = () => {
		setIsSubmited(true);
		setShopName.setRequest({
			shop_name: formData.shop_name,
		});
		setShopName.fetchData().then(() => {
			const data = {
				country_code: formData.country_code,
				customer_email: formData.customer_email,
				customer_login: formData.customer_login,
				customer_number: formData.customer_number,
				customer_password: formData.customer_password,
				ciam_credentials: formData.ciam_credentials,
				manufacturer_code: "hoy",
				transmit_face_form_angle: true,
			};

			setHoyasettings.setRequest(data);
			setHoyasettings
				.fetchData()
				.then(() => {
					getHoyasettings.invalidate();
					getHoyasettings.fetchData();
					openSnack(t("profile.savedSuccessfully"), "ok");
					setIsSubmited(false);
				})
				.catch((error) => {
					console.error(error);
					setIsSubmited(false);
				});
		});
		getSettings.invalidate();
		getSettings.fetchData();
	};

	return (
		<div>
			<HeadingText
				h={4}
				className={cn(branding === "optiswiss" && "!text-[26px] mt-2")}
			>
				{t("profile.pageTitle")}
			</HeadingText>
			<SettingsFragment
				title={t("profile.basicSettings") || undefined}
				className={cn(branding === "optiswiss" && "pt-7 pb-10")}
			>
				<div
					className={cn(
						"flex flex-col",
						branding === "optiswiss" ? "gap-6" : "gap-8"
					)}
				>
					<TextField
						type="default"
						htmlFor="shop_name"
						label={t("profile.shopName")}
						info={
							t("profile.shopNameDescription", {
								length: formData.shop_name.length,
							}) || undefined
						}
						fullWidth
						value={formData.shop_name}
						setValue={(v) => {
							setFormData({
								...formData,
								shop_name: v.slice(0, 20),
							});
						}}
					/>
					<CustomSelect
						label={t("profile.language")}
						value={selectedLanguageCode}
						setValue={(v) => {
							changeLanguage(v);
						}}
						options={languageOptions}
						fullWidth
					/>
				</div>
			</SettingsFragment>
			<SettingsFragment
				title={t("profile.emailSettings") || undefined}
				borderTop
				borderBottom
				className={cn(branding === "optiswiss" && "py-9 mb-6")}
			>
				<div className="flex flex-col gap-8">
					<TextField
						type="default"
						htmlFor="customer_email"
						label={t("profile.email")}
						info={tHoyasettingsEmail}
						fieldOptions={{ type: "email" }}
						fullWidth
						value={formData.customer_email}
						setValue={(v) =>
							setFormData({
								...formData,
								customer_email: v,
							})
						}
					/>
				</div>
			</SettingsFragment>
			{branding !== "optiswiss" && (
				<SettingsFragment title={tHoyasettingsTitle}>
					<div className="flex flex-col gap-8">
						<TabComponent
							state={state}
							setState={(v) => setValue(v)}
							options={{
								option1: {
									label:
										branding === "hoya"
											? t("profile.ilink")
											: t("profile.option1"),
								},
								option2: {
									label:
										branding === "hoya"
											? t("profile.ciam")
											: t("profile.option2"),
								},
							}}
						/>
						{state === "Option1" && (
							<>
								<TextField
									type="default"
									htmlFor="ilink-customer_number"
									label={t("hoyasettings.customerNumber")}
									fullWidth
									value={formData.customer_number}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_number: v,
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ilink-customer_login"
									label={t("profile.username")}
									fullWidth
									value={formData.customer_login}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_login: v,
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ilink-customer_password"
									label={t("hoyasettings.customerPassword")}
									fieldOptions={{ type: "password" }}
									fullWidth
									value={formData.customer_password}
									setValue={(v) =>
										setFormData({
											...formData,
											customer_password: v,
										})
									}
								/>
								<CustomSelect
									label={t("hoyasettings.country")}
									value={formData.country_code}
									setValue={(v) => {
										setFormData({
											...formData,
											country_code: v,
										});
									}}
									options={CountryCodes}
									fullWidth
								/>
							</>
						)}
						{state === "Option2" && (
							<>
								<TextField
									type="default"
									htmlFor="ciam-customer_email"
									label={t("profile.email")}
									fieldOptions={{ type: "email" }}
									fullWidth
									value={formData.ciam_credentials.login}
									setValue={(v) =>
										setFormData({
											...formData,
											ciam_credentials: {
												...formData.ciam_credentials,
												login: v,
											},
										})
									}
								/>
								<TextField
									type="default"
									htmlFor="ciam-customer_password"
									label={t("hoyasettings.customerPassword")}
									fieldOptions={{ type: "password" }}
									fullWidth
									value={formData.ciam_credentials.password}
									setValue={(v) =>
										setFormData({
											...formData,
											ciam_credentials: {
												...formData.ciam_credentials,
												password: v,
											},
										})
									}
								/>
							</>
						)}
					</div>
				</SettingsFragment>
			)}
			<div
				ref={buttonRef}
				className="before:bg-pure-white sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]"
			>
				<div
					className={clsx(
						"grid h-[72px] items-center justify-end",
						stuck && "shadow-[0px_-1px_0px_#e6e8e9]"
					)}
				>
					<SolidButton
						color="primary"
						onClick={onSubmit}
						disabled={isSubmited}
					>
						{t("profile.save")}
					</SolidButton>
				</div>
			</div>
		</div>
	);
};

export default Profile;
