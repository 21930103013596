export interface IRequestData {
	vpn_on: boolean;
}

export type IResponseData = null; // Probably an error here

export type IResponseError = null;

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "set_vpn";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	return resp === null;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
