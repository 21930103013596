import * as React from 'react';
import { SVGProps } from 'react';
const SvgBack = (props: SVGProps<SVGSVGElement>) => (
	<svg
		fill="currentColor"
		height={props.height || 24}
		width={props.width || 24}
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M21 11H8V7l-5 5 5 5v-4h13z" fill={`unset`} />
	</svg>
);
export default SvgBack;
