import { FrameLayout } from "../../components/layouts";
import { BodyText, HeadingText } from "../../components/typography";
import { TextField } from "../../components/input";
import { useEffect, useState } from "react";
import {
	FloatingButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import { Back, OSArrowLeft } from "../../components/icons";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/panels/Footer";
import { useTranslation } from "react-i18next";
import {
	getInternetConnectionState,
	getSettings,
	setShopName,
} from "../../util/api/api-store";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useBrandingStore } from "../../store";
import { application, cn } from "../../util/helpers";
import Modal from "react-modal";
import BrandLogo from "../../components/logo/BrandLogo";

type IFormValues = {
	shopName: string;
};

const ShopName = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [isSubmited, setIsSubmited] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [hasInternet, setHasInternet] = useState<boolean>(false);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormValues>({
		// mode: "onChange",
		defaultValues: {
			shopName: "",
		},
	});

	useEffect(() => {
		getSettings.setRequest(null, false);
		getSettings.fetchData().then((resp) => {
			if (resp.onboard) {
				navigate("/");
				return;
			}
		});

		getInternetConnectionState.setRequest(null);
		getInternetConnectionState
			.fetchData()
			.then((resp) => {
				setHasInternet(resp.internet_connected);
			})
			.catch(() => {
				setHasInternet(false);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const saveShopName: SubmitHandler<IFormValues> = (formData) => {
		setIsSubmited(true);

		setShopName.setRequest({
			shop_name: formData.shopName,
		});
		setShopName.fetchData().then((resp) => {
			setIsSubmited(false);
			getSettings.invalidate();
			getSettings.fetchData();
			if (resp.success) {
				gotoNextPage();
			}
		});
	};

	const gotoNextPage = () => {
		if (hasInternet) {
			navigate("/onboarding/privacy-policy");
		} else {
			navigate("/onboarding/wifi");
		}
	};

	if (branding === "optiswiss") {
		return (
			<main className="bg-[#b4b4b4]">
				<Modal
					closeTimeoutMS={0}
					isOpen={true}
					className="fixed inset-0 overflow-y-auto focus-visible:outline-0"
					overlayClassName="fixed inset-0 z-30 bg-transparent"
					bodyOpenClassName="overflow-hidden"
					style={{
						content: {
							transition: "none",
						},
						overlay: {
							transition: "none",
						},
					}}
				>
					<div
						className={cn(
							"flex min-h-full items-center justify-center p-4 sm:p-0",
						)}
					>
						<div
							className={cn(
								"relative flex min-h-[767px] w-full flex-col overflow-hidden rounded-[50px] bg-pure-white transition-all sm:my-8 sm:max-w-[620px]",
							)}
						>
							<div
								className={cn(
									"flex items-center justify-between border-b-[1px] border-secondary-40 px-12 py-8",
								)}
							>
								<BrandLogo override={branding} size={"37px"} />
								<div className={cn("flex flex-col items-end")}>
									<BodyText
										type={"normal"}
										className={cn(
											"!leading-[1em] text-pure-black",
										)}
									>
										{application[branding].title}
									</BodyText>
									<p
										className="text-[8px] text-pure-black"
										dangerouslySetInnerHTML={{
											__html: application[branding]
												.title_visureal,
										}}
									></p>
								</div>
							</div>
							<div
								className={cn(
									"grid flex-1 grid-cols-1 grid-rows-[1fr_auto] place-items-center px-6 pb-12 sm:px-14",
								)}
							>
								<div className="place-self-start">
									<TextButton
										to="/onboarding/language"
										color="primary"
										icon={
											branding === "optiswiss"
												? OSArrowLeft
												: Back
										}
										iconClasses={
											branding === "optiswiss"
												? "size-[30px] !rounded-[10px] border border-quaternary-80 p-[5px]"
												: undefined
										}
										className={cn(
											"-m-3 mb-6",
											branding === "optiswiss"
												? "mt-6 h-fit"
												: "mt-3",
										)}
									>
										{t("shopName.previous")}
									</TextButton>
									<div className={cn("flex flex-col gap-5")}>
										<HeadingText
											h={4}
											className="text-pure-black"
										>{`2/${hasInternet ? "3" : "4"}. ${t(
											"shopName.pleaseAddShopName",
										)}`}</HeadingText>
										<BodyText
											type={"normal"}
											className="text-pure-black"
										>
											{t("shopName.shopNameDescription")}
										</BodyText>
										<form
											onSubmit={handleSubmit(
												saveShopName,
											)}
											id="form-shop_name"
											className={cn(
												"w-full max-w-[250px]",
											)}
										>
											<Controller
												name="shopName"
												control={control}
												rules={{
													required:
														t(
															"shopName.enterShopName",
														) || undefined,
													maxLength: {
														value: 20,
														message: t(
															"shopName.maxLengthIs20",
														),
													},
												}}
												render={({
													field: {
														name,
														value,
														onChange,
													},
												}) => (
													<TextField
														type={
															isLoading ||
															isSubmited
																? "disabled"
																: "default"
														}
														htmlFor={name}
														search
														label={t(
															"shopName.shopName",
														)}
														info={
															t(
																"shopName.max20Char",
															) || undefined
														}
														value={value}
														setValue={(v) =>
															onChange(v)
														}
														errors={errors}
														className={cn(
															"w-full [&>div>input]:rounded-full [&>div>input]:py-2",
														)}
													/>
												)}
											/>
										</form>
									</div>
								</div>
								<div
									className={cn(
										"flex w-full flex-col gap-5 bg-white",
									)}
								>
									<SolidButton
										color="primary"
										loading={isLoading}
										disabled={isSubmited}
										form="form-shop_name"
										submit
										fullWidth
										className="capitalize"
									>
										{t("application.btnNextCaption")}
									</SolidButton>

									<TextButton
										color="primary"
										onClick={() => gotoNextPage()}
										fullWidth
										className={cn(
											"!h-10 rounded-full border border-primary-100",
										)}
									>
										{t("shopName.skip")}
									</TextButton>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</main>
		);
	}

	return (
		<>
			<FrameLayout headerType="onboarding" hiddenFooter={true}>
				<div className="container">
					<TextButton
						to="/onboarding/language"
						color="primary"
						icon={Back}
						className="-m-3 mb-6 mt-3"
					>
						{t("shopName.previous")}
					</TextButton>
					<HeadingText
						h={4}
						className={cn("mb-3 text-primary-dark-100")}
					>{`2/${hasInternet ? "3" : "4"}. ${t(
						"shopName.pleaseAddShopName",
					)}`}</HeadingText>
					<BodyText
						type={"normal"}
						className={cn("mb-8 text-primary-dark-80")}
					>
						{t("shopName.shopNameDescription")}
					</BodyText>
					<form
						onSubmit={handleSubmit(saveShopName)}
						id="form-shop_name"
						className="grid gap-8 md:grid-cols-2"
					>
						<Controller
							name="shopName"
							control={control}
							rules={{
								required:
									t("shopName.enterShopName") || undefined,
								maxLength: {
									value: 20,
									message: t("shopName.maxLengthIs20"),
								},
							}}
							render={({ field: { name, value, onChange } }) => (
								<TextField
									type={
										isLoading || isSubmited
											? "disabled"
											: "default"
									}
									htmlFor={name}
									label={t("shopName.shopName")}
									info={t("shopName.max20Char") || undefined}
									value={value}
									setValue={(v) => onChange(v)}
									errors={errors}
								/>
							)}
						/>
					</form>
				</div>
			</FrameLayout>
			<Footer stickyFooter>
				<div className="container">
					<FloatingButton
						mx={false}
						num={2}
						buttons={{
							left: {
								label: t("shopName.skip"),
								onClick: () => gotoNextPage(),
							},
							right: {
								label: t("application.btnNextCaption"),
								form: "form-shop_name",
								loading: isLoading,
								disabled: isSubmited,
							},
						}}
					/>
				</div>
			</Footer>
		</>
	);
};

export default ShopName;
