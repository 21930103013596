// This is a type that represents the result of operations that can error.
//

export interface Maybe<S> {
	unwrap: () => S;
	expect: (msg: string) => S;
	unwrapOrDefault: (def: S) => S;
	unwrapOrDefaultGeneric: <T>(def: T) => S | T;
	asOption: () => Option<S>;
}

export interface Ok<S, E> extends Maybe<S> {
	kind: "ok";
	data: S;
	happy: true;
	map: <T>(f: (data: S) => T) => Result<T, E>;
}

export interface Err<S, E> extends Maybe<S> {
	kind: "err";
	error: E;
	happy: false;
	map: <T>(f: (data: S) => T) => Result<T, E>;
}

export interface FetchOk<S, E> extends Maybe<S> {
	kind: "ok";
	data: S;
	happy: true;
	map: <T>(f: (data: S) => T) => FetchResult<T, E>;
}

export interface FetchErr<S, E> extends Maybe<S> {
	kind: "err";
	error: E;
	happy: false;
	map: <T>(f: (data: S) => T) => FetchResult<T, E>;
}

export interface Loading<S, E> extends Maybe<S> {
	kind: "loading";
	happy: false;
	map: <T>(f: (data: S) => T) => FetchResult<T, E>;
}

export interface Some<S> extends Maybe<S> {
	kind: "some";
	data: S;
	happy: true;
	map: <T>(f: (data: S) => T) => Option<T>;
	bind: <T>(f: (data: S) => Option<T>) => Option<T>;
}

export interface None<S> extends Maybe<S> {
	kind: "none";
	happy: false;
	map: <T>(f: (data: S) => T) => Option<T>;
	bind: <T>(f: (data: S) => Option<T>) => Option<T>;
}

export type Result<S, E> = Ok<S, E> | Err<S, E>;
export type FetchResult<S, E> = Loading<S, E> | FetchOk<S, E> | FetchErr<S, E>;
export type Option<T> = Some<T> | None<T>;

export function newOption<S>(data: S | undefined | null) {
	if (data === undefined || data === null) {
		return newNone<S>();
	} else {
		return newSome<S>(data);
	}
}

export function newErr<S, E>(error: E): Result<S, E> {
	return {
		kind: "err",
		happy: false,
		error: error,
		unwrap: () => {
			throw new Error("unwrap on Err");
		},
		expect: (msg: string) => {
			throw new Error(msg);
		},
		unwrapOrDefault: (def: S) => {
			return def;
		},
		unwrapOrDefaultGeneric: <T>(def: T) => {
			return def;
		},
		// eslint-disable-next-line
		map: <T>(_: (data: S) => T) => {
			return newErr<T, E>(error);
		},
		asOption: () => {
			return newNone<S>();
		},
	};
}

export function newOk<S, E>(data: S): Result<S, E> {
	return {
		kind: "ok",
		happy: true,
		data: data,
		unwrap: () => {
			return data;
		},
		//eslint-disable-next-line
		expect: (_: string) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefault: (_: S) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefaultGeneric: <T>(_: T) => {
			return data;
		},
		map: <T>(f: (data: S) => T) => {
			return newOk(f(data));
		},
		asOption: () => {
			return newSome(data);
		},
	};
}

export function newFetchErr<S, E>(error: E): FetchResult<S, E> {
	return {
		kind: "err",
		happy: false,
		error: error,
		unwrap: () => {
			throw new Error("unwrap on FetchErr");
		},
		expect: (msg: string) => {
			throw new Error(msg);
		},
		unwrapOrDefault: (def: S) => {
			return def;
		},
		unwrapOrDefaultGeneric: <T>(def: T) => {
			return def;
		},
		// eslint-disable-next-line
		map: <T>(_: (data: S) => T) => {
			return newFetchErr<T, E>(error);
		},
		asOption: () => {
			return newNone<S>();
		},
	};
}

export function newFetchOk<S, E>(data: S): FetchResult<S, E> {
	return {
		kind: "ok",
		happy: true,
		data: data,
		unwrap: () => {
			return data;
		},
		//eslint-disable-next-line
		expect: (_: string) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefault: (_: S) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefaultGeneric: <T>(_: T) => {
			return data;
		},
		map: <T>(f: (data: S) => T) => {
			return newFetchOk(f(data));
		},
		asOption: () => {
			return newSome(data);
		},
	};
}

export function newLoading<S, E>(): FetchResult<S, E> {
	return {
		kind: "loading",
		happy: false,
		unwrap: () => {
			throw new Error("unwrap on Loading");
		},
		expect: (msg: string) => {
			throw new Error(msg);
		},
		unwrapOrDefault: (def: S) => {
			return def;
		},
		unwrapOrDefaultGeneric: <T>(def: T) => {
			return def;
		},
		// eslint-disable-next-line
		map: <T>(_: (data: S) => T) => {
			return newLoading<T, E>();
		},
		asOption: () => {
			return newNone<S>();
		},
	};
}

export function newSome<S>(data: S): Option<S> {
	return {
		kind: "some",
		happy: true,
		data: data,
		unwrap: () => {
			return data;
		},
		//eslint-disable-next-line
		expect: (_: string) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefault: (_: S) => {
			return data;
		},
		//eslint-disable-next-line
		unwrapOrDefaultGeneric: <T>(_: T) => {
			return data;
		},
		map: <T>(f: (data: S) => T) => {
			return newSome(f(data));
		},
		bind: <T>(f: (data: S) => Option<T>) => {
			return f(data);
		},
		asOption: () => {
			return newSome(data);
		},
	};
}

export function newNone<S>(): Option<S> {
	return {
		kind: "none",
		happy: false,
		unwrap: () => {
			throw new Error("unwrap on None");
		},
		expect: (msg: string) => {
			throw new Error(msg);
		},
		unwrapOrDefault: (def: S) => {
			return def;
		},
		unwrapOrDefaultGeneric: <T>(def: T) => {
			return def;
		},
		//eslint-disable-next-line
		map: <T>(_: (data: S) => T) => {
			return newNone<T>();
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		bind: <T>(_: (data: S) => Option<T>) => {
			return newNone<T>();
		},
		asOption: () => {
			return newNone<S>();
		},
	};
}

export function mapResult<S, T, E>(
	f: (data: S) => T,
	result: Result<S, E>
): Result<T, E> {
	switch (result.kind) {
		case "ok":
			return newOk(f(result.data));
		case "err":
			return newErr(result.error);
	}
}

export function mapFetchResult<S, T, E>(
	f: (data: S) => T,
	result: FetchResult<S, E>
): FetchResult<T, E> {
	switch (result.kind) {
		case "ok":
			return newFetchOk(f(result.data));
		case "err":
			return newFetchErr(result.error);
		case "loading":
			return newLoading();
	}
}

export function unwrapResult<S, E>(result: Result<S, E>): S {
	switch (result.kind) {
		case "ok":
			return result.data;
		case "err":
			throw new Error("unwrap on Err");
	}
}

export function unwrapFetchResult<S, E>(result: FetchResult<S, E>): S {
	switch (result.kind) {
		case "ok":
			return result.data;
		case "loading":
			throw new Error("unwrap on Loading");
		case "err":
			throw new Error("unwrap on Err");
	}
}

export function defaultFetchResult<S, E>(def: S, result: FetchResult<S, E>): S {
	if (result.kind === "ok") {
		return result.data;
	} else {
		return def;
	}
}

export function expectOption<T>(option: Option<T>, message: string): T {
	switch (option.kind) {
		case "some":
			return option.data;
		case "none":
			throw new Error(message);
	}
}
