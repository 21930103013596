import clsx from "clsx";
import { LegacyRef, useEffect, useRef } from "react";
import { capitalize } from "../../util/helpers";
import { BodyText, ButtonText } from "../typography";
import CheckboxState from "./CheckboxState";
import { useBrandingStore } from "../../store";

type CheckboxProps = {
	label: string | JSX.Element;
	checked: CheckboxState;
	setChecked: (e: any) => void;
	disabled?: boolean;
	className?: string;
	alert?: string;
	info?: string;
};

const Checkbox = ({
	checked,
	setChecked,
	label,
	className,
	disabled,
	alert,
	info,
}: CheckboxProps) => {
	const isIndeterminate = checked === "indeterminate";
	const checkboxRef = useRef(null);
	const { branding } = useBrandingStore();

	useEffect(() => {
		if (checkboxRef.current as LegacyRef<HTMLInputElement>) {
			// @ts-expect-error - Ref is not null
			checkboxRef.current.indeterminate = isIndeterminate;
		}
	}, [checkboxRef, isIndeterminate]);

	return (
		<label className={clsx("flex flex-row items-start gap-3", className)}>
			<input
				className={clsx(
					"h-6 w-6 border-[3px] outline-none focus:ring-0",
					!disabled &&
						"text-primary-100 border-primary-100 accent-primary-100 cursor-pointer",
					disabled &&
						"text-secondary-40 border-secondary-40 accent-secondary-40",
					alert && "border-system-danger-100",
					branding === "optiswiss" &&
						"rounded-full border-2 border-primary-80 text-primary-80",
					branding === "optiswiss" &&
						alert &&
						"text-system-danger-100",
					branding === "optiswiss" &&
						disabled &&
						"text-quaternary-80 border-quaternary-80"
				)}
				type="checkbox"
				disabled={disabled}
				checked={checked === "checked"}
				onChange={(e) => {
					setChecked(e.target.checked);
				}}
				ref={checkboxRef}
			/>
			<div className="flex flex-col gap-1">
				<ButtonText type="normal" className="text-primary-dark-100">
					{label}
				</ButtonText>
				{alert && (
					<BodyText
						type="mini"
						className="text-system-danger-100 select-none"
					>
						{capitalize(alert)}
					</BodyText>
				)}
				{info && (
					<BodyText
						type="mini"
						className="text-primary-dark-60 select-none"
					>
						{capitalize(info)}
					</BodyText>
				)}
			</div>
		</label>
	);
};
export default Checkbox;
