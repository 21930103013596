import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	OutlineButton,
	SolidButton,
	TextButton,
} from "../../components/buttons";
import {
	SettingsConnectivityStatus,
	SettingsFragment,
} from "../../components/fragments";
import { Switch } from "../../components/input";
import { ModalBase, ModalUpdate } from "../../components/modals";
import { InfoPanel } from "../../components/panels";
import { ButtonText, HeadingText } from "../../components/typography";
import {
	about,
	getServerStatus,
	getSettings,
	restart,
	setUpdateEnabled,
} from "../../util/api/api-store";
import { getAccessIdAsPromise, useBrandingStore } from "../../store";
import { cn } from "../../util/helpers";
import { OSArrowLeft } from "../../components/icons";

const Update = () => {
	const { t } = useTranslation();
	const { branding } = useBrandingStore();
	const [modalStartOpen, setModalStartOpen] = useState<boolean>(false);
	const [modalWorkingOpen, setModalWorkingOpen] = useState<boolean>(false);
	const [modalFailedOpen, setModalFailedOpen] = useState<boolean>(false);

	const closeStartModal = () => {
		setModalStartOpen(false);
	};

	const closeWorkingModal = () => {
		setModalWorkingOpen(false);
	};

	const closeFailedModal = () => {
		setModalFailedOpen(false);
	};

	const startUpdate = () => {
		closeStartModal();
		setModalWorkingOpen(true);

		getAccessIdAsPromise().then((access_id) => {
			restart.setRequest({
				access_id: access_id,
				action: "update",
			});
			restart
				.fetchData()
				.then((result) => {
					console.log(result);
					closeWorkingModal();

					getSettings.setRequest(null);
					getSettings.fetchData();

					getServerStatus.setRequest(null);
					getServerStatus.fetchData();

					about.setRequest(null);
					about.fetchData();
				})
				.catch((error) => {
					console.error(error);
					closeWorkingModal();
					setModalFailedOpen(true);
				})
				.finally(() => {
					setIsSubmited(false);
				});
		});
		// setTimeout(() => {
		// 	setModalWorkingOpen(true);
		// }, 500);
		// setTimeout(() => {
		// 	closeWorkingModal();
		// }, 4000);
		// setTimeout(() => {
		// 	setModalFailedOpen(true);
		// }, 4500);
	};

	const [stuck, setStuck] = useState<boolean>(false);
	const buttonRef = useRef<HTMLDivElement>(null);
	const [isSubmited, setIsSubmited] = useState<boolean>(false);

	const settingsResponse = getSettings.useGetResult();

	const settings = settingsResponse.unwrapOrDefault({
		admin_mode: false,
		auto_exposure: 0,
		branding: "hoya",
		brightness: 0,
		contrast: 0,
		contrast_max: 0,
		contrast_min: 0,
		contrast_step: 0,
		debug_capture_timing: false,
		direct_loupe: false,
		expose_face_form_angle: false,
		exposure_time: 0,
		exposure_time_max: 0,
		exposure_time_min: 0,
		exposure_time_step: 0,
		eye_catcher: false,
		gain_max: 0,
		gain_min: 0,
		hide_shutdown: false,
		hide_wifi_settings: false,
		history_show_preview: false,
		inactive_time: 0,
		isp_digital_gain_max: 0,
		measurement_retention_days: 3,
		measurements_permanent: false,
		modules: {
			ai: false,
			b2b_optic: false,
			b2b_optic_download: false,
		},
		onboard: false,
		order_id_fixed: false,
		order_id_required: false,
		shop_name: "",
		show_aux_points: false,
		show_coordinates: false,
		show_editor_changes: false,
		show_frame_edge_editor: false,
		show_menu_item_debug: false,
		show_reevaluate_button: false,
		starlight_available: false,
		starlight_enabled: false,
		update_enabled: false,
		// user_instructions_url: string // The response does not contain this key.
	});

	const serverStatusResponse = getServerStatus.getResult();

	const serverStatus = serverStatusResponse.unwrapOrDefault({
		show_scheduler: false,
		update_ready: false,
		updated_from: null,
		version: "",
	});

	const systemInfoResponse = about.useGetResult();

	const systemInfo = systemInfoResponse.unwrapOrDefault({
		master_system: "",
		mainboard_serial_nr: "",
		public_version: "",
		space_info: {
			available: 0,
			capacity: 0,
			free: 0,
		},
		system_nr: 0,
		user_instructions_url: "", // Could be a problem
		versions: {
			firm_ware: "",
			frame_tracer: "",
			hard_ware: "",
			inet_tools_master: "",
			inet_tools_tegra: "",
			master: "",
			nxweb: "",
			server: "",
			user_interface: "",
			web_handler: "",
		},
	});

	useEffect(() => {
		const cachedRef = buttonRef.current;

		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		if (null !== cachedRef) {
			observer.observe(cachedRef);
		}

		getSettings.setRequest(null);
		getSettings.fetchData();

		getServerStatus.setRequest(null);
		getServerStatus.fetchData();

		about.setRequest(null);
		about.fetchData();
	}, []);

	const onSubmit = () => {
		setIsSubmited(true);
		// setTimeout(() => {
		// 	setIsSubmited(false);
		// }, 4000);
		setModalStartOpen(true);
	};

	return (
		<div>
			<HeadingText
				h={4}
				className={cn(branding === "optiswiss" && "!text-[26px] py-2")}
			>
				{t("update.pageTitle")}
			</HeadingText>
			<SettingsFragment borderBottom>
				{settings.admin_mode && (
					<InfoPanel
						type="default"
						title={t("update.noPermissionToUpdate")}
						message={
							t("update.noPermissionToUpdateDescription") ||
							undefined
						}
						className="mb-8"
					/>
				)}
				{serverStatus.update_ready ? (
					<SettingsConnectivityStatus type="warning">
						<strong>{t("update.notUpToDate")}</strong>
					</SettingsConnectivityStatus>
				) : (
					<SettingsConnectivityStatus type="success">
						<strong>{t("update.upToDate")}</strong> (
						{t("application.title_visureal")}{" "}
						{t("application.title")} {systemInfo.public_version})
					</SettingsConnectivityStatus>
				)}
			</SettingsFragment>
			{serverStatus.update_ready && (
				<SettingsFragment borderBottom>
					<div
						id="details"
						className="prose prose-li:marker:text-primary-dark-100"
					>
						<p>
							<strong>
								{t("update.updateAvailable", {
									name: t("application.title_visureal"),
									title: t("application.title"),
									version: serverStatus.version,
								})}
							</strong>
						</p>
						<p>Feature list</p>
						<ul>
							<li>
								Lorem ipsum dolor sit amet, consectetur
								adipiscing elit.
							</li>
							<li>
								Nulla ut purus at ipsum placerat dignissim quis
								non nisl. Suspendisse potenti.
							</li>
							<li>Morbi commodo porta tristique.</li>
							<li>
								Nunc ac nibh felis. Ut congue eros a ante
								suscipit convallis.
							</li>
							<li>
								Nulla facilisis lectus vitae justo sodales, in
								eleifend sapien commodo. Integer urna arcu,
								viverra ut volutpat vel, porttitor non lectus.
							</li>
						</ul>
					</div>
				</SettingsFragment>
			)}
			<SettingsFragment title={t("update.updateSettings") || undefined}>
				<Switch
					value={settings.update_enabled}
					setValue={(v) => {
						setIsSubmited(true);
						setUpdateEnabled.setRequest({
							update_enabled: v,
						});
						setUpdateEnabled.fetchData().then(() => {
							getSettings.invalidate();
							getSettings.fetchData();
							setIsSubmited(false);
						});
					}}
					label={t("update.updateSettingsDescription")}
					info={t("update.updateSettingsInfo") || undefined}
					disabled={isSubmited || settings.admin_mode}
				/>
			</SettingsFragment>
			{serverStatus.update_ready && (
				<div
					ref={buttonRef}
					className="before:bg-pure-white sticky bottom-[-1px] backdrop-blur before:absolute before:inset-0 before:opacity-[0.88]"
				>
					<div
						className={clsx(
							"grid h-[72px] items-center justify-end",
							stuck && "shadow-[0px_-1px_0px_#e6e8e9]"
						)}
					>
						<SolidButton
							color="primary"
							onClick={onSubmit}
							disabled={isSubmited || settings.admin_mode}
						>
							{t("update.updateNow")}
						</SolidButton>
					</div>
				</div>
			)}
			<ModalBase
				title={t("update.newUpdateAvailable") || undefined}
				open={modalStartOpen}
				small
				close={closeStartModal}
			>
				<div
					className={cn(
						branding === "optiswiss" ? "px-12 py-8" : "p-6"
					)}
				>
					<p
						className={cn(
							branding === "optiswiss"
								? "text-center mb-5"
								: "mb-3"
						)}
					>
						{t("update.newUpdateDescription")}
					</p>
					<p
						className={cn(
							"mb-10",
							branding === "optiswiss" &&
								"items-center flex gap-1 justify-center"
						)}
					>
						<ButtonText
							type="bold"
							className={cn(
								"text-primary-100 cursor-pointer",
								branding === "optiswiss" && ""
							)}
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClick={() => {}}
						>
							{t("update.updateDetails")}
						</ButtonText>
						{branding === "optiswiss" && (
							<OSArrowLeft className="-scale-100" />
						)}
					</p>
					<SolidButton
						color="primary"
						onClick={startUpdate}
						fullWidth
					>
						{t("update.updateNow")}
					</SolidButton>
					{branding === "optiswiss" ? (
						<OutlineButton
							color="primary"
							className="text-primary-100 mt-4"
							onClick={closeStartModal}
							fullWidth
						>
							{t("update.dontRemind")}
						</OutlineButton>
					) : (
						<TextButton
							color="primary"
							className="text-primary-100 mt-4"
							onClick={closeStartModal}
							fullWidth
						>
							{t("update.dontRemind")}
						</TextButton>
					)}
				</div>
			</ModalBase>
			<ModalBase
				disableHeader
				open={modalWorkingOpen}
				close={closeWorkingModal}
			>
				<ModalUpdate />
			</ModalBase>
			<ModalBase
				title={t("update.installationFailed") || undefined}
				open={modalFailedOpen}
				small
				close={closeFailedModal}
			>
				<div
					className={cn(
						branding === "optiswiss" ? "px-12 py-8" : "p-6"
					)}
				>
					<p
						className={cn(
							branding === "optiswiss" ? "mb-8" : "mb-6"
						)}
					>
						{t("update.errorWhileUpdate")}
					</p>
					<p className="text-right">
						<SolidButton
							color="primary"
							fullWidth={branding === "optiswiss"}
							onClick={closeFailedModal}
						>
							{t("update.tryAgain")}
						</SolidButton>
					</p>
				</div>
			</ModalBase>
		</div>
	);
};

export default Update;
