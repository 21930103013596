import { CSSProperties, Dispatch, SetStateAction } from "react";
import { cn } from "../../util/helpers";

type DropdownProps = {
	forwardRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
	styles: CSSProperties;
	attributes: object;
	children: React.ReactNode;
	className?: string;
};

const Dropdown = ({
	forwardRef,
	styles,
	attributes,
	children,
	className,
}: DropdownProps) => {
	return (
		<div
			ref={forwardRef}
			style={styles}
			{...attributes}
			// eslint-disable-next-line tailwindcss/no-custom-classname
			className={cn(
				"bg-pure-white drop-shadow-dropdownbg z-10 grid min-w-max py-3",
				className
			)}
		>
			{children}
		</div>
	);
};
export default Dropdown;
