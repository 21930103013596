import J from "joi";

export interface IRequestData {
	auto_exposure: number;
	contrast: number;
	direct_loupe: boolean;
	exposure_time: number;
	eye_catcher: boolean;
	history_show_preview: boolean;
	inactive_time: number;
	starlight_enabled: boolean;
	onboard: boolean;
}

export interface IResponseData {
	success: boolean;
}

export type IResponseError = null;

const responseDataShape = J.object({
	success: J.boolean(),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "set_settings";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(respErr: any): respErr is IResponseError {
	return respErr === null;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
