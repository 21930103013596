import J from "joi";

export type FrameType =
	| "metal"
	| "plastic"
	| "rimless"
	| "nylor"
	| "optyl"
	| "spx";

export type LensType = "spherical" | "aspheric" | "progressive";

export interface HeadRoationCompensation {
	cornea_vertex_distance: number;
	cornea_vertex_distance_L: number;
	cornea_vertex_distance_R: number;
	distance_between_lenses: number;
	face_form_angle: number;
	frame_height: number;
	frame_width: number;
	head_rotation_compensation_mm: number;
	head_rotation_degrees: number;
	hoya_mets: number[];
	inclination: number;
	left: number[];
	lens_diameter_L: number | null;
	lens_diameter_R: number | null;
	measurement_distance_cm: number;
	progressive_corridor_max_length: number;
	pupil_distance: number;
	right: number[];
	y_aspheric_L: number;
	y_aspheric_R: number;
	zL_precise: number;
	zR_precise: number;
}

export interface IRequestData {
	access_id: string;
	measurement: string;
}

export interface IResponseData {
	camera_pair: number;
	diagnostic_images: {
		_CO: string;
		_CU: string;
	};
	frame_type: FrameType;
	groove_depth: number;
	head_rotation_compensation: boolean;
	last_modified: string;
	lens_type: LensType;
	measurement: string;
	order_id: string;
	result_data: {
		head_rotation_compensation_off: HeadRoationCompensation;
		head_rotation_compensation_on: HeadRoationCompensation;
	};
	selection_images: {
		_CO: string;
		_CU: string;
	};
	time_stamp: string;
}

export type IResponseError = {
	error: "access conflict";
	success: false;
};

const headRotationCompensationDataShape = () =>
	J.object({
		cornea_vertex_distance: J.number().allow(null),
		cornea_vertex_distance_L: J.number().allow(null),
		cornea_vertex_distance_R: J.number().allow(null),
		distance_between_lenses: J.number().allow(null),
		face_form_angle: J.number().allow(null),
		frame_height: J.number().allow(null),
		frame_width: J.number().allow(null),
		head_rotation_compensation_mm: J.number().allow(null),
		head_rotation_degrees: J.number().allow(null),
		hoya_mets: J.array().items(J.number().allow(null)),
		inclination: J.number().allow(null),
		left: J.array().items(J.number().allow(null)),
		lens_diameter_L: J.number().allow(null),
		lens_diameter_R: J.number().allow(null),
		measurement_distance_cm: J.number().allow(null),
		progressive_corridor_max_length: J.number().allow(null),
		pupil_distance: J.number().allow(null),
		right: J.array().items(J.number().allow(null)),
		y_aspheric_L: J.number().allow(null),
		y_aspheric_R: J.number().allow(null),
		zL_precise: J.number().allow(null),
		zR_precise: J.number().allow(null),
	})
		.required()
		.unknown();

const responseDataShape = J.object({
	camera_pair: J.number(),
	diagnostic_images: J.object({
		_CO: J.string(),
		_CU: J.string(),
	}).unknown(),
	frame_type: J.string(),
	groove_depth: J.number(),
	head_rotation_compensation: J.boolean(),
	last_modified: J.string(),
	lens_type: J.string(),
	measurement: J.string(),
	order_id: J.string().allow(""),
	result_data: J.object({
		head_rotation_compensation_off: headRotationCompensationDataShape(),
		head_rotation_compensation_on: headRotationCompensationDataShape(),
	}).unknown(),
	selection_images: J.object({
		_CO: J.string(),
		_CU: J.string(),
	}).unknown(),
	time_stamp: J.string(),
})
	.required()
	.unknown();

const responseErrorShape = J.object({
	error: J.alternatives().try(J.string().valid("access conflict")),
	success: J.boolean().valid(false),
})
	.required()
	.unknown();

const uri = import.meta.env.VITE_MASTER_SERVER_HOST + "calculate";

// eslint-disable-next-line
export function isValidResponse(resp: any): resp is IResponseData {
	const validationError = responseDataShape.validate(resp).error;
	return validationError === undefined;
}

export function isValidResponseError(
	respErr: unknown
): respErr is IResponseError {
	const validationError = responseErrorShape.validate(respErr).error;
	return validationError === undefined;
}

export function request(req: IRequestData): Promise<Response> {
	return fetch(uri, {
		method: "POST",
		body: JSON.stringify(req),
	});
}
