import clsx from "clsx";
import { ReactNode } from "react";
import { Done, GreenTick, Loading, OSWarning, Warning } from "../../icons";
import { useBrandingStore } from "../../../store";

type Status = "loading" | "done" | "error";

type RequestStatusProps = {
	status: Status;
	loadingText: string;
	doneText: string;
	errorText: string;
};

const RequestStatus = (props: RequestStatusProps) => {
	const { branding } = useBrandingStore();

	const align = (children: ReactNode) => {
		return (
			<div
				className={clsx(
					props.status !== "error" && "text-primary-dark-100",
					props.status === "error" && "text-system-danger-100",
					"flex flex-row gap-3"
				)}
			>
				{children}
			</div>
		);
	};

	if (props.status === "loading") {
		return align(
			<>
				<div className="animate-spin">
					<Loading />
				</div>
				{props.loadingText}
			</>
		);
	}

	if (props.status === "done") {
		return align(
			<>
				{branding === "optiswiss" ? (
					<GreenTick className="size-8" />
				) : (
					<Done />
				)}
				{props.doneText}
			</>
		);
	}

	return align(
		<>
			{branding === "optiswiss" ? (
				<OSWarning className="size-8" />
			) : (
				<Warning />
			)}
			{props.errorText}
		</>
	);
};

export default RequestStatus;
